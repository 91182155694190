import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderComponent from "../Components/header.js";
import FooterComponent from "../Components/footer.js";
import SideMenue from "../Components/sideMenue.js";
import services from "../utils/services";
import { PlaidLink } from "react-plaid-link";
import Header from "../Components/header.js";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: [],
      validEmail: false,
      inviteList: [],
      loader: false,
    };
  }
  async componentDidMount() {}
  validateEmail = (e) => {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    ) {
      this.setState({ validEmail: true });
      this.setState({ email: e.target.value });
    } else {
      this.setState({ validEmail: false });
    }
  };
  handleInvite = async (e) => {
    if (this.state.validEmail) {
      var dataToSend = {
        email: this.state.email,
        name: "user",
      };
      const responseInvite = await services.inviteFriend(dataToSend);
      const responseInviteJson = await responseInvite.json();
      if (responseInvite.status == "200") {
        alert(responseInviteJson.message);
      } else {
        alert(responseInviteJson.message);
        return;
      }
    } else {
      this.setState({ validEmail: false });
    }
  };
  getManageInvite = async (e) => {
    this.setState({ loader: true });
    document.getElementById("manageInvite").style.display = "block";
    const responseInvite = await services.getInviteList();
    const responseInviteJson = await responseInvite.json();
    if (responseInvite.status == "200") {
      this.setState({ inviteList: responseInviteJson.data.rows });
      this.setState({ loader: false });
    } else {
      alert(responseInviteJson.message);
      return;
    }
  };
  handlePending = (status,index,id)=>{
      
    
    if(document.getElementById("button_"+index).innerText == 'Pending'){
       
        document.getElementById("button_"+index).innerText = 'Delete'
        document.getElementById("button_"+index).style.backgroundColor = 'red'
        document.getElementById("button_"+index).style.color = 'white'
    }
    else if(document.getElementById("button_"+index).innerText == 'Delete'){
        const dataToSend={
            ID: id,
        }
        document.getElementById("button_"+index).innerText = 'Pending'
        document.getElementById("button_"+index).style.backgroundColor = 'white'
        document.getElementById("button_"+index).style.color = '#3A5FAC'
        this.deleteInvite(dataToSend);
        
    }
  }
  deleteInvite = async (dataToSend)=>{
    const responseInvite = await services.deleteInvite(dataToSend);
    const responseInviteJson = await responseInvite.json();
    if (responseInvite.status == "200") {
     alert(responseInviteJson.message)
     this.getManageInvite();
    } else {
      alert(responseInviteJson.message);
      return;
    }
  }
  render() {
    return (
      <section className="dashboard-section">
        <div className="container-fluid">
          <div className="row">
            
            <div className="col-md-12">
              <Header/>
              <div className="heading-info-top">
                <div className="row">
                  <div className="col-md">
                    <h1 className="dash-main-heading">Invite Friends</h1>
                  </div>
                </div>
              </div>
              <div className="dash-card">
                <section
                  className="section section-compare-goal"
                  style={{ paddingBottom: "5px;", paddingTop: "5px" }}
                >
                  <div className="container">
                    <div style={{paddingTop:'10px',paddingBottom:'10px'}} className="goal-tabs-wrapper" id={"tabs_data_content"}>
                      <div className="goal-tabs-inner">
                        <ul className="nav nav-tabs clearfix" style={{border:'0px'}}>
                          <li className="nav-item" style={{ width: "50%" }}>
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#inviteFriends"
                            >
                              Invite
                            </a>
                          </li>
                          <li className="nav-item" style={{ width: "50%" }}>
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#manageFriends"
                              onClick={this.getManageInvite}
                            >
                              Manage
                            </a>
                          </li>
                        </ul>

                        <div
                          className="tab-content"
                          style={{ marginTop: "20px" }}
                        >
                          <div
                            className="tab-pane container active"
                            id="inviteFriends"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <center>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={this.validateEmail}
                                  placeholder="Enter email to invite"
                                ></input>
                                </center>
                                <center>
                                  {this.state.validEmail ? (
                                    <button
                                      style={{ marginTop: "10px" }}
                                      className="btn btn-primary"
                                      onClick={this.handleInvite}
                                    >
                                      Invite
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </center>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane container active"
                            id="manageFriends"
                          >
                            <div id="manageInvite" style={{ display: "none" }}>
                              {this.state.loader ? (
                                <center>
                                  <div className="fa-3x">
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                </center>
                              ) : (
                                ""
                              )}
                              {this.state.inviteList.map((item, index) => (
                                <div
                                  className="goal-box"
                                 
                                  key={"goal-box" + index}
                                >
                                  <div
                                    className="row goal-box-info"
                                    key={"goal-box-info" + index}
                                  >
                                    <div
                                      className="col-sm-6 col-xs-6 col-sm-6"
                                     
                                      key={"goal-box-col-6" + index}
                                    >
                                      <h5
                                        key={"goal-h5" + index}
                                        style={{
                                          color: "#3a5dac",
                                          fontWeight: "900",
                                          float:'left'
                                        }}
                                      >
                                        {" "}
                                        {item.email}
                                      </h5>
                                      
                                    </div>
                                    <div
                                      className="col-sm-6 col-xs-6 col-sm-6 text-md-right text-sm-right text-right"
                                      
                                      key={"goal-box-right" + index}
                                    >
                                        <button className="btn btn-info" id={"button_"+index} onClick={this.handlePending.bind(this,item.status,index,item.id)} style={{backgroundColor:item.status === false? 'white':'#3A5FAC',color:item.status === false? '#3A5FAC':'white',borderColor:'#3A5FAC'}}>
                                            {item.status === false?'Pending':'Accepted'}
                                        </button>
                                      
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent/>
      </section>
    );
  }
}

export default Index;
