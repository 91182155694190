import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import services from "../../utils/services";
import { ToastContainer, toast } from 'react-toastify';
class login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password:'',
            confirmPassword:'',
            token:'',
            errorPassword:''
        }
    }
componentDidMount(){
    this.setState({email:this.props.match.params.email}) 
    this.setState({token:this.props.match.params.token}) 
}
    email = (e) => {
        this.setState({email: e.target.value})
    }
    password = (e) => {
        this.setState({password: e.target.value})
    }
    confirmPassword = (e) => {
        this.setState({confirmPassword: e.target.value})
    }
    
    resetPassword= async () => {
        var reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,55}$/;
          if (reg.test(this.state.password)) {
            if(this.state.password !== this.state.confirmPassword){
                this.setState({errorPassword:'Passwords do not match'});
                return;
            }else{
                this.setState({errorPassword:''});
            }
          }else{
            this.setState({errorPassword:'Password Must be greater than 7 and includes special characters'});
            return;
          }
        const data = {
            email:this.state.email,
            hash:this.state.token
          }
          const response = await services.validatePasswordRequest(data);
          const responseJson = await response.json();
          console.log(responseJson.message)
          if (responseJson.message == 'Token is valid') {
            this.resetPass();
          }else{
            toast["error"](responseJson.message);
          }
    }
    resetPass= async () => {
        const data = {
            email:this.state.email,
            hash:this.state.token,
            password:this.state.password
          }
          const response = await services.forgotPasswordReset(data);
          const responseJson = await response.json();
          console.log(responseJson.message)
          if (responseJson.message == 'Password reset successfully.') {
            
                toast["success"](responseJson.message);
                setTimeout(function() {
                    window.location.href="/";
                 }, 5000);
          }else{
            toast["error"](responseJson.message);
          }
    }
    render() {
        return (
            <body className="signup-page">
                 <ToastContainer />
            <section className="reg-section section-height-full">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="reg-form-box">
                            <div className="col-12">
                                <div className="logo-wrapper text-center">
                                    <div className="logo-img">
                                        <img src="/images/logo-A.svg" alt="logo"/>
                                    </div>
                                    <h1>Reset Password</h1>
                                </div>
                                <div className="form-wrapper">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control" placeholder="name@aghaz.com" readOnly={true}
                                            value={this.state.email}  
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" className="form-control" placeholder="Password"
                                          onChange={this.password} 
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" className="form-control" placeholder="Confirm Password"
                                          onChange={this.confirmPassword}
                                        />
                                    </div>
                                    {this.state.errorPassword !== ''?
                                     <p style={{color:'red',fontSize:'10px'}}>
                                            {this.state.errorPassword}
                                     </p>:''
                                }
                                   

                                    <div className="form-btn text-center">
                                        <button type="submit" className="btn btn-primary btn-signup btn-block" onClick={this.resetPassword}> Reset Password
                                        </button>
                                    </div>
                                    {/* <Link to="/login">
                                        Login
                                        </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </body>
        );
    }
}

export default login;
