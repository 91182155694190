import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import SideMenue from "../Components/sideMenue.js";
import Header from "../Components/header.js";
import Footer from "../Components/footer";
import services from "../utils/services";
import "../style.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import $ from "jquery";
import Slider from "react-slick";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
am4core.useTheme(am4themes_animated);
const settings = {
  dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000
};
const options = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{$}{point.percentage:.1f}%</b>",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Balance",
      innerSize: "70%",
      colorByPoint: true,
      data: [
        {
          name: "Chrome",
          y: 61.41,
        },
        {
          name: "Internet Explorer",
          y: 11.84,
        },
        {
          name: "Firefox",
          y: 10.85,
        },
        {
          name: "Edge",
          y: 4.67,
        },
        {
          name: "Safari",
          y: 4.18,
        },
        {
          name: "Other",
          y: 7.05,
        },
      ],
    },
  ],
};

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goal: [],
      loading: false,
      totalBalance: 0,
      percent: 0,
      percentage: 0,
      loadingGraph: true,
      GraphData: [],
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    let chartData = new Array();
    let goalData = localStorage.getItem("goalData");
    if (goalData !== undefined && goalData != null) {
      this.setState({ goal: JSON.parse(goalData) });
      this.setState({ loading: false });
      goalData = JSON.parse(goalData);
      let totalDeposit = 0;
      let totalBalance = 0;
      for (let a = 0; a < goalData.length; a++) {
        chartData[a] = {
          name: goalData[a].name,
          y:
            goalData[a].balance.accountSummary.cash.cashBalance +
            goalData[a].balance.accountSummary.equity.equityValue,
        };
        totalDeposit += goalData[a].totalDeposits;
        totalBalance +=
          goalData[a].balance.accountSummary.cash.cashBalance +
          goalData[a].balance.accountSummary.equity.equityValue;
      }
      options["series"][0].data = chartData;

      this.setState({ GraphData: options });
      this.setState({ totalBalance: totalBalance });
      let percentage = 0;
      if (totalDeposit !== 0 && totalBalance !== 0) {
        let difference = totalBalance - totalDeposit;
        percentage = difference / totalDeposit;
        percentage = percentage * 100;
        if (percentage >= 100) {
          this.setState({ percentage: 100 });
          // state.percentage = percentage;
        } else {
          this.setState({ percentage: percentage });
          // state.percentage = percentage;
        }
      } else {
        this.setState({ percentage: percentage });
      }
      console.log(totalDeposit, "totalDeposit");
      console.log(totalBalance, "totalBalance");
      console.log(percentage, "percentage");
    }
    this.setState({ loadingGraph: true });

    // this.configureChart(chartData);
    $("title").parent().css("display", "none");

    const response = await services.getGoals();
    const responseJson = await response.json();
    if (response.status == "200") {
      let defaultGoal = "";

      if (responseJson.data.rows.length > 0) {
        localStorage.setItem(
          "goalData",
          JSON.stringify(responseJson.data.rows)
        );

        localStorage.setItem("goalCount", responseJson.data.rows.length);
        let totalDeposit = 0;
        let totalBalance = 0;
        for (let a = 0; a < responseJson.data.rows.length; a++) {
          if (responseJson.data.rows[a].isDefault == true) {
            localStorage.setItem(
              "defaultGoal",
              responseJson.data.rows[a].goal_id
            );
          }
          if (
            responseJson.data.rows[a].balance.accountSummary.cash.cashBalance +
              responseJson.data.rows[a].balance.accountSummary.equity
                .equityValue !==
            0
          ) {
            chartData[a] = {
              name: responseJson.data.rows[a].name,
              y:
                responseJson.data.rows[a].balance.accountSummary.cash
                  .cashBalance +
                responseJson.data.rows[a].balance.accountSummary.equity
                  .equityValue,
            };
          }
          totalDeposit += responseJson.data.rows[a].totalDeposits;
          totalBalance +=
            responseJson.data.rows[a].balance.accountSummary.cash.cashBalance +
            responseJson.data.rows[a].balance.accountSummary.equity.equityValue;
        }

        options["series"][0].data = chartData;

        this.setState({ GraphData: options });
        this.setState({ totalBalance: totalBalance });
        let percentage = 0;
        if (totalDeposit !== 0 && totalBalance !== 0) {
          let difference = totalBalance - totalDeposit;
          percentage = difference / totalDeposit;
          percentage = percentage * 100;
          if (percentage >= 100) {
            this.setState({ percentage: 100 });
            // state.percentage = percentage;
          } else {
            this.setState({ percentage: percentage });
            // state.percentage = percentage;
          }
        } else {
          this.setState({ percentage: percentage });
        }
        console.log(totalDeposit, "totalDeposit");
        console.log(totalBalance, "totalBalance");
        console.log(percentage, "percentage");
      }
      this.setState({ loadingGraph: true });
      // this.configureChart(chartData);
      $("title").parent().css("display", "none");
      this.setState({ goal: responseJson.data.rows });
      this.setState({ loading: false });
    } else if (response.status == "401") {
      alert("authorization Problem");
      this.setState({ loading: false });
    } else if (response.status == "429") {
      localStorage.setItem("goalCount", "");
      alert("Too many requests");
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }
  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  configureChart(chartData) {
    let chart = am4core.create("chartdiv", am4charts.PieChart);

    // Add data
    chart.data = chartData;
    // Set inner radius
    chart.innerRadius = am4core.percent(50);

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "amount";
    pieSeries.dataFields.category = "goal";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    // pieSeries.calculatePercent = false;
    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    pieSeries.labels.template.text = "{category}: ${value.value}";
    pieSeries.slices.template.tooltipText = "{category}: ${value.value}";
    // chart.legend.valueLabels.template.text = "{value.value}";
    this.setState({ loadingGraph: false });
    $("title").parent().css("display", "none");
  }
  checkGoalLimit = (e) => {
    let goalCount = localStorage.getItem("goalCount");
    if (goalCount >= 10) {
      // toast
      //   alert("Goal limit reached");
      return;
    }
  };

  getGoalIcon = (name) => {
    let icon = "";
    if (name.search("Retirement") != -1) {
      icon = "images/goalIconsNew/default/retirement.png";
    } else if (name.search("Marriage") != -1) {
      icon = "images/goalIconsNew/default/wedding.png";
    } else if (name.search("Investing") != -1) {
      icon = "images/goalIconsNew/default/investment.png";
    } else if (name.search("Business") != -1) {
      icon = "images/goalIconsNew/default/business.png";
    } else if (name.search("New Home") != -1) {
      icon = "images/goalIconsNew/default/new-home.png";
    } else if (name.search("New Car") != -1) {
      icon = "images/goalIconsNew/default/car.png";
    } else if (name.search("Vacation") != -1) {
      icon = "images/goalIconsNew/default/vacation.png";
    } else if (name.search("Hajj") != -1) {
      icon = "images/goalIconsNew/default/hajj.png";
    } else if (name.search("Umrah") != -1) {
      icon = "images/goalIconsNew/default/hajj.png";
    } else if (name.search("Education") != -1) {
      icon = "images/goalIconsNew/default/kid-ed.png";
    } else if (name.search("Renovation") != -1) {
      icon = "images/goalIconsNew/default/new-home.png";
    } else if (name.search("Investment") != -1) {
      icon = "images/goalIconsNew/default/investment.png";
    } else if (name.search("Donation") != -1) {
      icon = "images/goalIconsNew/default/donate.png";
    } else {
      icon = "images/goalIconsNew/default/custom-goal.png";
    }
    return icon;
  };
  getPercentage = (balance, amount) => {
    let percentage = 0;
    let targetAmount = amount;
    let achieved = balance;
    if (targetAmount != 0) {
      percentage = (achieved / targetAmount) * 100;
    }
    console.log(percentage, "percentage");
    return percentage.toFixed(1);
  };

  render() {
    console.log(this.state.GraphData, "GraphData");
    return (
      <section className="dashboard-section">
        <div className="container-fluid">
          <div className="row">
            {/* <SideMenue /> */}
            <div className="col-md-12">
              <Header />
              <div className="heading-info-top">
                <div className="notification-box">
                  <div className="notification-list">
                    <div className="notf-row clearfix">
                      <div className="notf-img">
                        <img src="/aghaz/images/avatar-1.png" alt="" />
                      </div>
                      <div className="notf-detail">
                        <h5>
                          <b>David Lee</b> sent you a message.
                        </h5>
                        <p className="notf-time">4 min ago</p>
                      </div>
                    </div>
                    <div className="notf-viewall">
                      <a href="#">View all notifications</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-md-6 dashboard-main"
                  style={{ marginTop: "10px" }}
                >
                  <div className="dashboard-main-inner">
                    <div className="goals-card-heading">
                      <h1>Goals Overview</h1>

                      <Link
                        to="/add-goals"
                        onClick={this.checkGoalLimit}
                        style={{
                          marginTop: "-15px",
                          padding: "5px 6px 5px 11px",
                          borderRadius: "35px !important",
                          border: "2px solid",
                          backgroundColor: '#3a5fac',
                          color: 'white',
                          height: '40px',
                          width:' 40px'
                        }}
                        className="add-goals shadow-lg mb-5 rounded"
                      >
                        <i className="fa fa-plus"></i>
                      </Link>
                    </div>
                    {this.state.loading ? (
                      <center>
                        <div className="fa-3x">
                          <i className="fas fa-spinner fa-spin"></i>
                        </div>
                      </center>
                    ) : (
                      ""
                    )}
                    <Slider {...settings}>
                      {this.state.goal.map((item, index) => (
                        <>
                          <Link
                            key={"link_goal" + index}
                            className="goal-box-link"
                            to={"goal-detail/" + JSON.stringify(item)}
                          >
                            <div
                              className="goal-box-primary"
                              style={{
                                backgroundColor: "#cfcfcf",
                                borderRadius: "15px",
                                padding: " 0px 10px",
                                borderBottom: "1px solid #cfcfcf",
                              }}
                              key={"goal-box_" + index}
                            >
                              <div
                                className="row goal-box-info"
                                key={"goal-box-info" + index}
                              >
                                <div
                                  className="col-sm-6 col-xs-6 col-sm-6"
                                  style={{ width: "50%" }}
                                  key={"goal-box-col-61" + index}
                                >
                                  <h5
                                    key={"goal-h51" + index}
                                    style={{
                                      color: "#3a5eac",
                                      fontWeight: "900",
                                    }}
                                  >
                                    {" "}
                                    {item.name}
                                  </h5>
                                  <h6
                                    key={"goal-h532" + index}
                                    style={{
                                      color: "black",
                                      fontWeight: "700",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {"$"}
                                    {(
                                      item.balance.accountSummary.cash
                                        .cashBalance +
                                      item.balance.accountSummary.equity
                                        .equityValue
                                    ).toLocaleString()}
                                  </h6>
                                </div>

                                <div
                                  className="col-sm-6 col-xs-6 col-sm-6 text-md-right text-sm-right text-right"
                                  style={{ width: "50%" }}
                                  key={"goal-box-right2323" + index}
                                >
                                  <h4 className="float_right">
                                    <img
                                      style={{
                                        height: "50px",
                                      }}
                                      src={this.getGoalIcon(item.name)}
                                    ></img>
                                    {/* {item.percentage + "%"} */}
                                  </h4>
                                </div>
                              </div>
                              {!item.isDefault === true ? (
                                <div
                                  className="row goal-box-info"
                                  style={{
                                    marginTop: "-10px",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <div
                                    style={{ width: "50%" }}
                                    class="col-sm-6 col-xs-6 col-sm-6 col-md-6"
                                  >
                                    <h6 style={{ color: "#3a5eac" }}>
                                      {this.getPercentage(
                                        item.balance.accountSummary.cash
                                          .cashBalance +
                                          item.balance.accountSummary.equity
                                            .equityValue,
                                        item.goal_amount
                                      )}
                                      {item.percentage + "%"}
                                    </h6>
                                  </div>
                                  <div
                                    style={{ width: "50%" }}
                                    className="col-sm-6 col-xs-6 col-sm-6 text-md-right text-sm-right text-right"
                                  >
                                    <p
                                      className="float_right"
                                      style={{
                                        color: "#3a5eac",
                                        fontWeight: "900",
                                      }}
                                    >
                                      {new Date(
                                        item.accumulation_horizon.replace(
                                          " ",
                                          ""
                                        )
                                      ).toLocaleString("en-us", {
                                        month: "short",
                                        year: "numeric",
                                      })}{" "}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </Link>
                        </>
                      ))}
                    </Slider>
                  </div>
                </div>

                <div
                  className="col-md-6 dashboard-main"
                  style={{ marginTop: "10px" }}
                >
                  <div className="dashboard-main-inner">
                    <div className="goals-card-heading">
                      <h1>Overall performance</h1>
                    </div>
                    <center>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={this.state.GraphData}
                      />
                      <div>
                        <h2 className="graph_data_value">
                          {"$"}
                          {this.state.totalBalance.toLocaleString()}
                        </h2>

                        <h2
                          className="graph_data_precentage"
                          style={{
                            color: this.state.percentage > 0 ? "green" : "red",
                          }}
                        >
                          {""}
                          {this.state.percentage > 0 ? "+" : ""}
                          {parseFloat(this.state.percentage).toFixed(1) + "%"}
                          {""}
                          <i
                            className={
                              this.state.percentage > 0
                                ? "fa fa-caret-up"
                                : "fa fa-caret-down"
                            }
                          ></i>
                        </h2>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    );
  }
}

export default Index;
