import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import SideMenue from "../Components/sideMenue.js";
import Header from "../Components/header.js";
import services from "../utils/services";
import "../style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "../Components/footer";
class riskProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goal: [],
      loading: false,
    };
  }
  async componentDidMount() {
    let riskProfile = localStorage.getItem("riskProfile");
    console.log(riskProfile, "riskProfile");
    this.setState({ selectedRiskProfile: riskProfile });
    if (riskProfile == "Conservative") {
      document.getElementById("conservative").style.border =
        "2px solid #3a5eac";
    } else if (riskProfile == "Moderately Conservative") {
      document.getElementById("moderately_conservative").style.border =
        "2px solid #3a5eac";
    } else if (riskProfile == "Moderate") {
      document.getElementById("moderate").style.border = "2px solid #3a5eac";
    } else if (riskProfile == "Moderately Aggressive") {
      document.getElementById("moderate_aggressive").style.border =
        "2px solid #3a5eac";
    } else if (riskProfile == "Aggressive") {
      document.getElementById("aggressive").style.border = "2px solid #3a5eac";
    }
  }
  update = async (e) => {
    const data = { riskProfile: this.state.selectedRiskProfile };
    const response = await services.updateRiskProfile(data);
    const responseJson = await response.json();
    if (response.status == "200") {
      toast["success"](responseJson.message);
    } else {
      toast["error"](responseJson.message);
    }
  };
  selectOption = (type, e) => {
    console.log(type);
    document.getElementById("conservative").style.border = "2px solid #e1f1fc";
    document.getElementById("moderately_conservative").style.border =
      "2px solid #e1f1fc";
    document.getElementById("moderate").style.border = "2px solid #e1f1fc";
    document.getElementById("moderate_aggressive").style.border =
      "2px solid #e1f1fc";
    document.getElementById("aggressive").style.border = "2px solid #e1f1fc";

    document.getElementById(type).style.border = "2px solid #3a5eac";
    if (type == "conservative") {
      this.setState({ selectedRiskProfile: "Conservative" });
    } else if (type == "moderately_conservative") {
      this.setState({ selectedRiskProfile: "Moderately Conservative" });
    } else if (type == "moderate") {
      this.setState({ selectedRiskProfile: "Moderate" });
    } else if (type == "moderate_aggressive") {
      this.setState({ selectedRiskProfile: "Moderate Aggressive" });
    } else if (type == "aggressive") {
      this.setState({ selectedRiskProfile: "Aggressive" });
    }
  };
  render() {
    return (
      <section className="dashboard-section">
        <div
          className="container-fluid"
         
        >
          {" "}
          <ToastContainer />
          <div className="row">
           
            <div className="col-md-12">
              <Header />
              <div className="heading-info-top">
                <div className="notification-box">
                  <div className="notification-list">
                    <div className="notf-row clearfix">
                      <div className="notf-img">
                        <img src="/aghaz/images/avatar-1.png" alt="" />
                      </div>
                      <div className="notf-detail">
                        <h5>
                          <b>David Lee</b> sent you a message.
                        </h5>
                        <p className="notf-time">4 min ago</p>
                      </div>
                    </div>
                    <div className="notf-viewall">
                      <a href="#">View all notifications</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 dashboard-main">
                  <div className="dashboard-main-inner">
                    <div className="goals-card-heading">
                      <h1>Risk Profile</h1>
                    </div>
                    {this.state.loading ? (
                      <center>
                        <div className="fa-3x">
                          <i className="fas fa-spinner fa-spin"></i>
                        </div>
                      </center>
                    ) : (
                      ""
                    )}

                    <div
                      id="conservative"
                      onClick={this.selectOption.bind(this, "conservative")}
                      className="riskProfileSelect justify-content-center"
                    >
                      <h4>Conservative</h4>
                    </div>

                    <div
                      id="moderately_conservative"
                      onClick={this.selectOption.bind(
                        this,
                        "moderately_conservative"
                      )}
                      className="riskProfileSelect justify-content-center"
                    >
                      <h4>Moderately Conservative</h4>
                    </div>

                    <div
                      id="moderate"
                      onClick={this.selectOption.bind(this, "moderate")}
                      className="riskProfileSelect justify-content-center"
                    >
                      <h4>Moderate</h4>
                    </div>

                    <div
                      id="moderate_aggressive"
                      onClick={this.selectOption.bind(
                        this,
                        "moderate_aggressive"
                      )}
                      className="riskProfileSelect justify-content-center"
                    >
                      <h4>Moderately Aggressive</h4>
                    </div>
                    <div
                      id="aggressive"
                      onClick={this.selectOption.bind(this, "aggressive")}
                      className="riskProfileSelect justify-content-center"
                    >
                      <h4>Aggressive</h4>
                    </div>
                    <center>
                      <button style={{marginTop:'10px'}} className="btn btn-primary" onClick={this.update}>
                        Update
                      </button>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </section>
    );
  }
}

export default riskProfile;
