import React, {Fragment} from "react";
import {Link} from "react-router-dom";

class login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            privacy: '',
        }
    }
    componentDidMount() {
        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // Set the date we're counting down to
        var countDownDate = new Date("Dec 10, 2020 15:37:25").getTime();
        // Update the count down every 1 second
        var now = new Date().getTime();
            // Find the distance between now and the count down date
            var distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            // Output the result in an element with id="demo"
            document.getElementById("countDownTimerHomePage").innerHTML = days + "d :" + hours + "h "
                + minutes + "m " + seconds + "s ";
        var x = setInterval(function() {
            // Get today's date and time
            var now = new Date().getTime();
            // Find the distance between now and the count down date
            var distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            // Output the result in an element with id="demo"
            document.getElementById("countDownTimerHomePage").innerHTML = days + "d :" + hours + "h "
                + minutes + "m " + seconds + "s ";
            // If the count down is over, write some text
            if (distance < 0) {
                clearInterval(x);
                document.getElementById("demo").innerHTML = "EXPIRED";
            }
        }, 1000);
    }
    email = (e) => {
        this.setState({email: e.target.value})
    }
    password = (e) => {
        this.setState({password: e.target.value})
    }
    confirmPassword = (e) => {
        this.setState({confirmPassword: e.target.value})
    }
    privacy = (e) => {
        this.setState({privacy: e.target.value})
    }

    signUp = (e) => {

    }

    render() {
        return (
            <body className="signup-page">
            <section className="reg-section section-height-full">
                <div className="container" style={{maxWidth:'90%'}}>
                    <div className="row align-items-center">
                        <div className="reg-form-box">
                            <div className="col-12">
                                <div className="logo-wrapper text-center">
                                    <div className="logo-img">
                                        <img src="/images/icons/aghaz-invest-logo.svg" alt="logo"/>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-xl-6 col-md-6 order-md-2 offset-xl-2 text-center">
                                        <img src="/aghaz/images/app-img.jpg" alt=""/>
                                        </div>
                                        <div class="col-xl-4 col-md-6">
                                            <h2>DOWNLOAD THE APP </h2>
                                            <h4>We are Launching Soon</h4>
                                            <div id={"countDownTimerHomePage"}
                                     style={{
                                         border:'1px solid black', fontSize:'34px',fontWeight:'900',color:'black',padding:'9px',textAlign:'center'
                                     }}
                                ></div>
                                            <p>
                                    Tap on one of the below icons to get started.
                                </p>
                                            <div class="app-btns">
                                                <a href="https://aghazinvest.com/subscribe">
                                                    <img src="/images/google-play-store-icon.png" alt="Google Play Store"/>
                                                    </a>
                                                    <a href="https://aghazinvest.com/subscribe">
                                                        <img src="/images/apple-store-icon.png" alt="Apple Store"/>
                                                        </a>
                                                        </div>
                                                        </div>
                                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </body>
        );
    }
}

export default login;
