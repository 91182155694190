import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import HeaderComponent from '../Components/header.js';
import FooterComponent from '../Components/footer.js'
import SideMenue from '../Components/sideMenue.js'
import services from "../utils/services";
import { ToastContainer, toast } from 'react-toastify';
import Header from "../Components/header.js";
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword : '',
            newPassword : '',
            newConfirmPassword : '',
        }
    }
    oldPassword = (e)=>{
        this.setState({
            oldPassword:e.target.value
        })
        if(e.target.value != ''){
            this.setState({oldPasswordMessage:''})
        }
    }
    newPassword = (e)=>{
        this.setState({
            newPassword:e.target.value
        })
        if(e.target.value != ''){
            this.setState({newPasswordMessage:''})
        }
    }
    newConfirmPassword = (e)=>{
        this.setState({
            newConfirmPassword:e.target.value
        })
        if(e.target.value != ''){
            this.setState({confirmPasswordMessage:''})
        }
    }
    validateForm=  (e) => {
        let oldPassword = this.state.oldPassword;
        let newPassword = this.state.newPassword;
        let newConfirmPassword = this.state.newConfirmPassword;
        let isValid = false;
        // await functions.setItemObject("RememberMeUserName", email);
        
        if(oldPassword == '') {
          this.setState ({
            oldPasswordMessage : 'Old Password is required'
          })
        } else if (newPassword == '') {
          this.setState ({
            newPasswordMessage : 'New Password is required'
          })
        } else if(newConfirmPassword == '') {
          this.setState ({
            confirmPasswordMessage : 'Confirm password is required'
          })
        } else if(newConfirmPassword != newPassword) {
            this.setState ({
                confirmPasswordMessage : 'New Password & Confirm Password does not Match'
            })
          } else {
          isValid = true;
        }
        return isValid;
      }
    updatePass= async () => {
        if(this.validateForm()){
        const client_id = localStorage.getItem('clientId');
        if(this.state.newConfirmPassword == this.state.newPassword){
 const data = {
            oldpassword:this.state.oldPassword,
            newPassword:this.state.newPassword
          }
          const response = await services.resetPassword(data);
          const responseJson = await response.json();
          if (responseJson.message == 'Your old password is wrong.') {
                this.setState({
                    oldPasswordMessage:responseJson.message
                })
          }else if(responseJson.message === 'Password reset successfully.'){
            // window.location.href="/web/";
            toast["success"](responseJson.message);
          }else{              
            alert(responseJson.message)
            toast["error"](responseJson.message);
        }
        }else{
            toast["error"]('Password do not match');
            // alert('Password does not match')
        }
    }
        }

    render()
    {
        return (
            <section className="dashboard-section">
                 <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        
                        <div className="col-md-12">
                            <Header/>
                            <div className="heading-info-top">
                                <div className="row">
                                    <div className="col-md">
                                        <h1 className="dash-main-heading">Setting</h1>
                                    </div>
                                    <div className="col-md text-md-right">
                                        <ul className="dash-top-list clearfix">
                                            <li>
                                                <a href="#" className="del-anchor">
                                                    <img src="/images/delete-icon.svg" alt="Delete Icon"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="/images/edit-icon-1.svg" alt="Edit Icon"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="notification-anchor">
                                                    <span className="notf-alert"></span>
                                                    <img src="/images/notifications.svg" alt="notification icon"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="notification-box">
                                    <div className="notf-heading">
                                        <h4>Notifications</h4>
                                    </div>
                                    <div className="notification-list">
                                        <div className="notf-row clearfix">
                                            <div className="notf-img">
                                                <img src="/images/avatar-1.png" alt=""/>
                                            </div>
                                            <div className="notf-detail">
                                                <h5><b>David Lee</b> sent you a message.</h5>
                                                <p className="notf-time">4 min ago</p>
                                            </div>
                                        </div>
                                        <div className="notf-viewall">
                                            <a href="#">View all notifications</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dash-card">
                                <h2>Reset Password</h2>
                                <div className="form-wrapper">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input className="form-control" type="password"
                                                       placeholder="Old Password"
                                                onChange={this.oldPassword}
                                                style={{borderRadius:'1px'}}
                                                />
                                                 {this.state.oldPasswordMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.oldPasswordMessage }</p>
                                        : ''
        }
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" type="password"
                                                       placeholder="New Password"
                                                       onChange={this.newPassword}
                                                       style={{borderRadius:'1px'}}
                                                />
                                                 {this.state.newPasswordMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.newPasswordMessage }</p>
                                        : ''
        }
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" type="password"
                                                       placeholder="Confirm Password"
                                                       onChange={this.newConfirmPassword}
                                                       style={{borderRadius:'1px'}}
                                                />
                                                {this.state.confirmPasswordMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.confirmPasswordMessage }</p>
                                        : ''
        }
                                            </div>
                                            <div className="form-group text-right">
                                                <button className="btn btn-primary" style={{borderRadius:'1px'}} onClick={this.updatePass}>Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterComponent/>
            </section>
        );
    }
}

export default Index;
