import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import Header from "../Components/header.js";
// import HeaderComponent from '../Components/header.js';
import SideMenue from '../Components/sideMenue.js'
import InvestmentDisclaimerComponent from '../Components/disclaimer.js';
import FooterComponent from '../Components/footer.js'
import {Line} from "react-chartjs-2";

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            calculate: false
        }
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

//    finalAmount(t, e, n, a) {
//       var o, l = e;
//        for (o = 0; o < t; o++) l = -(-l - (12 * n)) * -(-1 - a);
//        return l;
//    }

    finalAmount(t, i, m, r) {
        var fv1 = i * Math.pow((1 + r), t);
        var fv2 = m * ((Math.pow((1 + r / 12), (t * 12)) - 1)/(r / 12));
        return fv1 + fv2;
    }


    calculate = (e) => {

        let o = document.getElementById('deposit').value;
        let r = document.getElementById('amount').value;
        let i = document.getElementById('year').value;
        if (o == '' || r == '' || i == '') {
            this.setState({calculate: false});
            this.setState({calculate: 'Enter All fields to continue'});
            return;
        } else {
            this.setState({calculate: true});
            document.getElementById('tabs_data_content').style.display = 'block';
        }

        let t = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"});
        let es = new Intl.NumberFormat("en-US", {style: "percent", minimumFractionDigits: 2});

        let int = 2.05 / 100;
        let inf = 2.10 / 100; 
        let gld = (6 - inf) / 100;
        let aghaz = (8 - inf) / 100;
        
        let u = this.finalAmount(i, o, r, -1 * int);
        let s = this.finalAmount(i, o, r, int - inf);
        let m = this.finalAmount(i, o, r, gld);
        let d = this.finalAmount(i, o, r, aghaz);
        let totalmonth = r * (12 * i);
        let c = -(-o - totalmonth);

        document.getElementById("goal_description_values").innerHTML = "You are starting with <b>" + t.format(o) + "</b> and setting up a recurring deposit of <b>" + t.format(r) + "</b> for " + i + " years. Effectively, you expect to save <b>" + t.format(c) + "</b>.<p></p>";
        document.getElementById("gold_description_values").innerHTML = "If you invest in Gold exclusively, you will end up with <b>" + t.format(m) + "</b> in " + i + " years.<p></p>";
        document.getElementById("cash_description_values").innerHTML = "If you want to just save cash for " + i + " years without Interest, your final amount will be <b>" + t.format(u) + "</b>. Effectively, you will lose <b>" + t.format(c - u) + "</b> due to inflation. With Interest, you will end up with <b>" + t.format(s) + "</b>.<p></p>";
        document.getElementById("aghaz_description_values").innerHTML = "With Aghaz Investments, your final savings would be <b>" + t.format(d) + "</b>. That is a saving of <b>" + t.format(d - c) + "</b> or <b>" + es.format((d - c) / c) + "</b>.<p>";

        this.setState({
            options: {
                title: {
                    text: 'Here is how much you will save towards your goal.'
                },
                xAxis: {
                    categories: ['Start', 'Years ' + i, '']
                },
                plotOptions: {
                    series: {
                        lineWidth: 3,
                        label: {
                            connectorAllowed: false
                        },
                        animation: {
                            duration: 2000
                        },
                        marker: {
                            enabled: false,
                            symbol: 'circle',
                            states: {
                                hover: {
                                    enabled: true
                                }
                            }
                        }
                    }
                },
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: '$',
                    valueSuffix: ' USD'
                },

                series: [{
                    name: 'Cash',
                    data: [parseInt(o), parseInt(u)],
                    color: '#7fcc6b'
                }, {
                    name: 'Interest',
                    data: [parseInt(o), parseInt(s)],
                    color: '#ffb1d6'
                }, {
                    name: 'Gold',
                    data: [parseInt(o), parseInt(m)],
                    color: '#dadc66'
                }, {
                    name: 'Aghaz',
                    data: [parseInt(o), parseInt(d)],
                    color: '#bbdefd'




                }],
            }
        });

    }

    render() {
        return (
            <section className="dashboard-section">
        <div className="container-fluid">
               <div className="row">
                    
                    <div className="col-md-12">
                <Header/>
                <section className="section section-compare-top">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <div className="compare-top-txt">
                                    <h1 style={{fontWeight:'900',fontSize:'34px'}}>YOUR SAVINGS WITH A RECURRING DEPOSIT</h1>
                                    <h6>
                                        See how your savings grow when you 
                                        set up a recurring deposit
                                    </h6>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="compare-top-img">
                                    <img style={{maxWidth: '70%'}} src="/images/compare-deposit-img.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-compare-goal">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="compare-field-group">
                                    <label>Your initial deposit (up to $1 million)</label>
                                    <input type="number" className="form-control" id={"deposit"} defaultValue={'1000'}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="compare-field-group">
                                    <label>Your Monthly deposit (up to $10,000)</label>
                                    <input type="number" className="form-control" id={"amount"} defaultValue={'200'}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="compare-field-group">
                                    <label>Your timeframe in years (up to 30 years)</label>
                                    <input type="number" className="form-control" id={"year"} defaultValue={'5'}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="calculate-btn text-center">
                                    <button className="btn btn-primary" onClick={this.calculate}>Calculate</button>
                                </div>
                            </div>
                        </div>

                        <div className="goal-tabs-wrapper" id={"tabs_data_content"} style={{display: 'none'}}>
                            <div className="goal-tabs-inner">
                                <ul className="nav nav-tabs clearfix">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#tabYrGoal">Your Goal</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabCash">Cash</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabGold">Gold</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabAghaz">Aghaz</a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane container active" id="tabYrGoal">
                                        <p id={"goal_description_values"}>
                                            You want to save $7,500.00 in 7 years.
                                        </p>
                                    </div>
                                    <div className="tab-pane container fade" id="tabCash">
                                        <p id={"cash_description_values"}>
                                            If you want to save cash for 6 years without Interest, you need to start
                                            with $286.04. Effectively, you
                                            will lose $36.04 due to inflation. With Interest, you need to start with
                                            $250.75 to reach your goal.
                                        </p>
                                    </div>
                                    <div className="tab-pane container fade" id="tabGold">
                                        <p id={"gold_description_values"}>
                                            If you invest in Gold for 6 years, you need $187.62 today to achieve
                                            your
                                            goal.
                                        </p>
                                    </div>
                                    <div className="tab-pane container fade" id="tabAghaz">
                                        <p id={"aghaz_description_values"}>
                                            With Aghaz Investments, you need to start with $158.42. That is a saving
                                            of
                                            $127.62 or 51.05%.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.calculate == true ?
                            <div className="compare-charts compare-chart-1">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="compare-chart-box">
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={this.state.options}
                                                oneToOne={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ""
                        }
                    </div>
                </section>
               
                <InvestmentDisclaimerComponent/>
                </div>
                </div>
                {/* <FooterComponent/> */}
            </div>
            <FooterComponent/>
            </section>

        );
    }
}

export default AboutUs;
