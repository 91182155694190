import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import $ from "jquery";
// import constants from "../constants";
class footerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogsData: [],
      imagehref: [
        "images/blog-4.png",
        "images/blog-3.png",
        "images/blog-1.png",
        "images/blog-2.png",
        "images/blog-5.png",
        "images/blog-1.png",
        "images/blog-2.png",
        "images/blog-3.png",
        "images/blog-4.png",
      ],
    };
  }

  componentDidMount() {
   
  }

  
  toggle = (e) => {
    $("#terms-conditions33").slideToggle();
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoPlay: true,
    };
    let { blogsData } = this.state;
    // console.log(blogsData);
    return (
      <div className="App">
        <footer id="mainFooter" style={{ marginTop: "30px" }}>
          <div className="container" style={{maxWidth: '100%'}}>
            <div className="footer-inner">
              <div className="footer-top">
                <div className="row">
                  <div className="col-lg-4 col-8">
                    <a href="/#/" className="logo">
                      <img
                        style={{ height: "125px",tintColor:'blue' }}
                        src="/images/aghaz-invest-logo.svg"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="col-md-8">
                    <div className="footer-socials">
                      <a
                        href="https://www.facebook.com/aghazinvest/"
                        target={"_blank"}
                      >
                        <img
                          src="/images/facebook-icon.svg"
                          alt="facebook Icon"
                        />
                      </a>
                      <a
                        href="https://twitter.com/aghazinvest"
                        target={"_blank"}
                      >
                        <img src="/images/twitter-icon.svg" alt="Twitter Icon" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/aghazinvest/"
                        target={"_blank"}
                      >
                        <img
                          src="/images/linkedin-icon.svg"
                          alt="Linkedin Icon"
                        />
                      </a>
                      <a href="#" target={"_blank"} style={{ display: "none" }}>
                        <img
                          src="/images/instagram-icon.svg"
                          alt="instagram Icon"
                        />
                      </a>
                    </div>
                    <nav className="footer-nav">
                      <div className="footer-socials">
                        <ul
                          className="clearfix"
                        >
                          <li>
                            <a
                              style={{ whiteSpace: "nowrap" }}
                              href="https://aghazinvest.com/#/press-release"
                              target={"_blank"}
                            >
                              Press Releases
                            </a>
                          </li>
                          <li>
                            <a
                              style={{ whiteSpace: "nowrap" }}
                              href="https://aghazinvest.com/#/contact-us"
                              target={"_blank"}
                            >
                              Contact Us
                            </a>
                          </li>

                          <li>
                            <a     target={"_blank"} href="https://aghazinvest.com/#/our-team">Team</a>
                          </li>
                          <li>
                            <a     target={"_blank"} href="https://aghazinvest.com/#/career">Careers</a>
                          </li>
                          {/* <li id="terms-conditions" onClick={this.toggle}>
                            <Link to="/terms-conditions">
                              Terms & Conditions
                            </Link>
                          </li> */}
                          <li>
                            <div class="dropdown">
                              {/* <button class="dropbtn">Legal</button> */}
                              <a href="javascript:void(0)" className="dropbtn">Legal</a>
                              <div class="dropdown-content">
                                <a     target={"_blank"} href="https://aghazinvest.com/#/terms-conditions">
                                  Terms & Conditions
                                </a>
                                <a      target={"_blank"} href="https://aghazinvest.com/#/invite-friends-terms">
                                  Invite Friends Terms
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                        </div>
                        <div className="footer-socials">
                        <ul className="cnt-list">
                          <li>
                            <a href="tel:+1-425-202-6196">
                              <i
                                className="fa fa-phone"
                                style={{ marginRight: "10px" }}
                              ></i>
                              +1-425-202-6196
                            </a>
                          </li>
                          <li>
                            <a href="mailto:info@aghazinvest.com">
                              <i
                                className="fa fa-envelope"
                                style={{ marginRight: "10px" }}
                              ></i>
                              info@aghazinvest.com
                            </a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="footer-top">
                <div className="row">
                  <div className="col-sm-12">
                    <h3 style={{ marginTop: "15px", fontWeight: "700" }}></h3>
                    <p style={{ textAlign: "justify" }}>
                      Aghaz Investment Advisors, LLC (“Aghaz”) is a registered
                      investment adviser located in Seattle, WA. Aghaz may only
                      transact business in those states in which it is
                      registered or qualifies for an exemption or exclusion from
                      registration requirements. Aghaz' website is limited to
                      the dissemination of general information pertaining to its
                      advisory services, together with access to additional
                      investment-related information, publications, and links.
                      Accordingly, the publication of Aghaz' website on the
                      Internet should not be construed by any consumer and/or
                      prospective client as Aghaz' solicitation to effect, or
                      attempt to effect transactions in securities, or the
                      rendering of personalized investment advice for
                      compensation, over the Internet. Any subsequent, direct
                      communication by Aghaz with a prospective client shall be
                      conducted by a representative that is either registered or
                      qualifies for an exemption or exclusion from registration
                      in the state where the prospective client resides. For
                      information pertaining to the registration status of
                      Aghaz, please contact the state securities regulators for
                      those states in which Aghaz maintains a registration
                      filing. A copy of Aghaz' current written disclosure
                      statement discussing Aghaz' business operations, services,
                      and fees is available at the SEC’s investment adviser
                      public information website – www.adviserinfo.sec.gov or
                      from Aghaz upon written request. Aghaz does not make any
                      representations or warranties as to the accuracy,
                      timeliness, suitability, completeness, or relevance of any
                      information prepared by any unaffiliated third party,
                      whether linked to Aghaz' website or incorporated herein,
                      and takes no responsibility therefor. All such information
                      is provided solely for convenience purposes only and all
                      users thereof should be guided accordingly.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      This website and information are provided for guidance and
                      information purposes only. Investments involve risk and
                      unless otherwise stated, are not guaranteed. This website
                      and information are not intended to provide investment,
                      tax, or legal advice.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      Click here for Aghaz' latest{" "}
                      <a href="https://aghazinvest.com/reg/FormCRS_308500.pdf">
                        ADV3 - Client Relationship Summary
                      </a>
                      ,{" "}
                      <a href="https://aghazinvest.com/reg/Privacy_Policy_AGHAZ_INVESTMENT_ADVISORS.pdf">
                        Privacy Policy
                      </a>{" "}
                      and <a href="https://aghazinvest.com/reg/Wrap_Brochure.pdf">Wrap Fee Brochure</a>{" "}
                      documents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default footerComponent;
