import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
// import HeaderComponent from '../Components/header.js';
import SideMenue from '../Components/sideMenue.js'
import InvestmentDisclaimerComponent from '../Components/disclaimer.js';
import FooterComponent from '../Components/footer.js'
import {Line} from "react-chartjs-2";
import Header from "../Components/header.js";

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            calculate: false
        }
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    ComputeFutureValue(Current, Periods, Return){
       let  fv = Current * Math.pow(1 + Return / 100, Periods)
       return fv    
    }
    ComputeMonthlyRecurring(GoalAmount, GoalDue, Return, Initial){
        if (Initial != 0){
            GoalAmount = GoalAmount - this.ComputeFutureValue(Initial, GoalDue / 365, Return)
        }

        // # compute annuity
        let annuity = GoalAmount * Return / 12 / ((Math.pow((1 + Return / 12), GoalDue / 30) - 1))
        return annuity
    }
    calculate = (e) => {

        let goal_amount = document.getElementById('deposit').value;
        let initial_amount = document.getElementById('amount').value;
        let year = document.getElementById('year').value;
      
        let CashAmount = '';
        let GoldAmount = '';
        let AghazAmount = '';
        if (initial_amount == '') {
            initial_amount = 0;
        }
     
        if (goal_amount == '' || year == '') {
            this.setState({calculate: false});
            this.setState({calculate: 'Enter All fields to continue'});
            return;
        } else {
            this.setState({calculate: true});
            document.getElementById('tabs_data_content').style.display = 'block';
        }

        let t = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"});
        let es = new Intl.NumberFormat("en-US", {style: "percent", minimumFractionDigits: 2});

        let int = 2.05 / 100;
        let inf = 2.10 / 100; 
        let gld = (6 - inf) / 100;
        let aghaz = (8 - inf) / 100;

        CashAmount= this.ComputeMonthlyRecurring(goal_amount, year*365, int - inf, initial_amount)
        CashAmount = parseFloat(CashAmount.toFixed(3))
        // console.log(CashAmount,'CashAmount');
        
        // Get monthly_payment for return from gold only
        GoldAmount = this.ComputeMonthlyRecurring(goal_amount, year*365, gld, initial_amount)
        GoldAmount = parseFloat(GoldAmount.toFixed(3))
        // console.log(GoldAmount,'GoldAmount');
        
        // Get monthly_payment for return from Aghaz only
        AghazAmount = this.ComputeMonthlyRecurring(goal_amount, year*365, aghaz, initial_amount)
        AghazAmount = parseFloat(AghazAmount.toFixed(3))
        // console.log(AghazAmount,'AghazAmount');

        document.getElementById("goal_description_values").innerHTML = "You are starting with initial deposit of <b>" + t.format(initial_amount) + "</b> and saving for a goal <b>" + t.format(goal_amount) + "</b> in <b>" + year + " years</b>.<p></p>";
        document.getElementById("gold_description_values").innerHTML = "If you invest in Gold exclusively, you will need to set up a monthly recurring deposit of <b>" + t.format(GoldAmount) + "</b> for <b>" + year + " years</b>.<p></p>";
        document.getElementById("cash_description_values").innerHTML = "If you want to just save cash for <b>" + year + " years</b> without Interest, your monthly recurring deposit will be <b>" + t.format(CashAmount) + "</b>.<p></p>";
        document.getElementById("aghaz_description_values").innerHTML = "With Aghaz, your monthly deposit would be <b>" + t.format(AghazAmount) + "</b>. That is a saving of <b>" + t.format(CashAmount - AghazAmount) + "</b> per month.<p>Effectively, you would have saved <b>" +  t.format(year * 12 * (CashAmount - AghazAmount)) + "</b> in <b>" + year + " years</b> to reach the same goal.</p>";

        this.setState({
            options: {
                title: {
                    text: 'Your Monthly Recurring Deposit'
                },
                tooltip: {
                    valueDecimals: 3,
                    valuePrefix: '$',
                    valueSuffix: ' USD'
                },
                chart: {
                    type: 'bar'
                },

                xAxis: {
                    categories: [''],
                    title: {
                        text: 'Savings'
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    },
                    series: {
                        animation: {
                            duration: 2000
                        }
                    }
                },
                credits: {
                    enabled: false
                },

                series: [{
                    name: 'Cash',
                    data: [CashAmount],
                    color: '#7fcc6b'
                },
                
                 {
                    name: 'Gold',
                    data: [GoldAmount],
                    color: '#dadc66'
                }, {
                    name: 'Aghaz',
                    data: [AghazAmount],
                    color: '#bbdefd'




                }],
            }
        });

    }

    render() {
        return (
            <section className="dashboard-section">
        <div className="container-fluid">
                <div className="row">
                  
                    <div className="col-md-12">
                        <Header/>
                <section className="section section-compare-top">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <div className="compare-top-txt">
                                    <h1 style={{fontWeight:'900',fontSize:'34px'}}>YOUR RECURRING DEPOSIT TO SAVE FOR A GOAL</h1>
                                    <h6>
                                        Calculate the monthly recurring deposit that leads you to your goal
                                    </h6>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="compare-top-img">
                                    <img style={{maxWidth: '70%'}}  src="/images/compare-deposit-img.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-compare-goal">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="compare-field-group">
                                    <label>Goal Amount (up to $1 million)</label>
                                    <input type="number" className="form-control" id={"deposit"} defaultValue={'7500'}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="compare-field-group">
                                    <label>Initial deposit (up to $10,000)</label>
                                    <input type="number" className="form-control" id={"amount"} defaultValue={'300'}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="compare-field-group">
                                    <label>Your timeframe in years (up to 30 years)</label>
                                    <input type="number" className="form-control" id={"year"} defaultValue={'7'}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="calculate-btn text-center">
                                    <button className="btn btn-primary" onClick={this.calculate}>Calculate</button>
                                </div>
                            </div>
                        </div>

                        <div className="goal-tabs-wrapper" id={"tabs_data_content"} style={{display: 'none'}}>
                            <div className="goal-tabs-inner">
                                <ul className="nav nav-tabs clearfix">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#tabYrGoal">Your Goal</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabCash">Cash</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabGold">Gold</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabAghaz">Aghaz</a>
                                    </li>
                                </ul>
                                {<div className="tab-content">
                                    <div className="tab-pane container active" id="tabYrGoal">
                                        <p id={"goal_description_values"}>
                                        </p>
                                    </div>
                                    <div className="tab-pane container fade" id="tabCash">
                                        <p id={"cash_description_values"}>
                                        </p>
                                    </div>
                                    <div className="tab-pane container fade" id="tabGold">
                                        <p id={"gold_description_values"}>
                                        </p>
                                    </div>
                                    <div className="tab-pane container fade" id="tabAghaz">
                                        <p id={"aghaz_description_values"}>
                                        </p>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        {this.state.calculate == true ?
                            <div className="compare-charts compare-chart-1">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="compare-chart-box">
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={this.state.options}
                                                oneToOne={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ""
                        }
                    </div>
                </section>
                <InvestmentDisclaimerComponent/>
                </div>
                </div>
               
           </div>
           <FooterComponent/>
           </section>

        );
    }
}

export default AboutUs;
