
import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import HeaderComponent from '../Components/header.js';
import FooterComponent from '../Components/footer.js'
import SideMenue from '../Components/sideMenue.js'
import services from "../utils/services";
import Header from "../Components/header.js";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionsData: [],
            startDate:'',
            endDate:'',
        }
    }
    async componentDidMount(){
          const response = await services.getTransactions();
          const responseJson = await response.json();
          console.log(responseJson.status)
          console.log(response)
          if(response.status == '200'){
              this.setState({transactionsData:responseJson.data.rows})
          }
          if(response.status == '401'){
            alert('authorization Problem')
            window.location.href='/login';
          }

    }
    startDate = (e)=>{
        this.setState({startDate:e.target.value})
    }
    endDate = (e)=>{
        this.setState({endDate:e.target.value})
    }
    filter = async (e)=>{
        
        if(this.state.startDate == '' || this.state.endDate == ''){
            const response = await services.getTransactions();
            const responseJson = await response.json();
            console.log(responseJson.status)
            console.log(response)
            if(response.status == '200'){
                this.setState({transactionsData:responseJson.data.rows})
            }
            if(response.status == '401'){
              alert('authorization Problem')
              window.location.href='/login';
            }
        }else{
             const responses = await services.getTransactionsFilters(this.state.startDate,this.state.endDate);
             const responseJsons = await responses.json();
             
             if(responses.status == '200'){
                 this.setState({transactionsData:responseJsons.data.rows})
             }
             if(responses.status == '401'){
               alert('authorization Problem')
               window.location.href='/login';
             }
        }
        
         
    }
    render()
    {
        return (
            <section className="dashboard-section">
                <div className="container-fluid">
                    <div className="row">
                      
                        <div className="col-md-12">
                            <Header/>
                            <div className="heading-info-top">
                                <div className="row">
                                    <div className="col-md">
                                        <h1 className="dash-main-heading">Transactions</h1>
                                    </div>
                                    <div className="col-md text-md-right">
                                        <ul className="dash-top-list clearfix">
                                            <li>
                                                <a href="#" className="del-anchor">
                                                    <img src="/images/delete-icon.svg" alt="Delete Icon"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="/images/edit-icon-1.svg" alt="Edit Icon"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="notification-anchor">
                                                    <span className="notf-alert"></span>
                                                    <img src="/images/notifications.svg" alt="notification icon"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="notification-box">
                                    <div className="notf-heading">
                                        <h4>Notifications</h4>
                                    </div>
                                    <div className="notification-list">
                                        <div className="notf-row clearfix">
                                            <div className="notf-img">
                                                <img src="/images/avatar-1.png" alt=""/>
                                            </div>
                                            <div className="notf-detail">
                                                <h5><b>David Lee</b> sent you a message.</h5>
                                                <p className="notf-time">4 min ago</p>
                                            </div>
                                        </div>
                                        <div className="notf-viewall">
                                            <a href="#">View all notifications</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dash-card">
                                <div className="row" style={{marginBottom:'10px'}}>
                                    <div className="col-sm-5">
                                        <input type="date" className="form-control" placeholder="Start Date" onChange={this.startDate}></input>
                                    </div>
                                    <div className="col-sm-5">
                                        <input type="date" className="form-control" placeholder="End Date" onChange={this.endDate}></input>
                                    </div>
                                    <div className="col-sm-2">
                                       <button className="btn btn-info" style={{backgroundColor: '#3a5dac'}} onClick={this.filter}>Filter</button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>Bank</th>
                                            <th>Goal</th>
                                            <th>Transaction Date</th>
                                            <th>Category</th>
                                            <th>Status</th>
                                            <th>Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                       {
                                           this.state.transactionsData.map((items,index)=>
                                               <tr>
                                                   <td><img style={{height:'30px',marginRight:'10px'}} src={items.user_bank == null || items.user_bank ==undefined?'': "data:image/png;base64,"+items.user_bank.logo}></img>{items.user_bank == null || items.user_bank ==undefined?'':items.user_bank.bankAccountNickname}</td>
                                                   <td>{items.client_goal!==null? items.client_goal.name:''}</td>
                                                   <td>{items.transactionDetail !==null?items.transactionDetail.createdAt.split('T')[0]:''}</td>
                                                   <td>{items.transactionDetail !== null?items.transactionDetail.category:''}</td>
                                                   <td><span className={items.status == 'Successful'?"badge badge-success":items.status == 'Approved'?"badge badge-warning":items.status == 'Pending'?"badge badge-secondary":''}>{items.status}</span></td>
                                                   <td style={{color:"#3a5dac",fontWeight:'900'}}>{"$"+items.amount}</td>
                                               </tr>
                                           )
                                       }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <FooterComponent/> */}
                    </div>
                </div>
                <FooterComponent/>
            </section>
        );
    }
}

export default Index;
