import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderComponent from "../Components/header.js";
import FooterComponent from "../Components/footer.js";
import SideMenue from "../Components/sideMenue.js";
import services from "../utils/services";

import Header from "../Components/header.js";
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionsData: [],
    };
  }
  async componentDidMount() {
    let id = this.props.match.params.id;
    const response = await services.getTransactionsByBankId(id);
    const responseJson = await response.json();
    console.log(responseJson.status);
    console.log(response);
    if (response.status == "200") {
      this.setState({ transactionsData: responseJson.data.rows });
    }
    if (response.status == "401") {
      alert("authorization Problem");
      window.location.href = "/#/login";
    }
  }
  deleteBank = async (e) => {
    let id = this.props.match.params.id;
    let a = window.confirm("Are you sure?");
    if (a) {
      const response = await services.deleteBank(id);
      const responseJson = await response.json();
      if (response.status == "200") {
        alert(responseJson.message);
      }
      if (response.status == "401") {
        alert("authorization Problem");
        window.location.href = "/#/login";
      }else{
        alert(responseJson.message);
      }
    } else {
      return;
    }
  };
  render() {
    return (
      <section className="dashboard-section">
        <div className="container-fluid">
          <div className="row">
          
            <div className="col-md-12">
              <Header/>
              <div className="heading-info-top">
                <div className="row">
                  <div className="col-md">
                    <h1 className="dash-main-heading">Bank Detail</h1>
                  </div>
                  <div className="col-md text-md-right">
                    <ul className="dash-top-list clearfix">
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={this.deleteBank}
                          className="del-anchor"
                        >
                          <img
                            src="/images/delete-icon.svg"
                            alt="Delete Icon"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="dash-card">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Goal</th>
                        <th>Transaction Date</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.transactionsData.map((items, index) => (
                        <tr>
                          <td>{items.client_goal.name}</td>
                          <td>
                            {items.transactionDetail.createdAt.split("T")[0]}
                          </td>
                          <td>{items.transactionDetail.category}</td>
                          <td>
                            <span
                              className={
                                items.status == "Successful"
                                  ? "badge badge-success"
                                  : items.status == "Approved"
                                  ? "badge badge-warning"
                                  : items.status == "Pending"
                                  ? "badge badge-secondary"
                                  : ""
                              }
                            >
                              {items.status}
                            </span>
                          </td>
                          <td style={{ color: "#3a5dac", fontWeight: "900" }}>
                            {"$" + items.amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent/>
      </section>
    );
  }
}

export default Index;
