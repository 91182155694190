import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import services from "../utils/services";

class sideMenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    if (
      localStorage.getItem("loginToken") == "" ||
      localStorage.getItem("loginToken") === null
    ) {
      window.location.href = "/#/login";
    }
    const response = await services.getBanks();
    const responseJson = await response.json();
    console.log(responseJson.status);
    console.log(response.status);
    if (response.status == "200") {
      this.setState({ goal: responseJson.data.rows });
    }
    if (responseJson.message == "jwt expired") {
      localStorage.setItem("loginToken", "");
      window.location.href = "/#/ogin";
    }
  }

  logout = (e) => {
    console.log("logout");
    localStorage.clear();
    window.location.href = "/#/login";
  };
  toolsClicked = (e) => {
    $("#subMenue").slideToggle();
  };
  settingsClicked = (e) => {
    $("#subMenueSettings").slideToggle();
  };
  render() {
    return (
      <aside className="col-md-3">
        <div className="dash-sidebar" style={{ backgroundColor: "#3a5eac" }}>
          <div
            className="dash-logo text-center"
            style={{ backgroundColor: "#3a5eac" }}
          >
            <img src="/images/aghaz-invest-logo.svg" alt="logo" />
          </div>
          <div className="sidebar-nav" style={{ backgroundColor: "#3a5eac" }}>
            <ul
              style={{
                backgroundColor: "#ffffff",
                padding: "18px",
                borderRadius: "10px",
              }}
            >
              <li>
                <Link to="/">
                  <span>
                    {/* <img className="img-normal" src="/aghaz/images/overview-icon.svg" alt=""/> */}
                    <img
                      className="img-normal"
                      src="/images/overview-hover-icon.svg"
                      alt=""
                    />
                  </span>
                  Overview
                </Link>
              </li>
              <li>
                <Link to="/accounts">
                  <span>
                    {/* <img className="img-normal" src="/aghaz/images/accounts-icon.svg" alt=""/> */}
                    <img
                      className="img-normal"
                      src="/images/accounts-hover-icon.svg"
                      alt=""
                    />
                  </span>
                  Accounts
                </Link>
              </li>
              <li>
                <Link to="/transactions">
                  <span>
                    {/* <img className="img-normal" src="/aghaz/images/trans-icon.svg" alt=""/> */}
                    <img
                      className="img-normal"
                      src="/images/trans-hover-icon.svg"
                      alt=""
                    />
                  </span>
                  Transactions
                </Link>
              </li>

              <li>
                <a href={"javascript:void(0)"} onClick={this.settingsClicked}>
                  <span>
                    {/* <img className="img-normal" src="/aghaz/images/settings-icon.svg" alt=""/> */}
                    <img
                      className="img-normal"
                      src="/images/h-icon-1.svg"
                      alt=""
                    />
                  </span>
                  Profile
                </a>
                <ul id="subMenueSettings" style={{ display: "none" }}>
                  <li>
                    <Link
                      to={"/settings"}
                      style={{ padding: "0px 9px 25px 70px" }}
                    >
                      <span className="subMenu-span">
                        {/* <img className="img-normal" src="/aghaz/images/settings-icon.svg" alt=""/> */}
                        <img
                          className="img-subMenue"
                          src="/images/settings-hover-icon.svg"
                          alt=""
                        />
                      </span>
                      Change Password
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/risk-profile"}
                      style={{ padding: "0px 9px 25px 70px" }}
                    >
                      <span className="subMenu-span">
                        {/* <img className="img-normal" src="/aghaz/images/settings-icon.svg" alt=""/> */}
                        <img
                          className="img-subMenue"
                          src="/images/riskP_icon.png"
                          alt=""
                        />
                      </span>
                      Risk Profile
                    </Link>
                  </li>
                  
                  <li>
                    <Link
                      to={"/beneficiary"}
                      style={{ padding: "0px 9px 25px 70px" }}
                    >
                      <span className="subMenu-span">
                        {/* <img className="img-normal" src="/aghaz/images/settings-icon.svg" alt=""/> */}
                        <img
                          className="img-subMenue"
                          src="/images/beneficiary.png"
                          alt=""
                        />
                      </span>
                      Beneficiary
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/invite-friends">
                  <span>
                    {/* <img className="img-normal" src="/aghaz/images/settings-icon.svg" alt=""/> */}
                    <img
                      className="img-normal"
                      src="/images/invite.png"
                      alt=""
                    />
                  </span>
                  Invite Friends
                </Link>
              </li>
              
              <li>
                <a href={"javascript:void(0)"} onClick={this.toolsClicked}>
                  <span>
                    {/* <img className="img-normal" src="/aghaz/images/settings-icon.svg" alt=""/> */}
                    <img
                      className="img-normal"
                      src="/images/tools.svg"
                      alt=""
                    />
                  </span>
                  Tools
                </a>
                <ul id="subMenue" style={{ display: "none" }}>
                  <li>
                    <Link
                      to={"/compare"}
                      style={{ padding: "0px 9px 25px 70px" }}
                    >
                      <span className="subMenu-span">
                        {/* <img className="img-normal" src="/aghaz/images/settings-icon.svg" alt=""/> */}
                        <img
                          className="img-subMenue"
                          src="/images/compar-savings.svg"
                          alt=""
                        />
                      </span>
                      Compare your Savings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/Compare-deposit"}
                      style={{ padding: "0px 9px 25px 70px" }}
                    >
                      <span className="subMenu-span">
                        {/* <img className="img-normal" src="/aghaz/images/settings-icon.svg" alt=""/> */}
                        <img
                          className="img-subMenue"
                          src="/images/recurring.svg"
                          alt=""
                        />
                      </span>
                      Saving with Recurring Deposit
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/Compare-goal-deposit"}
                      style={{ padding: "0px 9px 25px 70px" }}
                    >
                      <span className="subMenu-span">
                        {/* <img className="img-normal" src="/aghaz/images/settings-icon.svg" alt=""/> */}
                        <img
                          className="img-subMenue"
                          src="/images/compar-deposit.svg"
                          alt=""
                        />
                      </span>
                      Compare Goal Deposit
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/portfolio-review"}
                      style={{ padding: "0px 9px 25px 70px" }}
                    >
                      <span className="subMenu-span">
                        {/* <img className="img-normal" src="/aghaz/images/settings-icon.svg" alt=""/> */}
                        <img
                          className="img-subMenue"
                          src="/images/compar-deposit.svg"
                          alt=""
                        />
                      </span>
                      Portfolio review
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link onClick={this.logout} to="/login">
                  <span>
                    {/* <img className="img-normal" src="/aghaz/images/logout-icon.svg" alt=""/> */}
                    <img
                      className="img-normal"
                      src="/images/logout-hover-icon.svg"
                      alt=""
                    />
                  </span>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    );
  }
}

export default sideMenue;
