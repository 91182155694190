/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import Index from "Views/index.js";

import Index from "./views/index.js";
import Login from "./views/Auth/login.js";
import ForgotPassword from "./views/Auth/forgotPassword.js";
import SignUp from "./views/Auth/SignUp.js";
import Accounts from "./views/accounts.js";
import Transactions from "./views/transactions.js";
import Settings from "./views/settings.js";
import AddGoals from "./views/Goals/create.js";
import EditProfile from "./views/Profile/profile.js";
import Compare from "./views/compare.js";
import Comparedeposit from "./views/compare-deposit.js";
import Comparegoaldeposit from "./views/Comparegoaldeposit.js";
import Resetpassword from "./views/Auth/resetPassword";
import Goaldetail from "./views/Goals/goalDetail";
import Bankdetail from "./views/bankDetail";
import UpdateGoal from "./views/Goals/update";
import Invitefriend from "./views/Invitefriend";
import Portfolioreview from "./views/portfolioReview";
import Beneferies from "./views/beneferies";
import Riskprofile from "./views/riskProfile";
console.log(window.location.href.indexOf("reset-password"),'window.location.href.indexOf("reset-password")')
if (window.location.href.indexOf("reset-password") < -1) {
  if (
    localStorage.getItem("loginToken") == "" ||
    localStorage.getItem("loginToken") === null
  ) {
    window.location.href = "/#/login";
  }
}
ReactDOM.render(
  <BrowserRouter basename="/#/" forceRefresh={true}>
    <Switch>
      <Route
        path="/"
        exact
        component={Index}
        render={(props) => <Index {...props} />}
      />
      <Route
        path="/dashboard"
        exact
        component={Index}
        render={(props) => <Index {...props} />}
      />
      <Route path="/login/:id" exact render={(props) => <Login {...props} />} />
      <Route
        path="/login/:message/:email/:hash"
        exact
        render={(props) => <Login {...props} />}
      />
      <Route path="/login" exact render={(props) => <Login {...props} />} />
      <Route path="/login/:id" exact render={(props) => <Login {...props} />} />
      <Route
        path="/forgot-password"
        exact
        render={(props) => <ForgotPassword {...props} />}
      />
      <Route path="/sign-up" exact render={(props) => <SignUp {...props} />} />
      <Route
        path="/accounts"
        exact
        render={(props) => <Accounts {...props} />}
      />
      <Route
        path="/transactions"
        exact
        render={(props) => <Transactions {...props} />}
      />
      <Route
        path="/settings"
        exact
        render={(props) => <Settings {...props} />}
      />
      <Route
        path="/add-goals"
        exact
        render={(props) => <AddGoals {...props} />}
      />

      <Route
        path="/edit-profile"
        exact
        render={(props) => <EditProfile {...props} />}
      />
      <Route path="/compare" exact render={(props) => <Compare {...props} />} />
      <Route
        path="/Compare-deposit"
        exact
        render={(props) => <Comparedeposit {...props} />}
      />
      <Route
        path="/Compare-goal-deposit"
        exact
        render={(props) => <Comparegoaldeposit {...props} />}
      />
      <Route
        path="/reset-password/:email/:token"
        exact
        render={(props) => <Resetpassword {...props} />}
      />
      <Route
        path="/goal-detail/:id"
        exact
        render={(props) => <Goaldetail {...props} />}
      />
      <Route
        path="/bank-detail/:id"
        exact
        render={(props) => <Bankdetail {...props} />}
      />
      <Route
        path="/update-goal/:id"
        exact
        render={(props) => <UpdateGoal {...props} />}
      />
      <Route
        path="/invite-friends"
        exact
        render={(props) => <Invitefriend {...props} />}
      />
      <Route
        path="/portfolio-review"
        exact
        render={(props) => <Portfolioreview {...props} />}
      />
      <Route
        path="/beneficiary"
        exact
        render={(props) => <Beneferies {...props} />}
      />
      <Route
        path="/risk-profile"
        exact
        render={(props) => <Riskprofile {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
