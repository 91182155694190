import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderComponent from "../../Components/header.js";
import FooterComponent from "../../Components/footer.js";
import SideMenue from "../../Components/sideMenue.js";
import { Button, Modal } from "reactstrap";
import services from "../../utils/services";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Header from "../../Components/header.js";
const options = {
  chart: {
    type: "line",
  },
  title: {
    text: "",
  },
  series: [
    {
      data: [0],
    },
  ],
  yAxis: {
    labels: {
      format: "${value}",
    },
  },

  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  tooltip: {
    formatter: function () {
      return (
        "<b>" + this.x + "</b><br/>" + "$" + Highcharts.numberFormat(this.y, 2)
      );
    },
  },

  xAxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
};
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionsData: [],
      performanceData: [],
      cashData: [],
      goalData: [],
      DWTransactionsData: [],
      summaryData: [],
      loading: false,
      equityValue: 0,
      goal_id: "",
      cashMarketValue: "",
      cashAvailableForTradingQty: "",
      withDrawModal: false,
      banks: [],
      bankLoader: false,
      withdrawAmount: 0,
      withdrawAmountOriginal: 0,
      withDrawAmountNegativeErrorMessage: false,
      selectedBank: "",
      liquidateModal: false,
      addFundModal: false,
      addFundAmount: 0,
      chartOptions: options,
      pastDataSetShow: false,
      GoalInPast: true,
      projectedFV: 0,
    };
  }
  async componentDidMount() {
    // let graph = new Array();
    let graph = {
      title: {
        text: "",
      },
      series: [
        {
          data: [1, 2, 1, 4, 3, 6, 7, 3, 8, 6, 9],
        },
      ],
    };
    // this.setState({chartOptions:graph})
    let id = this.props.match.params.id;
    id = JSON.parse(id);
    // this.setState({ goalPercentage: id.percentage });
    console.log(id, "id");
    this.setState({ goalData: id });
    console.log(id.createdAt.split("T")[0]);
    this.setState({ createdAtGoal: id.createdAt.split("T")[0] });
    this.setState({ accumulation_horizon: id.accumulation_horizon });
    if (id.Goal_Recurring_Deposit !== 0) {
      // this.getGraphValuesApi(id);
      this.getInsights(id);
    }
    let percentage = 0;
    let targetAmount = id.goal_amount;
    let achieved = id.balance.accountSummary.cash.cashBalance + id.balance.accountSummary.equity.equityValue;
    if (targetAmount != 0) {
      percentage = achieved / targetAmount * 100;
    }
    console.log(percentage,'percentage')
   
    this.setState({goalPercentage:percentage.toFixed(2)})
    id = id.goal_id;
    this.setState({ goal_id: id });

    const response = await services.getTransactionsByGoalId(id);
    const responseJson = await response.json();

    if (response.status == "200") {
      this.setState({ transactionsData: responseJson.data.rows });
    }
    if (response.status == "401") {
      alert("authorization Problem");
      window.location.href = "/#/login";
    }
  }
  getPerformance = async (e) => {
    this.setState({ loading: true });
    let id = this.props.match.params.id;
    id = JSON.parse(id);
    id = id.goal_id;
    const response = await services.getPerformanceByGoalId(id);
    const responseJson = await response.json();

    if (response.status == "200") {
      this.setState({
        performanceData:
          responseJson.performance.accountSummary.equity.equityPositions,
        equityValue:
          responseJson.performance.accountSummary.equity.equityValue +
          responseJson.performance.accountSummary.cash.cashBalance,
        cashData: responseJson.performance.accountSummary.cash,
        cashMarketValue:
          responseJson.performance.accountSummary.cash
            .cashAvailableForWithdrawal,
        cashAvailableForTradingQty:
          responseJson.performance.accountSummary.cash
            .cashAvailableForWithdrawal,
      });
      let performance =
        responseJson.performance.accountSummary.equity.equityPositions;
      let equity =
        responseJson.performance.accountSummary.equity.equityValue +
        responseJson.performance.accountSummary.cash.cashBalance;

      let totalBalance = 0;
      for (
        let i = 0;
        i <
        responseJson.performance.accountSummary.equity.equityPositions.length;
        i++
      ) {
        totalBalance +=
          responseJson.performance.accountSummary.equity.equityPositions[i]
            .costBasis;
      }
      let cashBalance =
        responseJson.performance.accountSummary.cash.cashBalance;
      totalBalance = -(
        -totalBalance - responseJson.performance.accountSummary.cash.cashBalance
      );
      let percentage = 0;
      let id = this.props.match.params.id;
      id = JSON.parse(id);
      let totalDeposit = id.totalDeposits;
      let totalBalances =
        id.balance.accountSummary.cash.cashBalance +
        id.balance.accountSummary.equity.equityValue;

      // let percentage = 0;
      if (totalDeposit !== 0 && totalBalances !== 0) {
        let difference = totalBalances - totalDeposit;
        percentage = difference / totalDeposit;
        percentage = percentage * 100;
        if (percentage >= 100) {
          this.setState({ percentage: 100 });
          // state.percentage = percentage;
        } else {
          this.setState({ percentage: percentage });
          // state.percentage = percentage;
        }
      } else {
        this.setState({ percentage: percentage });
      }
      console.log(percentage, "percentage");

      this.setState({ loading: false });
    } else if (response.status == "401") {
      alert("authorization Problem");
      window.location.href = "/#/login";
      this.setState({ loading: false });
    } else {
      alert(responseJson.message);
      this.setState({ loading: false });
    }
  };
  delete = async (e) => {
    let id = this.props.match.params.id;
    id = JSON.parse(id);
    id = id.goal_id;
    var data = { id: id };
    const response = await services.deleteGoalById(data);
    const responseJson = await response.json();
    alert(responseJson.message);
    window.location.href = "/";
  };
  getDWTransactions = async (e) => {
    let id = this.props.match.params.id;
    id = JSON.parse(id);
    id = id.goal_id;

    const response = await services.getDWTransactions(id);
    const responseJson = await response.json();
    console.log(responseJson, "responseJson");
    if (response.status == "200") {
      // alert('asd')
      this.setState({ DWTransactionsData: responseJson.data });
    } else {
      alert(responseJson.message);
    }
  };
  getSummary = async (e) => {
    let id = this.props.match.params.id;
    id = JSON.parse(id);
    id = id.goal_id;

    const response = await services.getSummary(id);
    const responseJson = await response.json();
    console.log(responseJson, "responseJson");
    if (response.status == "200") {
      // alert('asd')
      this.setState({ summaryData: responseJson.data });
    } else {
      alert(responseJson.message);
    }
  };
  downloadFile = async (key, e) => {
    let id = this.props.match.params.id;
    id = JSON.parse(id);
    id = id.goal_id;
    const response = await services.downloadFile(id, key);
    const responseJson = await response.json();
    console.log(responseJson, "responseJson");
    if (response.status == "200") {
      // alert('asd')
      // this.setState({ summaryData: responseJson.data });
      // window.location.href=responseJson.data.url;
      window.open(responseJson.data.url);
    } else {
      alert(responseJson.message);
    }
  };
  WithDraw = async (e) => {
    this.setState({ bankLoader: true });
    const response = await services.getBanks();
    const responseJson = await response.json();
    if (response.status == "200") {
      this.setState({ banks: responseJson.data.rows });
      this.setState({ bankLoader: false });
    } else if (response.status == "401") {
      alert("authorization Problem");
    } else {
      alert(responseJson.message);
    }
    let id = this.props.match.params.id;
    id = JSON.parse(id);
    id = id.goal_id;
    const responsePerformance = await services.getPerformanceByGoalId(id);
    const responsePerformanceJson = await responsePerformance.json();
    if (responsePerformance.status == "200") {
      let cashAvailableForWithdraw =
        responsePerformanceJson.performance.accountSummary.cash
          .cashAvailableForWithdrawal;
      cashAvailableForWithdraw = cashAvailableForWithdraw * 0.98;
      this.setState({ withdrawAmount: cashAvailableForWithdraw });
      this.setState({ withdrawAmountOriginal: cashAvailableForWithdraw });
      this.setState({ withDrawModal: !this.state.withDrawModal });
    } else {
      alert(responseJson.message);
    }
  };
  withDrawModalClose = (e) => {
    this.setState({ withDrawModal: !this.state.withDrawModal });
  };
  withdrawAmount = async (e) => {
    this.setState({ withdrawAmount: e.target.value });
    if (e.target.value > this.state.withdrawAmountOriginal) {
      this.setState({ withDrawAmountErrorMessage: true });
    }
  };
  SaveWithDraw = async (e) => {
    if (this.state.withDrawAmountErrorMessage) {
      this.setState({ withDrawAmountErrorMessage: true });
      return;
    }
    if (this.state.withdrawAmount == "" || this.state.withdrawAmount <= 0) {
      this.setState({ withDrawAmountNegativeErrorMessage: true });
      return;
    }
    if (this.state.selectedBank == "") {
      this.setState({ SelectBankMessage: "Select bank to continue" });
      return;
    }
    let id = this.props.match.params.id;
    id = JSON.parse(id);
    id = id.goal_id;
    let dataToSend = {
      goal_id: id,
      bank_id: this.state.selectedBank,
      amount: this.state.withdrawAmount,
      note: "payout",
    };
    const responseWithDraw = await services.payOut(dataToSend);
    const responseWithDrawJson = await responseWithDraw.json();
    if (responseWithDraw.status == "200") {
      alert(responseWithDrawJson.message);
      this.setState({ withDrawModal: !this.state.withDrawModal });
    } else {
      alert(responseWithDrawJson.message);
      this.setState({ withDrawModal: !this.state.withDrawModal });
    }
  };
  withDrawModalClose = (e) => {
    this.setState({ withDrawModal: !this.state.withDrawModal });
  };
  selectBanks = (e) => {
    this.setState({ selectedBank: e.target.value });
  };
  Liquidate = async (e) => {
    this.setState({ bankLoader: true });
    const response = await services.getBanks();
    const responseJson = await response.json();
    if (response.status == "200") {
      this.setState({ banks: responseJson.data.rows });
      this.setState({ bankLoader: false });
      this.setState({ liquidateModal: !this.state.liquidateModal });
      this.setState({ selectedBank: "" });
    } else if (response.status == "401") {
      alert("authorization Problem");
    } else {
      alert(responseJson.message);
    }
  };
  saveLiquidate = async (e) => {
    let a = window.confirm("Are you sure you want to liquidate your goal?");
    if (a) {
      let id = this.props.match.params.id;
      id = JSON.parse(id);
      id = id.goal_id;
      let dataToSend = {
        goal_id: id,
        bank_id: this.state.selectedBank,
        note: "payout",
      };
      const responseLiquidate = await services.liquidateGoal(dataToSend);
      const rresponseLiquidateJson = await responseLiquidate.json();
      if (responseLiquidate.status == "200") {
        alert(rresponseLiquidateJson.message);
        this.setState({ liquidateModal: !this.state.liquidateModal });
      } else {
        alert(rresponseLiquidateJson.message);
        return;
      }
    } else {
      return;
    }
  };
  liquidateModalClose = (e) => {
    this.setState({ liquidateModal: !this.state.liquidateModal });
  };
  addFundHandle = async (e) => {
    this.setState({ bankLoader: true });
    const response = await services.getBanks();
    const responseJson = await response.json();
    if (response.status == "200") {
      this.setState({ banks: responseJson.data.rows });
      this.setState({ bankLoader: false });
      this.setState({ addFundModal: !this.state.addFundModal });
      this.setState({ selectedBank: "" });
    } else if (response.status == "401") {
      alert("authorization Problem");
    } else {
      alert(responseJson.message);
    }
  };
  addFundAmount = (e) => {
    this.setState({ addFundAmount: e.target.value });
    if (e.target.value == "" || e.target.value <= "0") {
      this.setState({ addFundAmountNegativeErrorMessage: true });
      return;
    }
  };
  addFundModalClose = (e) => {
    this.setState({ addFundModal: !this.state.addFundModal });
  };
  addFundSave = async (e) => {
    if (this.state.addFundAmountNegativeErrorMessage) {
      return;
    } else {
      this.setState({ addFundAmountNegativeErrorMessage: false });
    }
    if (this.state.selectedBank == "") {
      this.setState({ SelectBankMessage: "Select bank to continue" });
      return;
    } else {
      this.setState({ SelectBankMessage: "" });
    }
    if (this.state.addFundAmount == "" || this.state.addFundAmount <= 0) {
      this.setState({ addFundAmountNegativeErrorMessage: true });
      return;
    } else {
      this.setState({ addFundAmountNegativeErrorMessage: false });
    }
    let id = this.props.match.params.id;
    id = JSON.parse(id);
    console.log(id, "id");
    let accountID = id.accountID;
    let accountNo = id.accountNo;
    id = id.goal_id;
    const dataToSend = {
      goal_id: id,
      accountID: accountID,
      accountNo: accountNo,
      bank_id: this.state.selectedBank,
      amount: parseInt(this.state.addFundAmount),
      note: " ",
    };
    const responseAddFund = await services.addFund(dataToSend);
    const responseAddFundJson = await responseAddFund.json();
    if (responseAddFund.status == "200") {
      alert(responseAddFundJson.message);
      this.setState({ addFundModal: !this.state.addFundModal });
    } else {
      alert(responseAddFundJson.message);
      return;
    }
  };
  getGraphValuesApi = async (goalData) => {
    let recurring_amount = goalData.recurringDeposits[0].amount;
    // alert(recurring_amount);
    let goal_amount = goalData.goal_amount;

    let remainingdays = goalData.remaining.day;
    let remainingmonths = goalData.remaining.month;
    let remainingyear = goalData.remaining.year;

    remainingyear = remainingyear * 365;
    remainingmonths = remainingmonths * 30;
    remainingdays = remainingdays;

    let totalRemainingDays = parseInt(remainingyear + remainingmonths);
    totalRemainingDays = -(-totalRemainingDays - remainingdays);

    if (recurring_amount == "monthely") {
      recurring_amount = "0";
    } else {
      recurring_amount = recurring_amount;
    }
    let deposit = goalData.GoalDepositAmount;

    let goalCreateDate = goalData.createdAt.split("T")[0];
    let goalTragetDate = goalData.accumulation_horizon;
    this.setState({ goalTragetDate: goalTragetDate });
    let target_year = getTargetYears(goalCreateDate, goalTragetDate);

    let rate = 10;
    let daysSpent = target_year - totalRemainingDays;
    this.getGraphValuesApisss(
      recurring_amount,
      deposit,
      rate,
      totalRemainingDays,
      daysSpent,
      goal_amount,
      goalData.createdAt.split("T")[0]
    );
  };
  getGraphValuesApisss(
    MonthlyPayment,
    Initial,
    rate,
    GoalDue,
    daysSpent,
    goal_amount,
    goalCreateDate
  ) {
    let FuturePortfolioValues = new Array();
    let daysPast = new Array();

    let value = Initial;
    let daysGone = daysSpent;
    FuturePortfolioValues.push(value);
    daysPast.push(daysGone);
    let date = new Array();
    let d = new Date(goalCreateDate);
    while (daysGone <= GoalDue) {
      value = value * (1 + rate / 12 / 100);
      daysGone += 30;
      if (goal_amount >= value) {
        FuturePortfolioValues.push(value);
        value = -(-value - MonthlyPayment);
        daysPast.push(daysGone);
        let da = new Date(d.setMonth(d.getMonth() + 1)).toString().split(" ");
        da = da[1] + " " + da[3];
        date.push(da);
        this.setState({ xAxis: date });
      }
    }

    console.log(date, "date");
    if (value < goal_amount) {
      value = -(-value - MonthlyPayment);
      FuturePortfolioValues.push(value);
    }

    let currentIndex = 0;

    const dataGraphValue = {
      datasets: [
        {
          data: FuturePortfolioValues,
          color: (opacity = 1) => `rgba(255,0,0,${opacity})`,
          fill: true,
        },
      ],
      fill: true,
      legend: ["Current"],
    };
    console.log(dataGraphValue.datasets[0].data, "dataGraphValue");
    this.setState({ pastDataSet: dataGraphValue.datasets[0].data });
    let chartOptions = this.state.chartOptions;
    console.log(chartOptions, "chartOptions");
    chartOptions.series[0].data = dataGraphValue.datasets[0].data;
    chartOptions.xAxis.categories = date;
    console.log(chartOptions, "chartOptions");
    this.setState({ chartOptions: chartOptions });
    // this.setState({ pastDataSetShow: true });
  }
  abbreviateNumber(value) {
    var value = value.toFixed(0);
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "k", "m", "b", "t"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  getInsights = (goalData) => {
    console.log("++getInsights");
    if (goalData.isDefault === false) {
      this.state.AmountAchieved =
        (goalData.balance.accountSummary.equity.equityValue +
          goalData.balance.accountSummary.cash.cashBalance /
            goalData.goal_amount) *
        100;

      let inf = 2.1;
      let retun = 15 - inf;
      let std_dev = 2;
      let conf = 0.95;
      this.setState({ accumulation_horizon: goalData.accumulation_horizon });
      let goal_due_date = goalData.accumulation_horizon.split("-");

      let selectedYear = goal_due_date[0];
      let selectedMonth = goal_due_date[1];
      var differenceInTime = this.dateDifference(selectedYear, selectedMonth);
      var goalDue = differenceInTime / (1000 * 3600 * 24);
      console.log("---");
      console.log(goalDue, "GoalDue");
      console.log(goalData.Goal_Recurring_Deposit, "recurring");
      console.log(
        goalData.balance.accountSummary.equity.equityValue +
          goalData.balance.accountSummary.cash.cashBalance,
        "balance"
      );
      console.log("---");

      if (goalDue <= 0) {
        console.log(goalDue, "GoalDue is negative");
        // this.state.GoalInPast = true;
        this.setState({GoalInPast:true})

        return;
      } else {
        this.setState({GoalInPast:false})
        // this.state.GoalInPast = false;
      }

      //FV = (rate, nper, pmt, pv)
      let projectedFV = this.FV(
        retun / 12 / 100,
        goalDue / (365 / 12),
        goalData.Goal_Recurring_Deposit,
        goalData.balance.accountSummary.equity.equityValue +
          goalData.balance.accountSummary.cash.cashBalance
      );
      this.setState({ projectedFV: projectedFV });
      console.log(projectedFV, "projected FV");
    }
  };
  FV = (rate, nper, pmt, pv) => {
    var pow = Math.pow(1 + rate, nper);
    let fv;
    if (rate) {
      fv = (pmt * (pow - 1)) / rate + pv * pow;
    } else {
      fv = pv + pmt * nper;
    }
    return fv;
  };

  dateDifference = (selectedYear, selectedMonth) => {
    let startingDate = new Date();
    var endingDate = selectedYear + "-" + selectedMonth + "-" + "01";
    var dateCurrent = startingDate.getTime();
    var dateSelected = new Date(endingDate);
    var dateFuture = dateSelected.getTime();
    var dif = dateFuture - dateCurrent;
    return dif;
  };
  render() {
    return (
      <section className="dashboard-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Header />
              <div className="heading-info-top" style={{marginTop: '-25px',marginBottom: '0px'}}>
                <div className="row">
                  <div className="col-md">
                    <h1 className="dash-main-heading">Goal Detail</h1>
                  </div>
                  <div className="col-md text-md-right">
                    <ul className="dash-top-list clearfix">
                      <li>
                        <a
                          href="javascript:void(0)"
                          className="del-anchor"
                          onClick={this.delete}
                        >
                          <img
                            src="/images/delete-icon.svg"
                            alt="Delete Icon"
                          />
                        </a>
                      </li>
                      <li>
                        <Link to={"/update-goal/" + this.props.match.params.id}>
                          <img src="/images/edit-icon-1.svg" alt="Edit Icon" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="notification-box">
                  <div className="notf-heading">
                    <h4>Notifications</h4>
                  </div>
                  <div className="notification-list">
                    <div className="notf-row clearfix">
                      <div className="notf-img">
                        <img src="/images/avatar-1.png" alt="" />
                      </div>
                      <div className="notf-detail">
                        <h5>
                          <b>David Lee</b> sent you a message.
                        </h5>
                        <p className="notf-time">4 min ago</p>
                      </div>
                    </div>
                    <div className="notf-viewall">
                      <a href="#">View all notifications</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="dash-card">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                            />
                                </div> */}
              <section
                className="section section-compare-goal"
                style={{ paddingBottom: "50px;", paddingTop: "10px" }}
              >
                <div className="container">
                  <div style={{paddingTop:'5px'}} className="goal-tabs-wrapper" id={"tabs_data_content"}>
                    <div className="goal-tabs-inner">
                      <ul className="nav nav-tabs clearfix">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabGoalDashboard"
                          >
                            <p className="goal_detail_big"> Goal Dashboard</p>
                            <img style={{height: '40px'}}
                              src={"images/icons/goal_detail_inactive.png"}
                              className="goal_detail_img_big"
                            ></img>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabPerformance"
                            onClick={this.getPerformance}
                          >
                            <p className="goal_detail_big">Performance</p>
                            <img style={{height: '40px'}}
                              src={"images/icons/performance_inactive.png"}
                              className="goal_detail_img_big"
                            ></img>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabTransactions"
                            onClick={this.getDWTransactions}
                          >
                            <p className="goal_detail_big">Transactions</p>
                            <img style={{height: '40px'}}
                              src={"images/icons/transaction_inactive.png"}
                              className="goal_detail_img_big"
                            ></img>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabSummary"
                            onClick={this.getSummary}
                          >
                            <p className="goal_detail_big">Summary</p>
                            <img style={{height: '40px'}}
                              src={"images/icons/summary_inactive.png"}
                              className="goal_detail_img_big"
                            ></img>
                          </a>
                        </li>
                      </ul>

                      <div
                        className="tab-content"
                        style={{ marginTop: "20px" }}
                      >
                        <div
                          className="tab-pane container active"
                          id="tabGoalDashboard"
                        >
                          <div className="row">
                            <div
                              className="col-sm-4"
                              style={{
                                backgroundColor: "#e6e6e6",
                                padding: "20px",
                                borderRadius: "15px",
                                boxShadow: "0px 3px 8px 0px rgb(121 110 110)",
                              }}
                            >
                              <center>
                                <h2
                                  style={{
                                    fontWeight: "700",
                                    color: "#3a5fac",
                                  }}
                                >
                                  {this.state.goalData.name}
                                </h2>
                                <h5
                                  style={{
                                    fontWeight: "600",
                                    color: "#3a5fac",
                                    marginTop: "-5px",
                                  }}
                                >
                                  {this.state.goalData.accountNo}
                                </h5>
                                <h5
                                  style={{
                                    fontWeight: "600",
                                    color: "#3a5fac",
                                    marginTop: "-5px",
                                  }}
                                >
                                  Target amount: {"$"}
                                  {this.state.goalData.goal_amount}
                                </h5>

                                {/* <h2 style={{fontWeight:'600',color:'#3a5fac',marginTop:'5px'}}>Target amount: {"$"}{this.state.goalData.balance.equity.equityValue}</h2> */}
                              </center>
                              <div>
                                {this.state.goalData.isDefault === false?
                                <center>
                                  <CircularProgressbar
                                    value={this.state.goalPercentage}
                                    text={`${this.state.goalPercentage}%`}
                                  />
                                </center>
                                :''}
                              </div>
                            </div>
                            <div className="col-sm-8">
                              <h3 style={{textAlign: 'left'}}>Insights</h3>

                              {!this.state.GoalInPast ? (
                                <>
                                <div className="row">
                                  <div className="col-sm-1">
                                    <img style={{height:'30px'}} src="images/growth.png"></img>
                                  </div>
                                  <div className="col-sm-11">
                                    <p style={{textAlign: 'left'}}>
                                      {" "}
                                      This goal is projected to grow to{" "}
                                      <strong>
                                        {" "}
                                        $
                                        {parseInt(
                                          this.state.projectedFV
                                        ).toLocaleString()}
                                      </strong>{" "}
                                      by {this.state.accumulation_horizon}
                                    </p>
                                  </div>
                                </div>
                                </>
                              ):''
                              }
                                <div className="row" style={{marginTop:'10px'}}>
                                  <div className="col-sm-1">
                                    <img  style={{height:'30px'}} src="images/about.png"></img>
                                  </div>
                                  <div className="col-sm-11">
                                    <p style={{textAlign: 'left'}}>
                                    As your savings grow, you will find more insights and
                        suggested actions for your goal.
                                    </p>
                                  </div>
                                </div>
                               
                             
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane container fade"
                          id="tabPerformance"
                        >
                          {this.state.loading ? (
                            <center>
                              <div className="fa-3x">
                                <i className="fas fa-spinner fa-spin"></i>
                              </div>
                            </center>
                          ) : (
                            ""
                          )}
                          <div className="row">
                            <div
                              className="col-sm-12"
                              style={{
                                backgroundColor: "rgb(230, 230, 230)",
                                padding: "20px",
                                borderRadius: "15px",
                                boxShadow: "0px 3px 8px 0px rgb(121 110 110)",
                              }}
                            >
                              <center>
                                <h3>Net Balance</h3>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <h3>
                                      {"$"}
                                      {this.state.equityValue.toLocaleString()}
                                    </h3>
                                  </div>
                                  <div className="col-sm-6">
                                    <h3>
                                      {parseFloat(
                                        this.state.percentage
                                      ).toFixed(1)}
                                      {"%"}
                                    </h3>
                                  </div>
                                </div>
                              </center>
                            </div>
                            <div
                              className="col-sm-12"
                              style={{ marginTop: "20px" }}
                            >
                              {this.state.performanceData.map((item, index) => (
                                <div
                                  className="goal-box"
                                  style={{
                                    borderBotom: "1px solid",
                                    backgroundColor: "white",
                                    margin: '0px',
                                    paddingBottom: '0px'
                                    // borderRadius: " 35px",
                                  }}
                                  key={"goal-box" + index}
                                >
                                  <div
                                    className="row goal-box-info"
                                    key={"goal-box-info" + index}
                                  >
                                    <div
                                      className="col-md-6 col-xs-6 col-sm-6 col-6"
                                      key={"goal-box-col-6" + index}
                                    >
                                      <h5
                                        key={"goal-h5" + index}
                                        style={{
                                          color: "#3a5dac",
                                          fontWeight: "700",
                                          display: "flex",
                                          margin: '0px'
                                        }}
                                      >
                                        {" "}
                                        <img
                                          style={{ height: "30px" }}
                                          src={
                                            "https://drivewealth.imgix.net/symbols/" +
                                            item.symbol.toLowerCase() +
                                            ".png"
                                          }
                                        ></img>
                                        <h5
                                          style={{
                                            marginTop: "6px",
                                            color: "#3a5fac",
                                            marginLeft: "5px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          {item.symbol}
                                        </h5>
                                      </h5>
                                    </div>
                                    <div
                                      className="col-md-6 col-xs-6 col-sm-6 col-6"
                                      key={"goal-box-col-62" + index}
                                    >
                                      <h5
                                        style={{
                                          float: "right",
                                          color: "#3a5fac",
                                          marginTop: '5px',
                                        }}
                                      >
                                        {"$" +
                                          item.marketValue.toLocaleString()}
                                      </h5>
                                    </div>
                                    <div
                                      className="col-md-6 col-xs-6 col-sm-6 col-6"
                                      key={"goal-box-col-6" + index}
                                    >
                                      <h5
                                        style={{
                                          float: "left",
                                          marginTop: "10px",
                                          color: "#3a5fac",
                                        }}
                                      >
                                        {" "}
                                        {item.availableForTradingQty.toFixed(3)}
                                      </h5>
                                    </div>
                                    <div
                                      className="col-md-6 col-xs-6 col-sm-6 col-6"
                                      key={"goal-box-col-6" + index}
                                    >
                                      <h5
                                        style={{
                                          color:
                                            item.unrealizedDayPL > 0
                                              ? "green"
                                              : "red",
                                          float: "right",
                                        }}
                                      >
                                        {"$"}
                                        {item.unrealizedDayPL > 0
                                          ? item.unrealizedDayPL
                                          : -item.unrealizedDayPL}{" "}
                                        {"(" +
                                          item.unrealizedDayPLPercent +
                                          "%)"}
                                        <i
                                          className={
                                            item.unrealizedDayPL > 0
                                              ? "fa fa-caret-up"
                                              : "fa fa-caret-down"
                                          }
                                        ></i>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {this.state.performanceData.length > 0 ? (
                              <div className="col-sm-12">
                                <div
                                  className="goal-box"
                                  key={"goal-box"}
                                  style={{
                                    borderRadius: "15px",
                                    backgroundColor: "#3a5fac",
                                  }}
                                >
                                  <div
                                    className="row goal-box-info"
                                    key={"goal-box-info"}
                                  >
                                    <div
                                      className="col-md-6 col-xs-6 col-sm-6"
                                      key={"goal-box-col-6"}
                                    >
                                      <h5
                                        key={"goal-h5"}
                                        style={{
                                          color: "white",
                                          fontWeight: "700",
                                          display: "flex",
                                        }}
                                      >
                                        <h5
                                          style={{
                                            marginTop: "6px",
                                            color: "white",
                                            marginLeft: "5px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          Cash
                                        </h5>
                                      </h5>
                                    </div>
                                    <div
                                      className="col-md-6 col-xs-6 col-sm-6"
                                      key={"goal-box-col-62"}
                                    >
                                      <h5
                                        style={{
                                          float: "right",
                                          color: "white",
                                        }}
                                      >
                                        {"$" +
                                          this.state.cashMarketValue.toLocaleString()}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div
                          className="tab-pane container fade"
                          id="tabTransactions"
                        >
                          <div className="row">
                            <div className="col-sm-4 col-4">
                              <center>
                                <button
                                  className="btn-withdraw"
                                  onClick={this.WithDraw}
                                  style={{
                                    backgroundColor: "white",
                                    color: "#3a5fac",
                                  }}
                                >
                                  Withdraw
                                </button>
                              </center>
                            </div>

                            <div className="col-sm-4 col-4">
                              <center>
                                {" "}
                                <button
                                  className="btn-withdraw"
                                  onClick={this.addFundHandle}
                                  style={{
                                    backgroundColor: "#3a5fac",
                                    color: "white",
                                  }}
                                >
                                  Add{" "}Fund
                                </button>
                              </center>
                            </div>
                            <div className="col-sm-4 col-4">
                              <center>
                                {" "}
                                <button
                                  className="btn-withdraw"
                                  onClick={this.Liquidate}
                                  style={{
                                    backgroundColor: "white",
                                    color: "#3a5fac",
                                  }}
                                >
                                  Liquidate
                                </button>
                              </center>
                            </div>
                          </div>
                          <div className="container">
                            <div
                              className="goal-tabs-wrapper"
                              style={{ padding: "20px 0" }}
                              id={"tabs_data_content"}
                            >
                              <div className="goal-tabs-inner">
                                <ul className="nav nav-tabs clearfix">
                                  <li
                                    className="nav-item"
                                    style={{ width: "50%" }}
                                  >
                                    <a
                                      className="nav-link active"
                                      data-toggle="tab"
                                      href="#tabdwTransactions"
                                      onClick={this.getDWTransactions}
                                    >
                                      DW transactions
                                    </a>
                                  </li>
                                  <li
                                    className="nav-item"
                                    style={{ width: "50%" }}
                                  >
                                    <a
                                      className="nav-link"
                                      data-toggle="tab"
                                      href="#tabSimpleTransaction"
                                    >
                                      Transactions
                                    </a>
                                  </li>
                                </ul>

                                <div
                                  className="tab-content"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div
                                    className="tab-pane container active"
                                    id="tabdwTransactions"
                                  >
                                    <div className="col-sm-12">
                                      {this.state.DWTransactionsData.map(
                                        (item, index) => (
                                          <div
                                            className="goal-box"
                                            style={{
                                              // border: "2px solid",
                                              backgroundColor: "white",
                                              paddingBottom: '0px',
                                              // borderRadius: " 35px",
                                            }}
                                            key={"goal-box" + index}
                                          >
                                            <div
                                              className="row goal-box-info"
                                              key={"goal-box-info" + index}
                                            >
                                              <div
                                                className="col-md-6 col-xs-6 col-sm-6 col-6"
                                                key={"goal-box-col-6" + index}
                                              >
                                                {item.finTranTypeID == "SPUR" ||
                                                item.finTranTypeID == "SSAL" ? (
                                                  <>
                                                    <h5
                                                      key={"goal-h5" + index}
                                                      style={{
                                                        color: "#3a5dac",
                                                        fontWeight: "700",
                                                        display: "flex",
                                                        margin: '0px'
                                                      }}
                                                    >
                                                      {" "}
                                                      <img
                                                        style={{
                                                          height: "30px",
                                                        }}
                                                        src={
                                                          "https://drivewealth.imgix.net/symbols/" +
                                                          item.instrument.symbol.toLowerCase() +
                                                          ".png"
                                                        }
                                                      ></img>
                                                      <h5
                                                        style={{
                                                          marginTop: "6px",
                                                          color: "#3a5fac",
                                                          marginLeft: "5px",
                                                          fontWeight: "700",
                                                        }}
                                                      >
                                                        {item.instrument.symbol}
                                                      </h5>
                                                    </h5>
                                                  </>
                                                ) : item.finTranTypeID ==
                                                  "CSR" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    Deposit
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "CSD" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    Disbursement
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "FEE" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    Fee
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "JNLC" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    A cash journal was made from
                                                    one account to another
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "CADJ" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    A credit adjustment was
                                                    made, generally used for
                                                    returned ACH transactions
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "INT" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    An interest adjustment
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "MERGER_ACQUISITION" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    A merger or acquisition has
                                                    taken place
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "DIV" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    A dividend payment
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "DIVTAX" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    A tax withholding on a
                                                    dividend
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "DIVNRA" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    A tax withholding on a
                                                    dividend for a non-resident
                                                    alien
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "STCK" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    A stock price adjustment
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "COMM" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    A commission
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "SPINOFF" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    Stock spinoff
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "STOCK_SPLIT" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    Stock split/reverse stock
                                                    split
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "ACATS_CASH" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    ACATS cash delivery or
                                                    receive
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "ACATS_STOCK" ? (
                                                  <h5
                                                    style={{
                                                      color: "#3a5fac",
                                                      fontWeight: "700",
                                                      float: "left",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    ACATS stock delivery or
                                                    receive
                                                  </h5>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div
                                                className="col-md-6 col-xs-6 col-sm-6 col-6"
                                                key={"goal-box-col-62" + index}
                                              >
                                                {item.accountAmount > 0 ? (
                                                  <i
                                                    style={{
                                                      color: "green",
                                                      float: "right",
                                                    }}
                                                    className="fa fa-arrow-down"
                                                  ></i>
                                                ) : (
                                                  <i
                                                    style={{
                                                      color: "red",
                                                      float: "right",
                                                    }}
                                                    className="fa fa-arrow-up"
                                                  ></i>
                                                )}
                                                <h5
                                                  style={{
                                                    float: "right",
                                                    color: "#3a5fac",
                                                  }}
                                                >{" "}
                                                  {item.accountAmount > 0
                                                    ? "$" +
                                                      item.accountAmount.toLocaleString()
                                                    : "$" +
                                                      -item.accountAmount.toLocaleString()}
                                                </h5>
                                              </div>
                                              <div
                                                className="col-md-6 col-xs-6 col-sm-6 col-6"
                                                key={"goal-box-col-6" + index}
                                              >
                                                {item.finTranTypeID ==
                                                "SPUR" ? (
                                                  <h5
                                                    style={{
                                                      float: "left",
                                                      // marginTop: "10px",
                                                      color: "#3a5fac",
                                                      // display: "flex",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    {"Bought "}
                                                    <strong >
                                                      {" " +
                                                        item.fillQty.toFixed(2)}
                                                    </strong>
                                                    {" shares at "}  {" "}
                                                    <strong
                                                      style={{
                                                        marginLeft: "4px",
                                                        marginRight: "4px",
                                                        fontWeight: "700",
                                                        color: "#3a5fac",
                                                        margin: '0px',
                                                      }}
                                                    > 
                                                      {" $" +
                                                        item.fillPx
                                                          .toFixed(2)
                                                          .toLocaleString()}
                                                           {" "}
                                                    </strong>
                                                  </h5>
                                                ) : item.finTranTypeID ==
                                                  "SSAL" ? (
                                                  <h5
                                                    style={{
                                                      float: "left",
                                                      // marginTop: "10px",
                                                      color: "#3a5fac",
                                                      display: "flex",
                                                      margin: '0px',
                                                    }}
                                                  >
                                                    {"Sell "}  {" "}
                                                    <strong>
                                                      {" " +
                                                        item.fillQty.toFixed(2)}  {" "}
                                                    </strong>
                                                    {" shares at "}  {" "}
                                                    <strong>
                                                      {" $" +
                                                        item.fillPx
                                                          .toFixed(2)
                                                          .toLocaleString()}  {" "}
                                                    </strong>
                                                  </h5>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div
                                                className="col-md-6 col-xs-6 col-sm-6 col-6"
                                                key={"goal-box-col-6" + index}
                                              >
                                                <h5
                                                  style={{
                                                    float: "right",
                                                    fontWeight: "700",
                                                    color: "#3a5fac",
                                                  }}
                                                >
                                                  
                                                  {
                                                    new Date(
                                                      item.tranWhen.replace(
                                                        " ",
                                                        ""
                                                      )
                                                    ).toLocaleString("en-us", {
                                                      month: "short",
                                                      year: "numeric",
                                                    })
                                                  }
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane container active"
                                    id="tabSimpleTransaction"
                                  >
                                    <div className="dash-card">
                                      <div className="table-responsive">
                                        <table className="table">
                                          <thead>
                                            <tr>
                                              <th>Bank</th>
                                              <th>Transaction Date</th>
                                              <th>Category</th>
                                              <th>Status</th>
                                              <th>Amount</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.transactionsData.map(
                                              (items, index) => (
                                                <tr>
                                                  <td>
                                                    <img
                                                      style={{
                                                        height: "30px",
                                                        marginRight: "10px",
                                                      }}
                                                      src={
                                                        +items.user_bank ==
                                                          null ||
                                                        items.user_bank ==
                                                          undefined
                                                          ? ""
                                                          : "data:image/png;base64," +
                                                            items.user_bank.logo
                                                      }
                                                    ></img>
                                                    {items.user_bank == null ||
                                                    items.user_bank == undefined
                                                      ? ""
                                                      : items.user_bank
                                                          .bankAccountNickname}
                                                  </td>
                                                  <td>
                                                    {
                                                      items.transactionDetail.createdAt.split(
                                                        "T"
                                                      )[0]
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      items.transactionDetail
                                                        .category
                                                    }
                                                  </td>
                                                  <td>
                                                    <span
                                                      className={
                                                        items.status ==
                                                        "Successful"
                                                          ? "badge badge-success"
                                                          : items.status ==
                                                            "Approved"
                                                          ? "badge badge-warning"
                                                          : items.status ==
                                                            "Pending"
                                                          ? "badge badge-secondary"
                                                          : ""
                                                      }
                                                    >
                                                      {items.status}
                                                    </span>
                                                  </td>
                                                  <td
                                                    style={{
                                                      color: "#3a5dac",
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {"$" + items.amount.toLocaleString()}
                                                    
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane container fade"
                          id="tabSummary"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              {this.state.summaryData.map((item, index) => (
                                <div
                                onClick={this.downloadFile.bind(
                                  this,
                                  item.fileKey
                                )}
                                  style={{
                                    borderBottom:'1px solid #cfcfcf',
                                    cursor:'pointer'
                                  }}
                                  key={"goal-box" + index}
                                >
                                  <div
                                    className="row goal-box-info"
                                    key={"goal-box-info" + index}
                                  >
                                    <div className="col-sm-6">
                                      <h4
                                        style={{
                                          float: "left",
                                          fontWeight: "700",
                                          color: "#3a5fac",
                                        }}
                                      >
                                        {item.displayName}
                                      </h4>
                                    </div>
                                    <div className="col-sm-6">
                                      <h4
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        <i
                                        
                                          className="fa fa-download"
                                        ></i>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.withDrawModal}
                  toggle={() => this.WithDrawModal("withDrawModal")}
                >
                  <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                      Select Bank to continue
                    </h6>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.withDrawModalClose("withDrawModal")}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.state.bankLoader ? (
                      <center>
                        <div>
                          <i
                            style={{ fintSize: "16px" }}
                            className="fa fa-spinner"
                          ></i>
                          Loading Banks...
                        </div>
                      </center>
                    ) : (
                      ""
                    )}
                    {this.state.banks.map((items, index) => (
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          value={items.dwBankID}
                          onClick={this.selectBanks}
                          id={"customRadio_" + index}
                          name={"customRadio_name"}
                          type="radio"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"customRadio_" + index}
                        >
                          <span>{items.bankAccountNickname}</span>
                        </label>
                      </div>
                    ))}
                    {this.state.SelectBankMessage !== "" ? (
                      <p style={{ color: "red", marginLeft: "15px" }}>
                        {this.state.SelectBankMessage}
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          type={"number"}
                          className="form-control"
                          placeholder="Withdraw Amount"
                          onChange={this.withdrawAmount}
                          defaultValue={this.state.withdrawAmount}
                          style={{ borderRadius: "1px" }}
                        />
                      </div>
                      {this.state.withDrawAmountErrorMessage ? (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          You can WithDraw upto{" "}
                          {"$" +
                            this.state.withdrawAmountOriginal.toLocaleString()}
                        </p>
                      ) : (
                        ""
                      )}
                      {this.state.withDrawAmountNegativeErrorMessage ? (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          Amount should not be negative or zero
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button
                      color="primary"
                      type="button"
                      onClick={this.SaveWithDraw}
                    >
                      WithDraw
                    </Button>
                    <Button
                      className="ml-auto"
                      color="link"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.withDrawModalClose("SignUpModel")}
                    >
                      Close
                    </Button>
                  </div>
                </Modal>
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.liquidateModal}
                  toggle={() => this.WithDrawModal("withDrawModal")}
                >
                  <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                      Select Bank to continue
                    </h6>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.liquidateModalClose("withDrawModal")}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.state.bankLoader ? (
                      <center>
                        <div>
                          <i
                            style={{ fintSize: "16px" }}
                            className="fa fa-spinner"
                          ></i>
                          Loading Banks...
                        </div>
                      </center>
                    ) : (
                      ""
                    )}
                    {this.state.banks.map((items, index) => (
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          value={items.dwBankID}
                          onClick={this.selectBanks}
                          id={"customRadio_" + index}
                          name={"customRadio_name"}
                          type="radio"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"customRadio_" + index}
                        >
                          <span>{items.bankAccountNickname}</span>
                        </label>
                      </div>
                    ))}
                    {this.state.SelectBankMessage !== "" ? (
                      <p style={{ color: "red", marginLeft: "15px" }}>
                        {this.state.SelectBankMessage}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="modal-footer">
                    <Button
                      color="primary"
                      type="button"
                      onClick={this.saveLiquidate}
                    >
                      Liquidate
                    </Button>
                    <Button
                      className="ml-auto"
                      color="link"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.liquidateModalClose("SignUpModel")}
                    >
                      Close
                    </Button>
                  </div>
                </Modal>
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.addFundModal}
                  toggle={() => this.WithDrawModal("withDrawModal")}
                >
                  <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                      Select Bank to continue
                    </h6>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.addFundModalClose("withDrawModal")}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.state.bankLoader ? (
                      <center>
                        <div>
                          <i
                            style={{ fintSize: "16px" }}
                            className="fa fa-spinner"
                          ></i>
                          Loading Banks...
                        </div>
                      </center>
                    ) : (
                      ""
                    )}
                    {this.state.banks.map((items, index) => (
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          value={items.dwBankID}
                          onClick={this.selectBanks}
                          id={"customRadio_" + index}
                          name={"customRadio_name"}
                          type="radio"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"customRadio_" + index}
                        >
                          <span>{items.bankAccountNickname}</span>
                        </label>
                      </div>
                    ))}
                    {this.state.SelectBankMessage !== "" ? (
                      <p style={{ color: "red", marginLeft: "15px" }}>
                        {this.state.SelectBankMessage}
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type={"number"}
                          className="form-control"
                          placeholder="Add Fund"
                          onChange={this.addFundAmount}
                          defaultValue={"0"}
                          style={{ borderRadius: "1px" }}
                        />
                      </div>

                      {this.state.addFundAmountNegativeErrorMessage ? (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          Amount should not be negative or zero
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button
                      color="primary"
                      type="button"
                      onClick={this.addFundSave}
                    >
                      Add Fund
                    </Button>
                    <Button
                      className="ml-auto"
                      color="link"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.addFundModalClose("SignUpModel")}
                    >
                      Close
                    </Button>
                  </div>
                </Modal>
              </section>
            </div>
          </div>
        </div>
        <FooterComponent/>
      </section>
    );
  }
}

export default Index;
const getTargetYears = (goalCreateDate, goalTragetDate) => {
  var createdDate = new Date(goalCreateDate);

  var res = goalTragetDate.replace(" ", "");

  var targetDate = new Date(res);

  var dateCreate = createdDate.getTime();

  var datetarget = targetDate.getTime();

  var dif = datetarget - dateCreate;

  var dif = dif / (1000 * 3600 * 24);

  return dif;
};
