import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import services from "../../utils/services";
import { ToastContainer, toast } from 'react-toastify';
class login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
    }

    email = (e) => {
        this.setState({email: e.target.value})
    }

    forgot = (e) => {

    }
    forgot= async () => {
        const data = {
            email:this.state.email,
          }
          const response = await services.forgotPassword(data);
          const responseJson = await response.json();
          if (responseJson.message == 'Forgot reset Email send to your email address.') {
            // alert(responseJson.message)
            toast["success"](responseJson.message);
            // this.props.history.push("/login/forgotPasswordSuccess")
            // window.location.href="/login";
          }else{
            toast["error"](responseJson.message);
            // alert('in valid username')
          }
    }

    render() {
        return (
            <body className="signup-page">
                 <ToastContainer />
            <section className="reg-section section-height-full">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="reg-form-box">
                            <div className="col-12">
                                <div className="logo-wrapper text-center">
                                    <div className="logo-img">
                                        <img src="/images/logo-A.svg" alt="logo"/>
                                    </div>
                                    <h1>Forgot Password</h1>
                                </div>
                                <div className="form-wrapper">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control" placeholder="name@aghaz.com"
                                            onChange={this.email}
                                        />
                                    </div>

                                    <div className="form-btn text-center">
                                        <button type="submit" className="btn btn-primary btn-signup btn-block" onClick={this.forgot}> Forgot Password
                                        </button>
                                    </div>
                                    <Link to="/login">
                                        Login
                                        </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </body>
        );
    }
}

export default login;
