import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import SideMenue from '../../Components/sideMenue.js'
import InputMask from 'react-input-mask';
import services from "../../utils/services";
import Header from "../../Components/header.js";

import Footer from "../../Components/footer";
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date_of_birth : '',
            first_name : '',
            last_name : '',
            phone_number : '',
            ssn : '',
            address1 : '',
            address2 : '',
            city : '',
            Userstate : '',
            country : '',
            postal_code : '',
            id:''
        }
    }
    date_of_birth = (e)=>{

        this.setState({
            date_of_birth:e.target.value
        })
        if(e.target.value != ''){
            this.setState({
                date_of_birthMessage:""
            })
        }
    }
    first_name = (e)=>{
        this.setState({
            first_name:e.target.value
        })
        if(e.target.value != ''){
            this.setState({
                first_nameMessage:""
            })
        }
    }
    last_name = (e)=>{
        this.setState({
            last_name:e.target.value
        })
    }
    
    phone_number = (e)=>{
        this.setState({
            phone_number:e.target.value
        })
        if(e.target.value != ''){
            this.setState({
                phone_numberMessage:""
            })
        }
    }
    
    
    ssn = (e)=>{
        this.setState({
            ssn:e.target.value
        })
        if(e.target.value != ''){
            this.setState({
                ssnMessage:""
            })
        }
    }
    
    address1 = (e)=>{
        this.setState({
            address1:e.target.value
        })
        if(e.target.value != ''){
            this.setState({
                address1Message:""
            })
        }
    }
    
    address2 = (e)=>{
        this.setState({
            address2:e.target.value
        })
    }
    
    city = (e)=>{
        this.setState({
            city:e.target.value
        })
        if(e.target.value != ''){
            this.setState({
                cityMessage:""
            })
        }
    }

    
    Userstate = (e)=>{
        this.setState({
            Userstate:e.target.value
        })
        if(e.target.value != ''){
            this.setState({
                stateMessage:""
            })
        }
    }
    country = (e)=>{
        this.setState({
            country:e.target.value
        })
        if(e.target.value != ''){
            this.setState({
                countryMessage:""
            })
        }
    }
    
    postal_code = (e)=>{
        this.setState({
            postal_code:e.target.value
        })
        if(e.target.value != ''){
            this.setState({
                postal_codeMessage:""
            })
        }
    }
    validateForm=  (e) => {
        let date_of_birth = this.state.date_of_birth;
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let ssn = this.state.ssn;
        let address1 = this.state.address1;
        let address2 = this.state.address2;
        let city = this.state.city;
        let state = this.state.Userstate;
        let country = this.state.country;
        let postal_code = this.state.postal_code;
        let phone_number = this.state.phone_number;
        let isValid = false;
        // await functions.setItemObject("RememberMeUserName", email);
        console.log(state);
        if(date_of_birth == '') {
          this.setState ({
            date_of_birthMessage : 'Date of birth is required'
          })
          
        }else if(new Date(date_of_birth)>new Date()){
            this.setState ({
                date_of_birthMessage : 'Date of birth can not be a future date'
              })
          } else if(first_name == '') {
          this.setState ({
            first_nameMessage : 'First Name is required'
          })
        } else if(last_name == '') {
            this.setState ({
                last_nameMessage : 'Last Name is required'
            })
          }  else if(phone_number == '') {
            this.setState ({
                phone_numberMessage : 'Phone Number is required'
            })
          }else if(phone_number.length < 17) {
              console.log(phone_number)
            this.setState ({
                phone_numberMessage : 'Valid Phone Number is required'
            })
          }else if(ssn == '') {
            this.setState ({
                ssnMessage : 'SSN is required'
            })
          } else if(ssn.length < 11) {
          this.setState ({
            ssnMessage : 'Valid SSN is required'
          })
        }else if(address1 == '') {
            this.setState ({
                address1Message : 'Address is required'
            })
          } else if(city == '') {
            this.setState ({
                cityMessage : 'City is required'
            })
          } else if(state == '') {
            this.setState ({
                stateMessage : 'State is required'
            })
          } else if(country == '') {
            this.setState ({
                countryMessage : 'Country is required'
            })
          } else if(postal_code == '') {
            this.setState ({
                postal_codeMessage : 'Postal Code is required'
            })
          
          } else if(postal_code <5) {
            this.setState ({
                postal_codeMessage : 'Postal Code must be 5 digits'
            })
        
          }else {
          isValid = true;
        }
        return isValid;
      }
componentDidMount(){
    this.getpersonalInfo();
    this.getcontactInfo();
}

getpersonalInfo= async () => {
    const response = await services.getProfile();
    const responseJson = await response.json();
    if(responseJson.message == 'User found.'){
        this.setState({first_name:responseJson.data.first_name})
        this.setState({last_name:responseJson.data.last_name})
        this.setState({date_of_birth:responseJson.data.date_of_birth})
       
        let current_datetime = responseJson.data.date_of_birth;
        if(current_datetime != '' && current_datetime !== null){
        let date = current_datetime.split('-');
        let day = date[2].split('T');
        let final_date = day[0]+"/"+date[1]+"/"+date[0];
        this.setState({date_of_birth:'"'+final_date+'"'})
        console.log(final_date)
        }
        if(responseJson.data.phone_number != '' && responseJson.data.phone_number !== null){
        this.setState({phone_number:responseJson.data.phone_number.trim()})
        }
        if(responseJson.data.identification_number != '' && responseJson.data.identification_number !== null){
            this.setState({ssn:responseJson.data.identification_number.trim()})
            }
       

    }
}

getcontactInfo= async () => {
    const response = await services.getContactInfo();
    const responseJson = await response.json();
    if(responseJson.message == 'List of Addresses'){
        this.setState({id:responseJson.data.rows[0].id})
        this.setState({address1:responseJson.data.rows[0].address_line1})
        this.setState({address2:responseJson.data.rows[0].address_line2})
        this.setState({postal_code:responseJson.data.rows[0].postalcode})
        this.setState({city:responseJson.data.rows[0].city})
        this.setState({country:responseJson.data.rows[0].country})
        this.setState({Userstate:responseJson.data.rows[0].state})
        document.getElementById("userState").value = responseJson.data.rows[0].state;
        document.getElementById("userCountry").value = responseJson.data.rows[0].country;
    }
}
update = async () =>{
    if(this.validateForm()){
        const client_id = localStorage.getItem('clientId');
        const PersonalInfo = {
            first_name:this.state.first_name,
            last_name:this.state.last_name,
            phone_number:this.state.phone_number,
            date_of_birth:this.state.date_of_birth,
            identification_number:this.state.ssn
        }
        console.log(PersonalInfo)
        const response = await services.updateProfilePersonal(PersonalInfo);
    const responseJson = await response.json();
    if(responseJson.message == 'Client data is updated successfully.'){
        const contactInfo = {
            address_line1:this.state.address1,
            city:this.state.phone_number,
            type:"Home",
            postalcode:this.state.postal_code,
            country:this.state.country,
            state:this.state.Userstate,
            id:this.state.id
        }
        console.log(contactInfo)
        const responseContact = await services.updateProfileContact(contactInfo);
        const responseContactJson = await responseContact.json();
        if(responseContactJson.message == 'Address updated successfully'){
            this.setState({successMessage:'Profile Updated Successfully'})
            document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }else{
        alert(responseJson.message)
    }
}
}


beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
 
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
 
    return {
      value,
      selection
    };
  }
 
    render()
    {
        return (
            <section className="dashboard-section">
                <div className="container-fluid">
                    <div className="row">
                        <SideMenue/>
                        <div className="col-md-12">
                            <Header/>
                            <div className="heading-info-top">
                                <div className="row">
                                    <div className="col-md">
                                        <h1 className="dash-main-heading">Edit Profile</h1>
                                    </div>
                                    <div className="col-md text-md-right">
                                        <ul className="dash-top-list clearfix">
                                            <li>
                                                <a href="#" className="del-anchor">
                                                    <img src="/aghaz/images/delete-icon.svg" alt="Delete Icon"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="/aghaz/images/edit-icon-1.svg" alt="Edit Icon"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="notification-anchor">
                                                    <span className="notf-alert"></span>
                                                    <img src="/aghaz/images/notifications.svg" alt="notification icon"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="notification-box">
                                    <div className="notf-heading">
                                        <h4>Notifications</h4>
                                    </div>
                                    <div className="notification-list">
                                        <div className="notf-row clearfix">
                                            <div className="notf-img">
                                                <img src="/aghaz/images/avatar-1.png" alt=""/>
                                            </div>
                                            <div className="notf-detail">
                                                <h5><b>David Lee</b> sent you a message.</h5>
                                                <p className="notf-time">4 min ago</p>
                                            </div>
                                        </div>
                                        <div className="notf-viewall">
                                            <a href="#">View all notifications</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dash-card">

                                {this.state.successMessage != ''?
                            <div>
                                <center><h2 style={{color:'green'}}>{this.state.successMessage}</h2></center>
                            </div>  :''  
                            }
                                <h2>Personal Information</h2>
                                <div className="form-wrapper">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <input type="date" className="form-control" placeholder="Date of Birth"
                                                onChange={this.date_of_birth}
                                                defaultValue={this.state.date_of_birth}
                                                
                                                />
                                                  {this.state.date_of_birthMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.date_of_birthMessage }</p>
                                        : ''
        }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="First Name"
                                                 onChange={this.first_name}
                                                 defaultValue={this.state.first_name}
                                                />
                                                 {this.state.first_nameMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.first_nameMessage }</p>
                                        : ''
        }
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Last Name"
                                                 onChange={this.last_name}
                                                 defaultValue={this.state.last_name}/>
                                                  {this.state.last_nameMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.last_nameMessage }</p>
                                        : ''
        }
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                           
                                        
                                        <div className="form-group">
                                        <InputMask className="form-control"  value={this.state.phone_number} 
                                        mask="+1 (999) 999-9999" maskChar={null} placeholder="Phone Number"
                                        
                                        onChange={this.phone_number}/>
                                         {this.state.phone_numberMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.phone_numberMessage }</p>
                                        : ''
        }
                                        </div>
                                        </div>

                                           
                                        <div className="col-md-6">
                                           
                                        
                                        <div className="form-group">
                                        <InputMask className="form-control"  value={this.state.ssn} 
                                        placeholder="Enter Social Security Number"
                                        mask="999-99-9999" maskChar={null} 
                                        onChange={this.ssn}/>
                                        {this.state.ssnMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.ssnMessage }</p>
                                        : ''
        }
                                        </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="dash-card">
                                <h2>Contact Information</h2>
                                <div className="form-wrapper">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Address 1"
                                                 onChange={this.address1}
                                                 defaultValue={this.state.address1}
                                                />
                                                {this.state.address1Message !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.address1Message }</p>
                                        : ''
        }
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Address 2"
                                                  onChange={this.address2}
                                                  defaultValue={this.state.address2}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="City"
                                                  onChange={this.city}
                                                  defaultValue={this.state.city}/>
                                                  {this.state.cityMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.cityMessage }</p>
                                        : ''
        }
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <select  className="form-control" placeholder="State" id="userState"
                                                  onChange={this.Userstate}
                                                  selected={this.state.Userstate}
                                                  defaultValue={this.state.Userstate}>
                                                      <option value="">Select State</option>
                                                      <option value="AL">AL</option>
                                                      <option value="AK">AK</option>
                                                      <option value="AR">AR</option>
                                                      <option value="CA">CA</option>
                                                      <option value="CT">CT</option>
                                                      <option value="DE">DE</option>
                                                      <option value="FL">FL</option>
                                                      <option value="GA">GA</option>
                                                      <option value="HI">HI</option>
                                                      <option value="ID">ID</option>
                                                      <option value="IL">IL</option>
                                                      <option value="IN">IN</option>
                                                      <option value="IA">IA</option>
                                                      <option value="KS">KS</option>
                                                      <option value="KY">KY</option>
                                                      <option value="ME">ME</option>
                                                      <option value="MD">MD</option>
                                                      <option value="MA">MA</option>
                                                      <option value="MI">MI</option>
                                                      <option value="MN">MN</option>
                                                      <option value="MS">MS</option>
                                                      <option value="MO">MO</option>
                                                      <option value="MT">MT</option>
                                                      <option value="NE">NE</option>
                                                      <option value="NV">NV</option>
                                                      <option value="NH">NH</option>
                                                      <option value="NJ">NJ</option>
                                                      <option value="NM">NM</option>
                                                      <option value="NY">NY</option>
                                                      <option value="NC">NC</option>
                                                      <option value="ND">ND</option>
                                                      <option value="OH">OH</option>
                                                      <option value="OK">OK</option>
                                                      <option value="OR">OR</option>
                                                      <option value="PA">PA</option>
                                                      <option value="RI">RI</option>
                                                      <option value="SC">SC</option>
                                                      <option value="SD">SD</option>
                                                      <option value="TN">TN</option>
                                                      <option value="TX">TX</option>
                                                      <option value="UT">UT</option>
                                                      <option value="VA">VA</option>
                                                      <option value="WA">WA</option>
                                                      <option value="WV">WV</option>
                                                      <option value="WI">WI</option>
                                                      <option value="WY">WY</option>

               
                                                    </select>
                                                    {this.state.stateMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.stateMessage }</p>
                                        : ''
        }
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <select className="form-control" placeholder="Country"
                                                onChange={this.country}
                                                id="userCountry"
                                                defaultValue={this.state.country}>
                                                    <option value="">Select Country</option>
                                                    <option value="US">USA</option>
                                                </select>
                                                {this.state.countryMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.countryMessage }</p>
                                        : ''
        }
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Post Code"
                                                onChange={this.postal_code}
                                                defaultValue={this.state.postal_code}
                                                maxLength="5"
                                                />
                                                {this.state.postal_codeMessage !== ''?
                                        <p style={{color:'red',marginLeft:'15px'}}>{this.state.postal_codeMessage }</p>
                                        : ''
        }
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        
                                        <div className="col-md-12">
                                            <div className="form-group text-right">
                                                <button className="btn btn-primary" onClick={this.update}>Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </section>
        );
    }
}

export default Index;
