import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderComponent from "../Components/header.js";
import FooterComponent from "../Components/footer.js";
import SideMenue from "../Components/sideMenue.js";
import services from "../utils/services";
import { Button, Modal } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import Header from "../Components/header.js";
import "react-toastify/dist/ReactToastify.css";
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beneferiesData: [],
      loader: false,
      editModal: false,
      index:''
    };
  }
  async componentDidMount() {
    this.setState({ loader: true });
    const response = await services.getBeneferies();
    const responseJson = await response.json();
    console.log(responseJson.status);
    console.log(response.status);
    if (response.status == "200") {
      this.setState({ beneferiesData: responseJson.data.rows });
      this.setState({ loader: false });
    }
    if (response.status == "401") {
      this.setState({ loader: false });
      alert("authorization Problem");
    }
  }
  getBeneferies = async (e)=>{
    this.setState({ loader: true });
    const response = await services.getBeneferies();
    const responseJson = await response.json();
    console.log(responseJson.status);
    console.log(response.status);
    if (response.status == "200") {
      this.setState({ beneferiesData: responseJson.data.rows });
      this.setState({ loader: false });
    }
    if (response.status == "401") {
      this.setState({ loader: false });
      alert("authorization Problem");
    }
  }
  getDetail = (data, index,e) => {
    console.log(index, "index");
    this.setState({index:index})
    this.setState({ id: data.id });
    this.setState({ name: data.name });
    this.setState({ email: data.email });
    this.setState({ relation: data.relation });
    this.setState({ percentage: data.percentage });
    this.setState({ dob: data.dob });
    this.setState({ phone: data.phone });
    this.setState({ editModal: !this.state.editModal });
  };
  handleName = (e) => {
    this.setState({ name: e.target.value });
  };
  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  handleRelation = (e) => {
    this.setState({ relation: e.target.value });
  };
  handlePercentage = (e) => {
    this.setState({ percentage: e.target.value });
  };
  handleDOB = (e) => {
    this.setState({ dob: e.target.value });
  };
  handlephone = (e) => {
//    let s =  "+1"+e.target.value.replace(/(\d{0})(\d{3})(\d{3})(\d{4})/,"+1$2-$3-$4");
//    console.log(s,'s')
//    document.getElementById("phone").value = ""
//    document.getElementById("phone").value = s
    this.setState({ phone: e.target.value});
  };
  editModalClose = (e)=>{
      this.setState({editModal:false})
  }
  addBeneferiesModal = (e)=>{
    this.setState({index:''})
    this.setState({ name:''});
    this.setState({ email:''});
    this.setState({ relation:'' });
    this.setState({ percentage: '' });
    this.setState({ dob: '' });
    this.setState({ phone: '' });
    this.setState({ editModal: !this.state.editModal });
  }
  saveBeneferies = (e)=>{
      if(this.state.name == '' || this.state.email == '' || this.state.relation == '' ||
       this.state.percentage == '' || this.state.dob == '' || this.state.phone == '' 
      ){
        toast["error"]("Fill all the required fields to continue");
      }else{
          let data = new Array();
          let d = {
            name:this.state.name,
            email:this.state.email,
            relation:this.state.relation,
            percentage:this.state.percentage/100,
            dob:this.state.dob,
            phone:this.state.phone,
          }
          console.log(this.state.index,'this.state.index')
           data = this.state.beneferiesData;
           if(this.state.index == '' && this.state.index != '0'){
                data.push(d);
           }else{
               data[this.state.index] = d;
           }
           this.setState({beneferiesData:data})
           this.setState({ editModal: !this.state.editModal });
           this.setState({ editAble: true });
           console.log(data,'data')
      }
  }
  saveBeneferiesToServer = async (e)=>{
      if(this.state.beneferiesData.length>0){
          let sum = 0;
          let dd = new Array();
          for(let i=0; i<this.state.beneferiesData.length; i++){
              sum +=this.state.beneferiesData[i].percentage*100;
              dd[i] = {
                name: this.state.beneferiesData[i].name,
                relation:this.state.beneferiesData[i].relation,
                percentage:this.state.beneferiesData[i].percentage,
                email: this.state.beneferiesData[i].email,
                dob: this.state.beneferiesData[i].dob,
                phone: this.state.beneferiesData[i].phone,
              };
          }
          let dataToSenddd = {
            beneficiaries: dd,
          };
          if(sum != 100){
            toast["error"]("Sum of Percentage must be 100%");
          }else{
            this.setState({ loader: true });
            const response = await services.saveBeneferyUpdate(dataToSenddd);
            const responseJson = await response.json();
            
            if (response.status == "200") {
                toast["success"](responseJson.message);
                this.getBeneferies();
              this.setState({ loader: false });
              this.setState({ editAble: false });
            }else
            if (response.status == "401") {
              this.setState({ loader: false });
              alert("authorization Problem");
            }else{
                toast["error"](responseJson.message);
            }
          
          }
      }
  }
  render() {
    return (
      <section className="dashboard-section">
        <div className="container-fluid">
          <div className="row">
           
            <ToastContainer />
            <div className="col-md-12">
              <Header/>
              <div style={{    marginBottom: '-15px'}} className="heading-info-top">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="dash-main-heading">Beneficiary</h1>
                   <Button 
                   onClick={this.addBeneferiesModal}
                   style={{  float:'right',marginTop:'-35px', padding: '6px', borderRadius: '10px',border: '1px solid'}} className="add-goals shadow-lg mb-5 bg-white rounded big_screen_button">
                                                <i className="fa fa-plus"></i>  Add Beneficiary
                                            </Button>
                  </div>
                  <div className="col-md text-md-right">
                    <ul className="dash-top-list clearfix"></ul>
                  </div>
                </div>
              </div>
              <div className="dash-card">
                <center>
                  {this.state.loader ? (
                    <div>
                      <i
                        style={{ fontSize: "20px" }}
                        className="fas fa-spinner fa-spin"
                      ></i>
                    </div>
                  ) : (
                    ""
                  )}
                </center>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Account No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Relation</th>
                        <th>Percentage</th>
                        <th>Date Of Birth</th>
                        <th>Phone</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.beneferiesData.map((item, index) => (
                        <tr>
                          <td>{item.accountNo}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.relation}</td>
                          <td>{parseInt(item.percentage*100)+"%"}</td>
                          <td>{item.dob}</td>
                          <td>{item.phone}</td>

                          <td>
                            <i
                              className="fa fa-edit"
                              style={{ cursor: "pointer", color: "#3a5fa7" }}
                              onClick={this.getDetail.bind(this, item,index)}
                            ></i>
                            {/* <Link to={"bank-detail/" + item.dwBankID}>
                              <i className="fa fa-eye"></i> Detail
                            </Link> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <center>
                      {this.state.editAble?
                    <button className="btn btn-info" onClick={this.saveBeneferiesToServer}>Save</button>  
                    :''
                    }
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.editModal}
          toggle={() => this.editModalClose}
        >
          <div className="modal-header">
            <h4 className="modal-title" id="modal-title-default">
              Edit Beneficiary
            </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.editModalClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <input
                style={{ marginTop: "10px", marginBottom: "10px" }}
                type="text"
                id="text"
                placeholder="Name"
                className="form-control"
                defaultValue={this.state.name}
                onChange={this.handleName}
              ></input>

              <input
                style={{ marginTop: "10px", marginBottom: "10px" }}
                type="text"
                id="email"
                placeholder="Email"
                className="form-control"
                defaultValue={this.state.email}
                onChange={this.handleEmail}
              ></input>

              <select
                className="form-control"
                id="relation"
                onChange={this.handleRelation}
                defaultValue={this.state.relation}
              >
                <option value="">Select Relation</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Wife">Wife</option>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Friend">Friend</option>
              </select>

              <input
                style={{ marginTop: "10px", marginBottom: "10px" }}
                type="number"
                id="percentage"
                placeholder="Percentage"
                className="form-control"
                defaultValue={this.state.percentage*100}
                onChange={this.handlePercentage}
              ></input>

              <input
                style={{ marginTop: "10px", marginBottom: "10px" }}
                type="date"
                id="dob"
                placeholder="Date of Birth"
                className="form-control"
                defaultValue={this.state.dob}
                onChange={this.handleDOB}
              ></input>

              <input
                style={{ marginTop: "10px", marginBottom: "10px" }}
                type="text"
                id="phone"
                placeholder="Phone"
                className="form-control"
                defaultValue={this.state.phone}
                onChange={this.handlephone}
              ></input>
            </div>
          </div>
          <div className="modal-footer">
            <Button color="primary" type="button" onClick={this.saveBeneferies}>
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={this.editModalClose}
            >
              Close
            </Button>
          </div>
        </Modal>
        <FooterComponent/>
      </section>
    );
  }
}

export default Index;
