import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import services from "../../utils/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactCodeInput from 'react-verification-code-input';
const axios = require("axios");

const expressions = {
  email: /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  password: /[A-Z]/,
  passwordDigit: /[1-9]/,
  passwordSpecial: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
  // /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
  // passwordCaps: /^[A-Z!@#$%^&*]$/,
};
class login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailMessage: "",
      passwordMessage: "",
      showPass: false,
      loginForm:true,
      timer: 120,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // this.getDetail();
    }
  }
  async componentDidMount() {
    
    localStorage.clear();
    if (this.props.match.params.id == "success") {
      toast["success"](
        "Your account has been verified successfully, Please Proceed to login"
      );
    } else if (this.props.match.params.id == "forgotPasswordSuccess") {
      toast["success"]("Forgot reset Email send to your email address.");
    } else if (this.props.match.params.id == "resetPasswordSuccess") {
      toast["success"]("Forgot reset Email send to your email address.");
    }
    if (this.props.match.params.message == "success") {
      const data = {
        email: this.props.match.params.email,
        hash: this.props.match.params.hash,
      };
      const response = await services.userVerify(data);
      const responseJson = await response.json();
      if (response.status == "200") {
        toast["success"](
          "Your account has been verified successfully, Please Proceed to login"
        );
      } else if (responseJson.message == "Email is already Verified") {
        toast["warning"](
          "Your account is already verified, Please Proceed to login"
        );
      } else {
        toast["error"]("Some thing went wrong try again later.");
      }
    }
  }
  email = (e) => {
    this.setState({ email: e.target.value });
  };
  password = (e) => {
    this.setState({ password: e.target.value });
  };
  validateForm = (e) => {
    let email = this.state.email.trim();
    let password = this.state.password;
    let isValid = false;
    // await functions.setItemObject("RememberMeUserName", email);

    if (email == "") {
      this.setState({
        emailMessage: "Email is required",
      });
    } else if (!expressions.email.test(email) || email.includes(" ")) {
      this.setState({
        emailMessage: "Valid email is required",
      });
    } else if (password == "") {
      this.setState({
        passwordMessage: "Password is required",
      });
    } else {
      isValid = true;
    }
    return isValid;
  };
  login = async () => {
    if (this.validateForm()) {
      const data = {
        email: this.state.email,
        password: this.state.password,
      };
      const response = await services.userLogin(data);
      const responseJson = await response.json();
      if (responseJson.message == "Successfully Login") {
        localStorage.setItem("loginToken", responseJson.token.toString());
       if(responseJson.otp === false){
      
        localStorage.setItem("otpStatus", 'true');
        const responseProfile = await services.getProfile();
        const responseProfileJson = await responseProfile.json();
        console.log(responseProfileJson.data.email);
        if (responseProfileJson.message == "User found.") {
          console.log(responseProfileJson.data.first_name);
          localStorage.setItem(
            "firstName",
            responseProfileJson.data.first_name
          );
          localStorage.setItem("lastName", responseProfileJson.data.last_name);
          localStorage.setItem("email", responseProfileJson.data.email);
          localStorage.setItem(
            "riskProfile",
            responseProfileJson.data.riskProfile
          );
        }
        this.props.history.push("/");
        window.location.reload();
      }else{
        localStorage.setItem("otpStatus", 'false');
        this.setState({loginForm:false})
        this.startCounter();
      }

        // window.location.href="/dashboard/";
        // return;
        
      } else if (
        responseJson.message ==
        "Your Email is not verified. please check email and verify your email first"
      ) {
        this.setState({ loginMessage: responseJson.message });
      } else {
        this.setState({
          loginMessage: "Credentials Error. User name or Password Incorrect",
        });
        // alert('in valid username')
      }
    }
    //   window.location.reload();
  };
  showPass = (e) => {
    var x = document.getElementById("pass");
    var image = document.getElementById("eye_image");
    if (x.type === "password") {
      x.type = "text";
      image.src = "images/eye-icon-error.svg";
    } else {
      x.type = "password";
      image.src = "images/eye-icon.svg";
    }
  };
  OTPCode = (e)=>{
    console.log(e);
    this.setState({verifyCode:e});
  }
 
  startCounter = ()=>{
   
    this.interval = setInterval(
      () => this.setState((prevState) => ({ timer: prevState.timer - 1 })),
      1000
    );
  }
  verify = async (e)=>{
    if(this.state.verifyCode.length === 4){
      let data = { code: this.state.verifyCode };
      const responseProfile = await services.verifyCode(data);
      const responseProfileJson = await responseProfile.json();
      if(responseProfile.status === 200){
        localStorage.setItem("otpStatus", 'true');
        // toast["success"](responseProfileJson.message);
        const responseProfile2 = await services.getProfile();
        const responseProfileJson2 = await responseProfile2.json();
       
        if (responseProfileJson2.message == "User found.") {
          console.log(responseProfileJson2.data.first_name);
          localStorage.setItem(
            "firstName",
            responseProfileJson2.data.first_name
          );
          localStorage.setItem("lastName", responseProfileJson2.data.last_name);
          localStorage.setItem("email", responseProfileJson2.data.email);
          localStorage.setItem(
            "riskProfile",
            responseProfileJson2.data.riskProfile
          );
        }
        this.props.history.push("/");
        window.location.reload();
      }else if(responseProfile.status === 400){
        toast["error"]("You have entered an invalid code, please re-enter the code which was sent to your phone");
      }else{
        toast["error"](responseProfileJson.message);
      }
    }
  }
  render() {
    return (
      <body className={"login-page"}>
        <ToastContainer />
        <section className="login-section section-height-full ">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-6"></div>
              <div className="col-sm-6">
              <div
                className="login_wala_form"
                style={{ margin: "auto", width: "75%", float:'right'}}
              >
                <center>
                  <div
                    className="login-inner-wrapper"
                    style={{ padding: "30px", width: "80%", }}
                  >
                    <div className="logo-wrapper text-center">
                      <div className="logo-img">
                        <img
                          src="/images/icons/aghaz-invest-logo.svg"
                          style={{ height: "125px" }}
                          alt="logo"
                        />
                      </div>
                      {/* <h1 style={{    fontsize: '24px',margin: '0 0 20px', fontWeight: 'bold'}}>Login</h1> */}
                    </div>
                    {this.state.loginForm?
                    <div className="form-wrapper">
                      <div className="form-group">
                        {/* <label>Email</label> */}
                        <input
                          type="text"
                          style={{ border: "0px", height: "50px" }}
                          className="form-control"
                          placeholder="Username"
                          onChange={this.email}
                        />
                        {this.state.emailMessage !== "" ? (
                          <p style={{ color: "red", marginLeft: "15px" }}>
                            {this.state.emailMessage}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group field-has-icon">
                        {/* <label>Password</label> */}
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          id="pass"
                          onChange={this.password}
                        />
                        <a className="field-icon" onClick={this.showPass}>
                          <img
                            src="/images/eye-icon.svg"
                            id="eye_image"
                            alt=""
                          />
                        </a>
                        {this.state.passwordMessage !== "" ? (
                          <p style={{ color: "red", marginLeft: "15px" }}>
                            {this.state.passwordMessage}
                          </p>
                        ) : (
                          ""
                        )}
                        <p style={{ color: "red", marginLeft: "15px" }}>
                          {this.state.loginMessage}
                        </p>
                        <Link
                          to="/forgot-password"
                          style={{ fontSize: "15px" }}
                          className="forget-link"
                        >
                          Forgot password?
                        </Link>
                      </div>
                      <div className="form-btn login-form-btn">
                        <button
                          type="submit"
                          className="btn btn-primary btn-login btn-block"
                          onClick={this.login}
                        >
                          {" "}
                          Login
                        </button>
                        {/* <div
                          className="signup-info text-center"
                          style={{ fontSize:12 }}
                        >
                          Don't have an account?{" "}
                          <Link to="/sign-up">SIGN UP NOW</Link>
                        </div> */}
                      </div>
                    </div>
                    :
                    <div className="form-wrapper">
                      <ReactCodeInput 
                      placeholder="****"
                      fields={4}
                      onChange={this.OTPCode}
                      />
                       <div
                          className="signup-info text-center"
                          style={{ fontSize:10,color:'#3a5fac' }}
                        >
                          Verify code expire in {" "}
                         {this.state.timer} {" "}seconds
                        </div>
                      <div className="form-btn login-form-btn">
                        <button
                          type="submit"
                          className="btn btn-primary btn-login btn-block"
                          onClick={this.verify}
                        >
                          {" "}
                          Verify
                        </button>
                      </div>
                    </div>
                    }
                  </div>
                </center>
              </div>
              </div>
            </div>
          </div>
        </section>
        <div id="thScannerModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-scanner-icon text-center">
                  <img src="/images/scanner-icon.svg" alt="" />
                </div>
                <p>Login via Fingerprint</p>
              </div>
              <div className="modal-footer text-right">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </body>
    );
  }
}

export default login;
