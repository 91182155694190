import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import FooterComponent from "../Components/footer.js";
import SideMenue from "../Components/sideMenue.js";
import services from "../utils/services";
import Header from "../Components/header.js";
import { PlaidLink } from "react-plaid-link";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banks: [],
      plaidPublicToken: "",
      plaidAccessToken: "",
      loader:false,
    };
  }
  async componentDidMount() {
    this.setState({loader:true})
    const response = await services.getBanks();
    const responseJson = await response.json();
    console.log(responseJson.status);
    console.log(response.status);
    if (response.status == "200") {
      this.setState({ banks: responseJson.data.rows });
      this.setState({loader:false})
    }
    if (response.status == "401") {
      this.setState({loader:false})
      alert("authorization Problem");
    }
    this.getPlaidSettings();
  }
  getPlaidSettings = async (e) => {
    const response = await services.getPlaidSettings();
    const responseJson = await response.json();

    if (response.status == "200") {
      // console.log(responseJson.data.plaidPublicToken,'responseJson.data.plaidPublicToken')
      this.setState({ plaidPublicToken: responseJson.data.plaidPublicToken });
      this.setState({ plaidAccessToken: responseJson.data.plaidAccessToken });
    }
    if (response.status == "401") {
      alert("authorization Problem");
    }
  };
  handleSuccess = async (data) => {
    this.setState({loader:true})
    console.log(data,'data');
    const dataToSend = {
      client_id: this.state.plaidAccessToken,
      secret: this.state.plaidPublicToken,
      public_token: data,
    };
    const response = await services.getAccessToken(dataToSend);

    const responseJson = await response.json();
    console.log(responseJson, "response access token");
    if (response.status == "200") {
      this.setState({ access_token: responseJson.access_token });
      const dataToSendBalance = {
        client_id: this.state.plaidAccessToken,
        secret: this.state.plaidPublicToken,
        access_token: responseJson.access_token,
      };
      const responseBalance = await services.getPlaidBalance(dataToSendBalance);
      const responseJsonBalance = await responseBalance.json();
      console.log(responseJsonBalance, "response balance");
      if (responseBalance.status == "200") {
        if (responseJsonBalance.accounts[0].balances.available == 0) {
          alert("You have insufficient balance in your bank account");
          return;
        }
        this.setState({
          account_id: responseJsonBalance.accounts[0].account_id,
        });
        const dataToSendprocessorToken = {
          client_id: this.state.plaidAccessToken,
          secret: this.state.plaidPublicToken,
          access_token: responseJson.access_token,
          account_id: responseJsonBalance.accounts[0].account_id,
          processor: "drivewealth",
        };
        const responseProcessorToken = await services.getProcessorToken(
          dataToSendprocessorToken
        );
        const responseJsonProcessorToken = await responseProcessorToken.json();
        console.log(responseJsonProcessorToken, "response processor");
        if (responseProcessorToken.status == "200") {
          this.setState({
            processorToken: responseJsonProcessorToken.processor_token,
          });
          const dataToSendRoutingNumber = {
            client_id: this.state.plaidAccessToken,
            secret: this.state.plaidPublicToken,
            access_token: responseJson.access_token,
          };
          const responseRoutingNo = await services.getRoutingNumber(
            dataToSendRoutingNumber
          );
          const responseJsonRoutingNo = await responseRoutingNo.json();
          console.log(responseJsonRoutingNo, "response routing number");
          if (responseRoutingNo.status == "200") {
            this.setState({
              routingNumber: responseJsonRoutingNo.numbers.ach[0].routing,
            });
            this.setState({
              routingAccountID: responseJsonRoutingNo.numbers.ach[0].account,
            });

            const dataToSendInstitute = {
              client_id: this.state.plaidAccessToken,
              secret: this.state.plaidPublicToken,
              institution_id: responseJsonRoutingNo.item.institution_id,
            };
            const responseInstitute = await services.getInstitution(
              dataToSendInstitute
            );
            const responseJsonInstitute = await responseInstitute.json();
            console.log(responseJsonInstitute, "responseJsonInstitute");
            if (responseInstitute.status == "200") {
              this.setState({
                bankName: responseJsonInstitute.institution.name,
              });
              const dataToSendInstituteLogo = {
                query: responseJsonInstitute.institution.name,
                products: ["auth"],
                public_key: this.state.plaidPublicToken,
                options: {
                  include_optional_metadata: true,
                },
              };
              const responseInstituteLogo = await services.getInstitutionLogo(
                dataToSendInstituteLogo
              );
              const responseJsonInstituteLogo = await responseInstituteLogo.json();
              console.log(
                responseJsonInstituteLogo,
                "responseJsonInstituteLogo"
              );
              if (responseInstituteLogo.status == "200") {
                this.setState({
                  institute: responseJsonInstituteLogo.institutions[0].logo,
                });
                const dataAddBank = {
                  bankRoutingNumber:
                    responseJsonRoutingNo.numbers.ach[0].routing,
                  bankAccountNumber:
                    responseJsonRoutingNo.numbers.ach[0].account,
                  processor_token: responseJsonProcessorToken.processor_token,
                  nickName: responseJsonInstitute.institution.name,
                  logo: responseJsonInstituteLogo.institutions[0].logo,
                  access_token:responseJson.access_token
                };
                const responseaddBank = await services.addBank(dataAddBank);
                const responseJsonAddBank = await responseaddBank.json();
                if (responseaddBank.status == "200") {
                  alert(responseJsonAddBank.message);
                  const responsessss = await services.getBanks();
                  const responseJsonsss = await responsessss.json();

                  if (responsessss.status == "200") {
                    this.setState({ banks: responseJsonsss.data.rows });
                  }
                  if (responsessss.status == "401") {
                    alert("authorization Problem");
                  }
                }
              }
            }
          }
        }
      }
    }
  };
  exitFunction = (data) => {
    console.log(data);
  };
  errorFunction = (data) => {
    console.log(data);
  };
  render() {
    return (
      <section className="dashboard-section">
        <div className="container-fluid">
          <div className="row">
            {/* <SideMenue /> */}
           
            <div className="col-md-12">
            <Header />
              <div className="heading-info-top">
                <div className="row">
                  <div className="col-md">
                    <h1 className="dash-main-heading">Accounts</h1>
                  </div>
                  <div className="col-md text-md-right">
                    <ul className="dash-top-list clearfix">
                      <li>
                        {this.state.plaidPublicToken !== "" ? (
                          <PlaidLink style={{border:'0px'}}
                            publicKey={this.state.plaidPublicToken}
                            // publicKey={"78706dde8da865a1c7697b76e0b542"}
                            clientName="Aghaz"
                            env="sandbox" // 'sandbox' or 'development' or 'production'
                            product={["transactions"]}
                            onSuccess={(datass) => {
                              console.log(datass, "datass");
                              this.handleSuccess(datass);
                            }}
                            onExit={(dataee) => {
                              this.exitFunction(dataee);
                            }}
                            onCancelled={(result) => {
                              this.errorFunction(result);
                            }}
                          >
                            <a
                              href="javascript:void(0);"
                              className="del-anchor"
                              style={{backgroundColor:'#3a5fac'}}
                            >
                              <i style={{color:'white'}} className="fa fa-plus"></i>
                            </a>
                          </PlaidLink>
                        ) : (
                          ""
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="notification-box">
                  <div className="notf-heading">
                    <h4>Notifications</h4>
                  </div>
                  <div className="notification-list">
                    <div className="notf-row clearfix">
                      <div className="notf-img">
                        <img src="/images/avatar-1.png" alt="" />
                      </div>
                      <div className="notf-detail">
                        <h5>
                          <b>David Lee</b> sent you a message.
                        </h5>
                        <p className="notf-time">4 min ago</p>
                      </div>
                    </div>
                    <div className="notf-viewall">
                      <a href="#">View all notifications</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dash-card">
                <center>
                  {this.state.loader?
                    <div><i style={{fontSize:'20px'}} className="fas fa-spinner fa-spin"></i></div>:''}
                </center>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                       
                        <th>Bank</th>
                        <th>Account No</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.banks.map((item, index) => (
                        <tr>
                         
                          <td >
                          {" "}
                            <img
                              style={{ height: "35px" }}
                              src={"data:image/png;base64," + item.logo}
                              alt="Red dot"
                            />{"   "}{item.bankAccountNickname}
                          </td>
                          <td >
                            {item.bankAccountNumber}
                          </td>
                          <td >
                            <span
                              className={
                                item.status == "ACTIVE"
                                  ? "badge badge-success"
                                  : "badge badge-warning"
                              }
                            >
                              {item.status}
                            </span>
                          </td>
                          <td >
                            <Link to={"bank-detail/" + item.dwBankID}>
                              <i className="fa fa-eye"></i> Detail
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent/>
      </section>
    );
  }
}

export default Index;
