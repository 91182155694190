import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import Slider from "react-slick";

class investmentDisclaimerComponent extends React.Component {
    render() {
        return (
            <div className="invest-disclaimer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <p style={{textAlign:'justify', fontStyle: 'italic'}}>
                            This website and information are provided for guidance and information purposes only. Investments involve risk and unless otherwise stated, are not guaranteed. The results do not represent actual trading and actual results may significantly differ from the theoretical results presented. Past performance is not indicative of future performance. Future returns are not guaranteed. This website and information are not intended to provide investment, tax, or legal advice.
                            See <a href="/#/disclaimers">this link</a> for more details.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default investmentDisclaimerComponent;
