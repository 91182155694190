import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "reactstrap";
import SideMenue from "../../Components/sideMenue.js";
import services from "../../utils/services";
import Footer from "../../Components/footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../Components/header.js";
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      duration: "",
      amount: "",
      banks: [],
      bankLoader: true,
      notes: "",
      selectedBanks: "",
      initialDeposit: 0,
      recurringDeposit: "",
      goalId: "",
      SelectBankMessage: "",
      goalLoader: false,
      yearList: [],
      monthList: [],
      selectedYear: "",
      selectedMonth: "",
      durationMessageMonth: "",
      durationMessageYear: "",
      accountID: "",
      accountNo: "",
      accessToken: "",
      plaidPublicToken: "",
      plaidAccessToken: "",
      Initial_deposit: 0,
      annuity: 0,
      chooseGoalModal: true,
    };
  }

  async componentDidMount() {
    document.getElementById("recurring_amount").style.display = "none";
    let goalCount = localStorage.getItem("goalCount");
    if (goalCount !== undefined && goalCount !== null) {
      if (goalCount == "") {
        toast["error"](
          "Unable to process your request please try again later."
        );
        setTimeout(function () {
          window.location.href = "/";
        }, 3000);
      } else if (goalCount >= 10) {
        toast["error"]("Your goal limit has been reached.");
        setTimeout(function () {
          window.location.href = "/";
        }, 5000);
      }
    }
    const response = await services.getBanks();
    const responseJson = await response.json();
    console.log(responseJson.status);
    console.log(response.status);
    if (response.status == "200") {
      this.setState({ banks: responseJson.data.rows });
      this.setState({ bankLoader: false });
    }
    if (response.status == "401") {
      alert("authorization Problem");
    }
    let year = new Array();
    let ye = new Date().getFullYear();

    for (let a = 0; a <= 40; a++) {
      year[a] = parseInt(ye + a);
    }
    this.setState({ yearList: year });

    let months = [
      { id: "01", name: "January" },
      { id: "02", name: "February" },
      { id: "03", name: "March" },
      { id: "04", name: "April" },
      { id: "05", name: "May" },
      { id: "06", name: "June" },
      { id: "07", name: "July" },
      { id: "08", name: "August" },
      { id: "09", name: "September" },
      { id: "10", name: "October" },
      { id: "11", name: "November" },
      { id: "12", name: "December" },
    ];
    this.setState({ monthList: months });
    this.getPlaidSettings();
    // document.write("The current month is " + d.getMonth());
  }
  getPlaidSettings = async (e) => {
    const response = await services.getPlaidSettings();
    const responseJson = await response.json();

    if (response.status == "200") {
      // console.log(responseJson.data.plaidPublicToken,'responseJson.data.plaidPublicToken')
      this.setState({ plaidPublicToken: responseJson.data.plaidPublicToken });
      this.setState({ plaidAccessToken: responseJson.data.plaidAccessToken });
    }
    if (response.status == "401") {
      alert("authorization Problem");
    }
  };
  name = (e) => {
    this.setState({
      name: e.target.value,
    });
    if (e.target.value != "") {
      this.setState({ nameMessage: "" });
    }
  };
  duration = (e) => {
    console.log(e.target.value);
    this.setState({
      duration: e.target.value + "-01",
    });
    if (e.target.value != "") {
      this.setState({ durationMessage: "" });
    }
  };
  amount = (e) => {
    this.setState({
      amount: e.target.value,
    });
    if (e.target.value != "") {
      this.setState({ amountMessage: "" });
    }
  };
  selectBanks = (bank_id, access_token, e) => {
    console.log(bank_id, "bank_id");
    console.log(access_token, "access_token");
    this.setState({ selectedBanks: bank_id });
    this.setState({ accessToken: access_token });
    if (bank_id != "") {
      this.setState({ bankMessage: "" });
    }
  };
  initialDeposit = (e) => {
    this.setState({ initialDeposit: e.target.value });
    if (e.target.value != "") {
      this.setState({ initialDepositMessage: "" });
    }
  };
  recurringDeposit = (e) => {
    this.setState({ recurringDeposit: e.target.value });
    if (e.target.value != "") {
      this.setState({ recurringDepositMessage: "" });
    }
  };
  notes = (e) => {
    this.setState({ notes: e.target.value });
    if (e.target.value != "") {
      this.setState({ NotesMessage: "" });
    }
  };
  year = (e) => {
    this.setState({ selectedYear: e.target.value });
    if (e.target.value != "") {
      this.setState({ durationMessageYear: "" });
    }
  };
  month = (e) => {
    this.setState({ selectedMonth: e.target.value });
    if (e.target.value != "") {
      this.setState({ durationMessageMonth: "" });
    }
  };
  validateForm = (e) => {
    let name = this.state.name;
    let selectedYear = this.state.selectedYear;
    let selectedMonth = this.state.selectedMonth;
    let amount = this.state.amount;
    let recurring = this.state.recurringDeposit;
    let intial = this.state.initialDeposit;
    let isValid = false;
    let isRecurring = document.getElementById("recurringCheck").checked;
    console.log(isRecurring, "isRecurring");
    // await functions.setItemObject("RememberMeUserName", email);

    if (name == "") {
      this.setState({
        nameMessage: "Name is required",
      });
    } else if (selectedYear == "") {
      this.setState({
        durationMessageYear: "Year is required",
      });
    } else if (selectedMonth == "") {
      this.setState({
        durationMessageMonth: "Month is required",
      });
    } else if (amount == "") {
      this.setState({
        amountMessage: "Amount is required",
      });
    } else if (intial == "") {
      this.setState({ initialDepositMessage: "Initial Deposit is required" });
    } else if (isRecurring) {
      if (recurring == "") {
        this.setState({
          recurringDepositMessage: "Recurring Deposit is required",
        });
      } else {
        isValid = true;
      }
    } else {
      isValid = true;
    }
    return isValid;
  };
  addGoal = async () => {
    if (this.validateForm()) {
      this.setState({ goalLoader: true });
      //return;
      const client_id = localStorage.getItem("clientId");
      const data = {
        accumulation_horizon:
          this.state.selectedYear + "-" + this.state.selectedMonth + "-" + "01",
        category: "Large Purchase",
        decumulation_horizon: 0,
        goal_amount: this.state.amount,
        is_decumulation: false,
        name: this.state.name,
        parent_goal_id: "456eae0d-4619-4f99-bd0f-263ccb69644a",
        questionnaire_id: "fa2a3fce-cbdf-4962-9a57-d1737a3e1475",
        type: "Client Goal",
        notes: this.state.notes,
      };
      const response = await services.goalCreate(data);
      const responseJson = await response.json();
      console.log(responseJson, "responseJson");
      this.setState({ goalId: responseJson.data.goal_id });
      if (responseJson.message == "Goal is added Successfully.") {
        this.setState({ accountID: responseJson.data.accountID });
        this.setState({ accountNo: responseJson.data.accountNo });

        this.setState({ goalLoader: false });
        this.setState({ SignUpModel: true });
        //    alert(responseJson.message)
        //    window.location.href="/";
      } else {
        alert(responseJson.message);
      }
    } else {
      console.log("not validate");
    }
  };
  saveGoalAddDetail = async () => {
    if (this.state.selectedBanks == "") {
      this.setState({ SelectBankMessage: "Select Bank To Continue" });
      return;
    }
    if (
      this.state.accessToken !== undefined &&
      this.state.accessToken !== null
    ) {
      const dataToSendBalance = {
        client_id: this.state.plaidAccessToken,
        secret: this.state.plaidPublicToken,
        access_token: this.state.accessToken,
      };
      const responseBalance = await services.getPlaidBalance(dataToSendBalance);
      const responseJsonBalance = await responseBalance.json();
      console.log(responseJsonBalance, "response balance");
      if (responseBalance.status == "200") {
        if (
          responseJsonBalance.accounts[0].balances.available <=
          this.state.initialDeposit
        ) {
          alert("You have insufficient balance in your bank account");
          return;
        }
      }
    } else {
      alert("Create new bank to continue");
      return;
    }
    const client_id = localStorage.getItem("clientId");
    const data = {
      goal_id: this.state.goalId,
      bank_id: this.state.selectedBanks,
      amount: this.state.initialDeposit,
      accountID: this.state.accountID,
      accountNo: this.state.accountNo,
      note: " ",
    };
    if (document.getElementById("recurringCheck").checked) {
      const recurringData = {
        goal_id: this.state.goalId,
        bank_id: this.state.selectedBanks,
        amount: this.state.recurringDeposit,
        accountID: this.state.accountID,
        accountNo: this.state.accountNo,
        note: " ",
        recurringFrequency: "MONTHLY_FIRST",
      };
      const responseRecurring = await services.goalCreateRecurring(
        recurringData
      );
      const responseJsonRecurring = await responseRecurring.json();
      console.log(responseJsonRecurring, "responseJsonRecurring");
    }

    const response = await services.goalCreateTransaction(data);
    const responseJson = await response.json();
    console.log(responseJson, "responseJson");
    //   this.setState({goalId:responseJson.data.id})
    if (responseJson.message == "Deposit is debited successfully.") {
      this.setState({ SignUpModel: !this.state.SignUpModel });

      alert(responseJson.message);
      window.location.href = "/";
    } else {
      alert(responseJson.message);
    }
  };
  addGoalClose = (e) => {
    this.setState({ SignUpModel: false });
  };

  InvokeFunction = () => {
    debugger;
    const {
      goal,
      amount,
      initialDeposit,
      recurringDeposit,
      selectedYear,
      selectedMonth,
    } = this.state;

    var differenceInTime = dateDifference(selectedYear, selectedMonth);

    var goalDue = differenceInTime / (1000 * 3600 * 24);

    let inf = 2.1 / 100;
    let retun = (15 - inf) / 100;
    let std_dev = 12;
    let conf = 0.95;

    if (
      amount !== "" &&
      initialDeposit !== "" &&
      selectedYear !== "" &&
      recurringDeposit !== ""
    ) {
      let initialAmount = 0;
      const targetAmount = amount;
      // const initialAmount = this.amountValue1.getRawValue();
      if (initialDeposit.toString().search("$") == -1) {
        initialAmount = parseInt(initialDeposit)
          .toString()
          .replace(/[^a-zA-Z0-9 ]/g, "");
      } else {
        initialAmount = initialDeposit;
      }

      // const initialAmount = initialDeposit;
      const recurring = recurringDeposit;
      console.log(targetAmount, "targetAmount");
      console.log(initialAmount, "initialAmount");
      console.log(recurring, "recurrings");

      let recurringSuggestion = this.ComputeMonthlyRecurringWithPercentage(
        targetAmount,
        goalDue,
        retun,
        std_dev,
        initialAmount,
        conf
      );
      let traction = this.monteCarloTraction(
        targetAmount,
        goalDue,
        recurring,
        initialAmount,
        retun,
        std_dev
      );
      this.setState({ successPercent: traction });
      let tractionSuggestion = this.monteCarloTraction(
        targetAmount,
        goalDue,
        recurringSuggestion,
        initialAmount,
        retun,
        std_dev
      );
      this.setState({ successPercentSuggestion: tractionSuggestion });
      if (traction >= 85) {
        this.setState({ goalChance: "HIGH", chanceColor: "green" });
      } else if (traction >= 70) {
        this.setState({ goalChance: "MEDIUM", chanceColor: "orange" });
      } else if (traction < 70) {
        this.setState({ goalChance: "LOW", chanceColor: "red" });
      }
    }
  };
  ComputeMonthlyRecurringWithPercentage = (
    GoalAmount,
    GoalDue,
    Return,
    stDev,
    Initial,
    Confidence
  ) => {
    if (Initial !== 0) {
      GoalAmount = GoalAmount - FV(Return / 12 / 100, GoalDue / 30, 0, Initial);
      console.log(GoalAmount, "GoalAmount");
    }

    let zscore = Qnorm(Confidence);
    let critValue = Return - zscore * stDev;
    let rate = critValue / 12 / 100;
    let annuity = (GoalAmount * rate) / (Math.pow(1 + rate, GoalDue / 30) - 1);
    if (this.state.annuityClicked) {
      this.setState({ annuity: annuity });
    } else {
      this.setState({
        annuity: annuity,
        annuityShow: true,
        // modalVisible: true,
      });
    }
    console.log(annuity, "annuity");
    return annuity;
  };
  monteCarloTraction = (
    GoalAmount,
    GoalDue,
    MonthlyRecurring,
    Initial,
    Return,
    StDev
  ) => {
    let i = 0;
    let successCount = 0;
    var maxMonteCarloIterations = 10000;
    while (i < maxMonteCarloIterations) {
      let randReturn = gaussian(Return, StDev);
      let randFv = FV(
        randReturn / 100 / 12,
        GoalDue / 30,
        MonthlyRecurring,
        Initial
      );

      if (randFv >= GoalAmount) {
        successCount += 1;
      }
      i += 1;
    }
    var successPercent = (successCount / maxMonteCarloIterations) * 100;
    return successPercent;
  };
  InvokeFunctionChange = (e, field, value) => {
    console.log(value, "value");
    debugger;
    let {
      goal,
      amount,
      initialDeposit,
      recurringDeposit,
      selectedYear,
      selectedMonth,
    } = this.state;
    if (field == "initial") {
      initialDeposit = value;
    }
    if (field == "amount") {
      amount = value;
    }
    if (field == "recurring") {
      recurringDeposit = value;
    }
    if (field == "year") {
      selectedYear = value;
    }
    if (field == "month") {
      selectedMonth = value;
    }

    if (
      amount !== "" &&
      initialDeposit !== "" &&
      selectedYear !== "" &&
      selectedMonth !== "" &&
      recurringDeposit !== ""
    ) {
      initialDeposit = initialDeposit.toString();
      console.log(initialDeposit, "initialDeposit");
      var differenceInTime = dateDifference(selectedYear, selectedMonth);
      var goalDue = differenceInTime / (1000 * 3600 * 24);
      console.log(goalDue, "goalDue");
      let inf = 2.1 / 100;
      let retun = (15 - inf) / 100;
      let std_dev = 12;
      let conf = 0.95;

      let es = new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
      });
      this.setState({ MonthlyRecurringPercentage: es.format(conf) });

      const targetAmount = amount;
      const initialAmount = initialDeposit;
      const recurring = recurringDeposit;
      console.log(recurring, "recurring");
      this.ComputeMonthlyRecurringWithPercentage(
        targetAmount,
        goalDue,
        retun,
        std_dev,
        initialAmount,
        conf
      );
      let traction = this.monteCarloTraction(
        targetAmount,
        goalDue,
        recurring,
        initialAmount,
        retun,
        std_dev
      );
      console.log(traction, "traction");
      this.setState({ successPercent: traction });
      let Initial_deposit =
        targetAmount / Math.pow(1 + retun / 12, goalDue / 30);
      console.log(Initial_deposit, "Initial_deposit");
      this.setState({ Initial_deposit: Initial_deposit });
    }
  };
  InvokeFunctionInitial = (e, field, value) => {
    let {
      goal,
      amount,
      initialDeposit,
      recurringDeposit,
      selectedYear,
      selectedMonth,
    } = this.state;

    let recurring = 0;
    if (recurringDeposit == "") {
      recurring = 0;
    } else {
      recurring = recurringDeposit;
    }
    console.log(value, "value");
    // let recurring = 0;
    if (amount !== "" && selectedYear !== "" && selectedMonth !== "") {
      var differenceInTime = dateDifference(selectedYear, selectedMonth);
      var goalDue = differenceInTime / (1000 * 3600 * 24);

      let inf = 2.1 / 100;
      let retun = (15 - inf) / 100;
      let std_dev = 12;
      let conf = 0.95;

      let es = new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
      });
      const targetAmount = amount.replace(/[^a-zA-Z0-9 ]/g, "");
      const initialAmount = value.replace(/[^a-zA-Z0-9 ]/g, "");
      let recurringSuggestion = this.ComputeMonthlyRecurringWithPercentage(
        targetAmount,
        goalDue,
        retun,
        std_dev,
        initialAmount,
        conf
      );
      let traction = this.monteCarloTraction(
        targetAmount,
        goalDue,
        recurring,
        initialAmount,
        retun,
        std_dev
      );
      console.log(traction, "traction");
      this.setState({ successPercent: traction });
      let tractionSuggestion = this.monteCarloTraction(
        targetAmount,
        goalDue,
        recurringSuggestion,
        initialAmount,
        retun,
        std_dev
      );
      this.setState({ successPercentSuggestion: tractionSuggestion });
      if (tractionSuggestion >= 85) {
        this.setState({ goalChance: "HIGH", chanceColor: "green" });
      } else if (tractionSuggestion >= 70) {
        this.setState({ goalChance: "MEDIUM", chanceColor: "orange" });
      } else if (tractionSuggestion < 70) {
        this.setState({ goalChance: "LOW", chanceColor: "red" });
      }
      this.setState({ MonthlyRecurringPercentage: es.format(conf) });
      // const targetAmount = tAmount.replace(/[^a-zA-Z0-9 ]/g, "");
      let Initial_deposit =
        targetAmount / Math.pow(1 + retun / 12, goalDue / 30);
      // if (initialDeposit == Initial_deposit) {
      //   this.setState({ Initial_deposit: Initial_deposit });
      // }
      console.log(Initial_deposit, "Initial_deposit");
      this.setState({ Initial_deposit: Initial_deposit });
    }
  };
  InvokeFunctionModal = (value) => {
    debugger;
    let amount = value;
    let oldAmount = parseInt(this.state.annuity);
    if (this.state.annuity != 0) {
      if (oldAmount !== amount) {
        this.setState({ modalVisible: true });
      }
    }
  };
  InvokeFunctionInitialModal = (value) => {
    let amount = value;
    let oldAmount = parseInt(this.state.Initial_deposit);
    console.log(this.state.Initial_deposit, "this.state.Initial_deposit ");
    if (this.state.Initial_deposit != 0) {
      if (oldAmount !== amount) {
        this.setState({
          Initial_depositModal: !this.state.Initial_depositModal,
        });
      }
    }
  };
  callInvoke = () => {
    debugger;
    const { annuity, Initial_deposit } = this.state;
    document.getElementById("recurring_amount").value = annuity.toFixed();
    let value = 0;
    this.setState(
      {
        radio: 0,
        annuityClicked: true,
        annuityShow: false,
        recurringDeposit: parseInt(annuity).toFixed(),
        modalVisible: false,
        confirmValue: true,
      },
      this.InvokeFunction
    );
  };
  callInvokeInitial = (e) => {
    document.getElementById("initialAmount").value = parseInt(
      this.state.Initial_deposit
    ).toFixed();
    this.setState({
      initialDeposit: parseInt(this.state.Initial_deposit).toFixed(),
    });
    this.setState({ Initial_depositModal: !this.state.Initial_depositModal });
    this.InvokeFunctionInitial(
      "",
      "initial",
      this.state.Initial_deposit.toFixed().toString()
    );
  };
  recurring_depositModalClose = (e) => {
    this.setState({ modalVisible: false });
  };
  Initial_depositModalClose = (e) => {
    this.setState({ Initial_depositModal: false });
  };
  chooseGoal = (goal, e) => {
    this.setState({ name: goal });
    document.getElementById("goalName").value = goal;
    this.setState({ chooseGoalModal: false });
  };
  isRecurring = (e) => {
    if (document.getElementById("recurringCheck").checked) {
      document.getElementById("recurring_amount").style.display = "flex";
    } else {
      document.getElementById("recurring_amount").style.display = "none";
    }
  };
  chooseGoalModalClose = (e)=>{
    this.setState({chooseGoalModal:false})
    window.location.href="/#/"
  }
  render() {
    let yearList = this.state;
    return (
      <section className="dashboard-section">
        <div className="container-fluid">
          <div className="row">
            <ToastContainer />
            
            <div className="col-md-12">
              <Header/>
              <div className="heading-info-top">
                <div className="row">
                  <div className="col-md">
                    <h1 className="dash-main-heading">Add Goals</h1>
                  </div>
                  <div className="col-md text-md-right">
                    <ul className="dash-top-list clearfix">
                      {/* <li>
                        <a href="#" className="del-anchor">
                          <img
                            src="/aghaz/images/delete-icon.svg"
                            alt="Delete Icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src="/aghaz/images/edit-icon-1.svg"
                            alt="Edit Icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#" className="notification-anchor">
                          <span className="notf-alert"></span>
                          <img
                            src="/aghaz/images/notifications.svg"
                            alt="notification icon"
                          />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="notification-box">
                  <div className="notf-heading">
                    <h4>Notifications</h4>
                  </div>
                  <div className="notification-list">
                    <div className="notf-row clearfix">
                      <div className="notf-img">
                        <img src="/aghaz/images/avatar-1.png" alt="" />
                      </div>
                      <div className="notf-detail">
                        <h5>
                          <b>David Lee</b> sent you a message.
                        </h5>
                        <p className="notf-time">4 min ago</p>
                      </div>
                    </div>
                    <div className="notf-viewall">
                      <a href="#">View all notifications</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dash-card">
                {/* <h2>Goal Information</h2> */}
                <div className="form-wrapper">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Goal Name"
                          id="goalName"
                          style={{ borderRadius: "1px" }}
                          onChange={this.name}
                        />
                        {this.state.nameMessage !== "" ? (
                          <p style={{ color: "red", marginLeft: "15px" }}>
                            {this.state.nameMessage}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        {/* <input type="month" className="form-control" placeholder="Duration" style={{borderRadius:'1px'}} onChange={this.duration}></input> */}
                        <select
                          className={"form-control"}
                          onChange={(value) => {
                            this.setState({
                              selectedYear: value.target.value,
                              durationMessageYear: value !== "" ? "" : "",
                            });
                            this.InvokeFunctionChange(
                              this,
                              "year",
                              value.target.value
                            );
                          }}
                        >
                          <option value="">Select Year</option>
                          {this.state.yearList.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>

                        {this.state.durationMessageYear !== "" ? (
                          <p style={{ color: "red", marginLeft: "15px" }}>
                            {this.state.durationMessageYear}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        {/* <input type="month" className="form-control" placeholder="Duration" style={{borderRadius:'1px'}} onChange={this.duration}></input> */}
                        <select
                          className={"form-control"}
                          // onChange={this.month}
                          onChange={(value) => {
                            this.setState({
                              selectedMonth: value.target.value,
                              durationMessageMonth: value !== "" ? "" : "",
                            });
                            this.InvokeFunctionChange(
                              this,
                              "month",
                              value.target.value
                            );
                          }}
                        >
                          <option value="">Select Month</option>
                          {this.state.monthList.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>

                        {this.state.durationMessageMonth !== "" ? (
                          <p style={{ color: "red", marginLeft: "15px" }}>
                            {this.state.durationMessageMonth}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type={"number"}
                          className="form-control"
                          placeholder="Target Amount"
                          onChange={this.amount}
                          onChange={(value) => {
                            this.setState({
                              amount: value.target.value,
                              amountMessage: value !== "" ? "" : "",
                            });
                            this.InvokeFunctionChange(
                              this,
                              "amount",
                              value.target.value
                            );
                          }}
                          style={{ borderRadius: "1px" }}
                        />
                        {this.state.amountMessage !== "" ? (
                          <p style={{ color: "red", marginLeft: "15px" }}>
                            {this.state.amountMessage}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          type={"number"}
                          className="form-control"
                          placeholder="Initial Deposit"
                          id="initialAmount"
                          // onChange={this.initialDeposit}
                          onChange={(value) => {
                            this.setState({
                              initialDeposit: value.target.value,
                              initialDepositMessage: value !== "" ? "" : "",
                            });
                            this.InvokeFunctionInitial(
                              this,
                              "intial",
                              value.target.value
                            );
                          }}
                          onBlur={(value) => {
                            this.InvokeFunctionInitialModal(value.target.value);
                          }}
                          style={{ borderRadius: "1px" }}
                        />
                        {this.state.initialDepositMessage !== "" ? (
                          <p style={{ color: "red", marginLeft: "15px" }}>
                            {this.state.initialDepositMessage}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group" style={{ marginTop: "7px" }}>
                        <input
                          type="checkbox"
                          id="recurringCheck"
                          name="recurringCheck"
                          onClick={this.isRecurring}
                          value="1"
                        />
                        <label for="recurringCheck"> Recurring?</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          type={"number"}
                          className="form-control"
                          id="recurring_amount"
                          placeholder="Recurring Deposit"
                          // onChange={this.recurringDeposit}
                          onChange={(value) => {
                            this.setState({
                              recurringDeposit: value.target.value,
                              recurringDepositMessage: value !== "" ? "" : "",
                            });
                            this.InvokeFunctionChange(
                              this,
                              "recurring",
                              value.target.value
                            );
                          }}
                          onBlur={(value) => {
                            this.InvokeFunctionModal(value.target.value);
                          }}
                          style={{ borderRadius: "1px" }}
                        />
                        {this.state.recurringDepositMessage !== "" ? (
                          <p style={{ color: "red", marginLeft: "15px" }}>
                            {this.state.recurringDepositMessage}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group text-right">
                        <button
                          disabled={this.state.goalLoader ? true : false}
                          className="btn btn-primary"
                          onClick={this.addGoal}
                          style={{ borderRadius: "1px" }}
                        >
                          {this.state.goalLoader ? (
                            <div>
                              <i
                                style={{ fontSize: "17px" }}
                                className="fa fa-spinner"
                              ></i>
                              Saving Goal...{" "}
                            </div>
                          ) : (
                            "Next"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <Footer/> */}
                  <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.SignUpModel}
                    toggle={() => this.signUp("SignUpModel")}
                  >
                    <div className="modal-header">
                      <h6 className="modal-title" id="modal-title-default">
                        Select Bank to continue
                      </h6>
                      <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.addGoalClose("SignUpModel")}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {this.state.bankLoader ? (
                        <center>
                          <div>
                            <i
                              style={{ fintSize: "16px" }}
                              className="fa fa-spinner"
                            ></i>
                            Loading Banks...
                          </div>
                        </center>
                      ) : (
                        ""
                      )}
                      {this.state.banks.map((items, index) => (
                        <div className="custom-control custom-radio mb-3">
                          <input
                            className="custom-control-input"
                            value={items.dwBankID}
                            onClick={this.selectBanks.bind(
                              this,
                              items.dwBankID,
                              items.access_token
                            )}
                            id={"customRadio_" + index}
                            name={"customRadio_name"}
                            type="radio"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"customRadio_" + index}
                          >
                            <span>{items.bankAccountNickname}</span>
                          </label>
                        </div>
                      ))}
                      {this.state.SelectBankMessage !== "" ? (
                        <p style={{ color: "red", marginLeft: "15px" }}>
                          {this.state.SelectBankMessage}
                        </p>
                      ) : (
                        ""
                      )}
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type={"number"}
                            className="form-control"
                            placeholder="Initial Deposit"
                            onChange={this.initialDeposit}
                            value={this.state.initialDeposit}
                            style={{ borderRadius: "1px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <Button
                        color="primary"
                        type="button"
                        onClick={this.saveGoalAddDetail}
                      >
                        Add Goal
                      </Button>
                      <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.addGoalClose("SignUpModel")}
                      >
                        Close
                      </Button>
                    </div>
                  </Modal>

                  {/* initial Modal */}
                  <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.Initial_depositModal}
                    toggle={() => this.Initial_depositModalClose}
                  >
                    <div className="modal-header">
                      <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.Initial_depositModalClose}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        The suggested initial deposit, without any monthly
                        recurring deposit, to reach your goal is{" "}
                        <strong>{this.state.Initial_deposit.toFixed(2)}</strong>{" "}
                        Do you want to update your initial deposit?
                      </p>
                    </div>
                    <div className="modal-footer">
                      <Button
                        color="primary"
                        type="button"
                        onClick={this.callInvokeInitial}
                      >
                        Yes
                      </Button>
                      <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.Initial_depositModalClose}
                      >
                        No
                      </Button>
                    </div>
                  </Modal>
                  <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.modalVisible}
                    toggle={this.recurring_depositModalClose}
                  >
                    <div className="modal-header">
                      <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.recurring_depositModalClose}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        The optimal recurring deposit to reach your goal is{" "}
                        <strong>{this.state.annuity.toFixed(2)}</strong> Do you
                        want to update your monthly recurring deposit?
                      </p>
                    </div>
                    <div className="modal-footer">
                      <Button
                        color="primary"
                        type="button"
                        onClick={this.callInvoke}
                      >
                        Yes
                      </Button>
                      <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.recurring_depositModalClose}
                      >
                        No
                      </Button>
                    </div>
                  </Modal>
                  <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.chooseGoalModal}
                    // toggle={this.recurring_depositModalClose}
                  >
                    <div className="modal-header">
                      <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.chooseGoalModalClose}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                      
                    </div>
                    <div className="modal-header">
                    <h3>Choose Goal</h3>
                    </div>
                    <div className="modal-body">
                      <center>
                        <div className="row">
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "Vacation")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/retirement.png"}
                              ></img>
                              <p>Vacation</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "Hajj")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/hajj.png"}
                              ></img>
                              <p>Hajj</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "New Car")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/car.png"}
                              ></img>
                              <p>New Car</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "Education")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/kids-ed.png"}
                              ></img>
                              <p>Education</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "Business")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/business.png"}
                              ></img>
                              <p>Business</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "Renovation")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/house.png"}
                              ></img>
                              <p>Renovation</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "Investment")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/investment.png"}
                              ></img>
                              <p>Investment</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "Donation")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/donation.png"}
                              ></img>
                              <p></p>Donation
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "Marriage")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/wedding.png"}
                              ></img>
                              <p>Marriage</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "Retirement")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/retirement.png"}
                              ></img>
                              <p>Retirement</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "Umrah")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/hajj.png"}
                              ></img>
                              <p>Umrah</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className="choose-goal-box"
                              onClick={this.chooseGoal.bind(this, "New Home")}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/dark/house.png"}
                              ></img>
                              <p>New Home</p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div
                              style={{
                                width: "100%",
                                backgroundColor: "green",
                                borderRadius: "5px",
                                height: "85px",
                              }}
                              className="choose-goal-box custom-goal"
                              onClick={this.chooseGoal.bind(
                                this,
                                "Custom Goal"
                              )}
                            >
                              <img
                                className="choose-box-img"
                                src={"images/goalIconsNew/goalicon.png"}
                              ></img>
                              <p style={{color:'white',fontWeight:'900'}}>Custom Goal</p>
                            </div>
                          </div>
                        </div>
                      </center>
                    </div>
                    {/* <div className="modal-footer">
                      <Button
                        color="primary"
                        type="button"
                        onClick={this.callInvoke}
                      >
                        Yes
                      </Button>
                      <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={ this.recurring_depositModalClose}
                      >
                        No
                      </Button>
                    </div> */}
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </section>
    );
  }
}

export default Index;

const FV = (rate, nper, pmt, pv) => {
  var pow = Math.pow(1 + rate, nper);
  let fv;
  if (rate) {
    fv = (pmt * (pow - 1)) / rate + pv * pow;
  } else {
    fv = pv + pmt * nper;
  }
  return fv;
};

const Qnorm = (p) => {
  p = parseFloat(p);
  var split = 0.42;

  var a0 = 2.50662823884;
  var a1 = -18.61500062529;
  var a2 = 41.39119773534;
  var a3 = -25.44106049637;
  var b1 = -8.4735109309;
  var b2 = 23.08336743743;
  var b3 = -21.06224101826;
  var b4 = 3.13082909833;
  var c0 = -2.78718931138;
  var c1 = -2.29796479134;
  var c2 = 4.85014127135;
  var c3 = 2.32121276858;
  var d1 = 3.54388924762;
  var d2 = 1.63706781897;

  var q = p - 0.5;

  var r, ppnd;

  if (Math.abs(q) <= split) {
    r = q * q;
    ppnd =
      (q * (((a3 * r + a2) * r + a1) * r + a0)) /
      ((((b4 * r + b3) * r + b2) * r + b1) * r + 1);
  } else {
    r = p;
    if (q > 0) r = 1 - p;
    if (r > 0) {
      r = Math.sqrt(-Math.log(r));
      ppnd = (((c3 * r + c2) * r + c1) * r + c0) / ((d2 * r + d1) * r + 1);
      if (q < 0) ppnd = -ppnd;
    } else {
      ppnd = 0;
    }
  }

  return ppnd;
};

const gaussian = (mean, stdev) => {
  var u = 0,
    v = 0;
  while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  var x = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

  var retval = x * stdev + mean;
  return retval;
};

const dateDifference = (selectedYear, selectedMonth) => {
  let startingDate = new Date();
  var endingDate = selectedYear + "-" + selectedMonth + "-" + "01";
  var dateCurrent = startingDate.getTime();
  var dateSelected = new Date(endingDate);
  var dateFuture = dateSelected.getTime();
  var dif = dateFuture - dateCurrent;
  return dif;
};
