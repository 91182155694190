import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import services from "../utils/services";
class headerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    if (
      localStorage.getItem("loginToken") == "" ||
      localStorage.getItem("loginToken") === null ||
      localStorage.getItem("otpStatus") == "false"
    ) {
      window.location.href = "/#/login";
      return;
    }
    if( localStorage.getItem("otpStatus") === "false"){
      window.location.href = "/#/login";
      return;
    }
    const response = await services.getBanks();
    const responseJson = await response.json();
    console.log(responseJson.status);
    console.log(response.status);
    if (response.status == "200") {
      this.setState({ goal: responseJson.data.rows });
    }
    if (responseJson.message == "jwt expired") {
      localStorage.setItem("loginToken", "");
      window.location.href = "/#/login";
    }
  }
  logout = (e) => {
    console.log("logout");
    localStorage.clear();
    window.location.href = "/#/login";
  };
  render() {
    return (
      <div className="App">
        <header id="mainHeader" className="shadow-lg p-1 mb-5 bg-white rounded">
          <div
            className="heading-info-top"
            style={{ marginBottom: "0px", marginTop: "0px" }}
          >
            <div className="row">
              <div className="col-md text-md-right">
                <nav className="navbar navbar-expand-lg navbar-light text-md-right">
                  <a    className="navbar-brand" href="#">
                    <img
                      style={{ height: "60px" }}
                      src={"/images/aghaz_logo.png"}
                    ></img>
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse "
                    id="navbarSupportedContent"

                    // style={{marginLeft:'10%'}}
                  >
                    <ul className="navbar-nav mr-auto headernav">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          Dashboard
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/accounts">
                          Accounts
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/transactions">
                          Transactions
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Profile
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <Link className="dropdown-item" to="/settings">
                            Change Password
                          </Link>
                          <Link className="dropdown-item" to="/risk-profile">
                            Risk Profile
                          </Link>
                          <Link className="dropdown-item" to="/beneficiary">
                            Beneficiary
                          </Link>
                        </div>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/invite-friends">
                          Invite Friends
                        </Link>
                      </li>

                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Tools
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <Link className="dropdown-item" to="/compare">
                            Compare your Savings
                          </Link>
                          <Link className="dropdown-item" to="/Compare-deposit">
                            Saving with Recurring Deposit
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/Compare-goal-deposit"
                          >
                            Compare Goal Deposit
                          </Link>
                          <Link className="dropdown-item" to="/portfolio-review">
                            Portfolio review
                          </Link>
                        </div>
                      </li>
                      <li className="nav-item">
                        
                        <a
                          href="/#/login"
                          className="nav-link"
                          onClick={this.logout}
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default headerComponent;
