import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
// import HeaderComponent from '../Components/header.js';
import SideMenue from "../Components/sideMenue.js";
import InvestmentDisclaimerComponent from "../Components/disclaimer.js";
import FooterComponent from "../Components/footer.js";
import Select from "react-select";
import { Button, Modal } from "reactstrap";
import { PlaidLink } from "react-plaid-link";
import services from "../utils/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Line } from "react-chartjs-2";
import Header from "../Components/header.js";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioReviewData: [],
      settingPlaidAccessToken: "",
      settingPlaidPublicToken: "",
      scoreStatus: false,
      loading: false,
      manualStatus: false,
      scoreData: [],
      investingData: [],
      counter: 0,
      counterArray: [],
      allData: [],
      scoreDataAllOriginal: [],
      emailModal: false,
      name: "",
      email: "",
    };
  }
  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.getPlaidSettings();
    this.getPortfolioList();
    fetch(global.BASE_URL + "node/api/client/Portfolio/search/list")
      .then((res) => res.json())
      .then(
        (response) => {
          let data = new Array();
          if (response.data.length > 0) {
            for (let a = 0; a < response.data.length; a++) {
              data[a] = {
                label: response.data[a].Name + " | " + response.data[a].Symbol,
                value: response.data[a].Name,
              };
            }
          }
          this.setState({ portfolioReviewData: data });
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getPortfolioList = async (e) => {
    const response = await services.gtPortfolioData();
    const responseJson = await response.json();
    if (response.status == "200") {
      let data = new Array();
      if (responseJson.data.length > 0) {
        for (let a = 0; a < responseJson.data.length; a++) {
          data[a] = {
            label:
              responseJson.data[a].Name + " | " + responseJson.data[a].Symbol,
            value: responseJson.data[a].Name,
          };
        }
      }
      this.setState({ portfolioReviewData: data });
    }
  };
  getPlaidSettings = async (e) => {
    const response = await services.getPlaidSettings();
    const responseJson = await response.json();

    if (response.status == "200") {
      // console.log(responseJson.data.plaidPublicToken,'responseJson.data.plaidPublicToken')
      this.setState({
        settingPlaidAccessToken: responseJson.data.plaidAccessToken,
      });
      this.setState({
        settingPlaidPublicToken: responseJson.data.plaidPublicToken,
      });
    }
    if (responseJson.status == "401") {
      alert("authorization Problem");
    }
  };

  handleSuccess = async (data) => {
    this.setState({ loading: true });
    const dataToExchange = {
      client_id: this.state.settingPlaidAccessToken,
      secret: this.state.settingPlaidPublicToken,
      public_token: data,
    };

    const response = await services.getAccessToken(dataToExchange);
    const responseJson = await response.json();

    if (response.status == "200") {
      this.setState({ access_token: responseJson.access_token });
      const userDataInvestment = {
        client_id: this.state.settingPlaidAccessToken,
        secret: this.state.settingPlaidPublicToken,
        access_token: responseJson.access_token,
      };
      this.getAllInvestment(userDataInvestment);
    }
  };
  exitFunction = (data) => {
    console.log(data, "exit");
  };

  errorFunction = (data) => {
    console.log(data, "canceled");
  };
  getAllInvestment = async (data) => {
    const response = await services.getInvestment(data);
    const responseJson = await response.json();
    if (response.status == "200") {
      this.setState({ scoreDataAllOriginal: responseJson });
      this.getScore(responseJson);
    }
  };
  getScore = async (data) => {
    console.log(data, "data");
    const response = await services.getScore(data);
    const responseJson = await response.json();
    if (response.status == "200") {
      console.log(responseJson, "response");
      this.setState({ scoreData: responseJson.investments });
      //   this.setState({ scoreDataAllOriginal: response });
      this.setState({ cummulativeScore: responseJson.CumulativeScore });
      this.setState({ scoreStatus: true });
      this.setState({ manualStatus: false });
      this.setState({ loading: false });
      if (responseJson.investments.length > 0) {
        let halal = 0;
        let unknown = 0;

        for (let a = 0; a < responseJson.investments.length; a++) {
          if (responseJson.investments[a].isHalal == true) {
            halal++;
          }
          if (
            responseJson.investments[a].isHalal !== true &&
            responseJson.investments[a].isHalal !== false
          ) {
            unknown++;
          }
        }
        this.setState({ halal: halal });
        this.setState({ unknown: unknown });
      }
    }
  };

  addMoreStocks = (e) => {
    this.setState({ scoreStatus: false });
    this.setState({ manualStatus: !this.state.manualStatus });
  };
  addMoreStocksAppend = (e) => {
    this.setState({ counter: this.state.counter++ });
    this.state.counterArray.push(Math.random());
  };
  handleChange = (e) => {
    this.state.investingData.push(e.label);
    this.renderData();
  };
  renderData = (e) => {
    let res = [];
    let array1 = this.state.portfolioReviewData;
    let array2 = this.state.investingData;
    res = array1.filter((item) => !array2.includes(item.label));
    this.setState({ portfolioReviewData: res });
  };
  submitData = (e) => {
    let additional = new Array();

    if (this.state.investingData.length !== 0) {
      for (let i = 0; i < this.state.investingData.length; i++) {
        var name = this.state.investingData[i];

        if (name !== undefined) {
          additional.push({
            name: name,
            quantity: 1,
            symbol: this.state.investingData[i].split(" | ")[1],
          });
        }
      }
    }

    if (this.state.scoreDataAllOriginal.length !== 0) {
      var resp = {
        accounts: this.state.scoreDataAllOriginal.accounts,
        holdings: this.state.scoreDataAllOriginal.holdings,
        item: this.state.scoreDataAllOriginal.item,
        request_id: this.state.scoreDataAllOriginal.request_id,
        securities: this.state.scoreDataAllOriginal.securities,
        additional: additional,
      };
    } else {
      var resp = {
        accounts: [],
        holdings: [],
        item: {},
        request_id: "fdsfdsfdsf",
        securities: [],
        additional: additional,
      };
    }
    this.setState({ allData: resp });
    this.setState({ loading: true });
    this.getScore(resp);
  };
  emailResult = (e) => {
    this.setState({ emailModal: !this.state.emailModal });
  };
  emailModalClose = (e) => {
    this.setState({ emailModal: false });
  };
  handleName = (e) => {
    this.setState({ name: e.target.value });
  };
  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  sendEmailHandle = async (e) => {
    let email = localStorage.getItem("email");
    let name = localStorage.getItem("firstName");
    console.log(email, "email");
    if (email != "") {
      if (
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
      } else {
        toast["error"]("Enter valid email");
        return;
      }
      let investArry = new Array();
      for (let i = 0; i < this.state.scoreData.length; i++) {
        investArry.push({
          name: this.state.scoreData[i].name,
          percentage: this.state.scoreData[i].percentage,
          isHalal: this.state.scoreData[i].isHalal ? "Yes" : "No",
        });
      }
      var data = {
        name: name,
        email: email,
        score: this.state.cummulativeScore.toString(),
        data: investArry,
      };
      const response = await services.sendEmail(data);
      const responseJson = await response.json();
      if (response.status == "200") {
        toast["success"](responseJson.message);
      }
    } else {
      toast["error"]("Enter Email and name to continue.");
    }
  };
  render() {
    let { portfolioReviewData } = this.state;
    return (
      <section className="dashboard-section">
        <div className="container-fluid">
          <div className="row">
            <ToastContainer />
            <div className="col-md-12">
              <Header />
              <section className="section section-blog-top">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="blog-heading">
                        <h1
                          style={{
                            fontSize: "42px",
                            color: "#3A5FA7",
                            fontFamily: "HelveticaNeuBold",
                          }}
                        >
                          Portfolio Review
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section section-blog-listing">
                <div className="container">
                  {this.state.loading ? (
                    <center>
                      <i
                        style={{ fontSize: "35px" }}
                        className="fa fa-spinner fa-spin"
                      ></i>{" "}
                    </center>
                  ) : (
                    ""
                  )}
                  {this.state.scoreStatus === false ? (
                    <div className="row">
                      <div className="col-sm-12">
                        {this.state.settingPlaidAccessToken !== "" &&
                        this.state.settingPlaidAccessToken !== undefined ? (
                          <center>
                            <PlaidLink
                              publicKey={this.state.settingPlaidPublicToken}
                              clientName="Aghaz"
                              env="sandbox" // 'sandbox' or 'development' or 'production'
                              product={["investments"]}
                              onSuccess={(datass) => {
                                this.handleSuccess(datass);
                              }}
                              onExit={(dataee) => {
                                this.exitFunction(dataee);
                              }}
                              onCancelled={(result) => {
                                this.errorFunction(result);
                              }}
                              className="btn btn-primary portfolioButton"
                              style={{
                                padding: "9px 15px",
                                outline: "none",
                                background: "#3A5FAC",
                                color: "#fff",
                                border: "1px solid #3A5FAC",
                                width: "40%",
                                borderRadius: "1px",
                              }}
                              id="portfolioButton"
                            >
                              Connect your investment account
                            </PlaidLink>
                            <br />
                            <button
                              className="btn btn-primary portfolioButton"
                              style={{ marginTop: "10px", width: "40%" }}
                              onClick={this.addMoreStocks}
                            >
                              Manually enter your investments
                            </button>
                          </center>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-sm-10 offset-1">
                        <div
                          className="row"
                          style={{
                            backgroundColor: "#e6e6e6",
                            paddingBottom: "15px",
                            borderRadius: "15px",
                          }}
                        >
                          <div className="col-sm-5">
                            <div className="col-sm-12">
                              <h3
                                style={{
                                  fontWeight: "900",
                                  fontSize: "25px",
                                  marginLeft: "-15px",
                                  color: "#5bb2df",
                                  padding: "10px",
                                }}
                              >
                                Summary
                              </h3>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#e0f1fc",
                                padding: "10px 10px 10px 10px",
                                borderRadius: "20px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p style={{ fontSize: "18px" }}>Total Items</p>
                                <p
                                  style={{
                                    fontSize: "18px",
                                    right: "10px !important",
                                    position: "absolute",
                                    right: "25px",
                                  }}
                                >
                                  {this.state.scoreData.length}
                                </p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p style={{ fontSize: "18px" }}>Halal</p>
                                <p
                                  style={{
                                    fontSize: "18px",
                                    right: "20px !important",
                                    position: "absolute",
                                    right: "25px",
                                  }}
                                >
                                  {this.state.halal}
                                </p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p style={{ fontSize: "18px" }}>Unknown</p>
                                <p
                                  style={{
                                    fontSize: "18px",
                                    right: "20px !important",
                                    position: "absolute",
                                    right: "25px",
                                  }}
                                >
                                  {this.state.unknown}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <br />
                          </div>

                          <div
                            className="col-sm-5"
                            style={{
                              padding: "10px 10px 10px 10px",
                            }}
                          >
                            <div className="col-sm-12">
                              <center>
                                <h3
                                  style={{
                                    fontWeight: "900",
                                    fontSize: "25px",
                                    color: "#5bb2df",
                                  }}
                                >
                                  Portfolio Score
                                </h3>
                              </center>
                            </div>
                            <center>
                              <h1
                                className="score-circle"
                                style={{
                                  color: "#5bb2df",
                                  backgroundColor: "#fbfbfb",
                                }}
                              >
                                {this.state.cummulativeScore}%
                              </h1>
                            </center>
                          </div>
                        </div>
                        <div
                          class="row"
                          style={{
                            backgroundColor: "#e6e6e6",
                            padding: " 15px",
                            borderRadius: "15px",
                            marginTop: 20,
                          }}
                        >
                          <div style={{ marginTop: "0px", width: "100%" }}>
                            <div className="row" style={{}}>
                              <div className="col-lg-8 col-sm-8 col-md-8 col-8">
                                Stock
                              </div>
                              <div className="col-lg-2 col-sm-2 col-md-2 col-2">
                                %
                              </div>
                              <div className="col-lg-2 col-sm-2 col-md-2 col-2">
                                Halal?
                              </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                              {this.state.scoreData.map((item, index) => (
                                <div className="row" style={{ marginTop: 5 }}>
                                  <div
                                    className="col-lg-8 col-sm-8 col-md-8 col-8"
                                    style={{
                                      borderWidth: 1,
                                      borderColor: "#f77b57",
                                      height: 80,
                                      justifyContent: "center",
                                      // paddingTop: 15,
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "black",
                                        border: item.isHalal
                                          ? "2px solid #44c33b"
                                          : "2px solid #f77b57",
                                        width: "90%",
                                        borderRadius: " 35px",
                                        padding: " 4px",
                                        height: "80px",
                                        paddingTop: "9px",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      {item.name}
                                    </div>
                                  </div>

                                  <div
                                    className="col-lg-2 col-sm-2 col-md-2 col-2"
                                    style={{
                                      backgroundColor: item.isHalal
                                        ? "#44c33b"
                                        : "#f77b57",
                                      color: "white",
                                      borderTopLeftRadius: 15,
                                      borderBottomLeftRadius: 15,
                                      height: 80,
                                      paddingTop: 20,
                                    }}
                                  >
                                    {item.percentage}%
                                  </div>
                                  <div
                                    className="col-lg-2 col-sm-2 col-md-2 col-2"
                                    style={{
                                      backgroundColor: item.isHalal
                                        ? "#44c33b"
                                        : "#f77b57",
                                      color: "white",
                                      borderTopRightRadius: 15,
                                      borderBottomRightRadius: 15,
                                      height: 80,
                                      paddingTop: 20,
                                      maxWidth: "15.666667%",
                                    }}
                                  >
                                    {item.isHalal ? "Yes" : "No"}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <center>
                              <button
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  width: "70%",
                                }}
                                onClick={this.sendEmailHandle}
                                className="btn btn-primary"
                              >
                                Email me my results
                              </button>
                              <button
                                style={{ width: "70%" }}
                                onClick={this.addMoreStocks}
                                className="btn btn-primary"
                              >
                                Add more stocks
                              </button>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.manualStatus ? (
                    <center>
                      <div className="row" style={{ marginTop: "50px" }}>
                        <div className="col-sm-12">
                          <center>
                            <p>
                              Enter the name or symbol of a stock to see how
                              they align with your values.
                            </p>
                            <br />
                            <p style={{ marginBottom: "20px" }}>
                              Note: We do not support ETFs or Mutual Funds at
                              this time.
                            </p>
                            <div
                              style={{ width: "50%" }}
                              id="portfolio_multiselect"
                            >
                              <Select
                                onChange={this.handleChange}
                                options={portfolioReviewData}
                              />
                              {this.state.counterArray.map((item, index) => (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                  key={index}
                                >
                                  <Select
                                    onChange={this.handleChange}
                                    options={portfolioReviewData}
                                  />
                                </div>
                              ))}
                            </div>
                          </center>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-sm-6" style={{ width: "50%" }}>
                            <button
                              style={{ marginTop: "20px", float: "left" }}
                              className="btn btn-primary"
                              onClick={this.addMoreStocksAppend}
                            >
                              Add More
                            </button>
                          </div>
                          <div className="col-sm-6" style={{ width: "50%" }}>
                            <button
                              style={{
                                marginTop: "20px",
                                float: "right",
                                width: "50%",
                              }}
                              className="btn btn-primary"
                              onClick={this.submitData}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </center>
                  ) : (
                    ""
                  )}
                </div>
              </section>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.emailModal}
                toggle={() => this.signUp("SignUpModel")}
              >
                <div className="modal-header">
                  <h6 className="modal-title" id="modal-title-default">
                    Enter email to continue
                  </h6>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={this.emailModalClose}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <input
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                      type="text"
                      id="text"
                      placeholder="Name"
                      className="form-control"
                      onChange={this.handleName}
                    ></input>
                    <input
                      type="email"
                      id="email"
                      placeholder="Email"
                      className="form-control"
                      onChange={this.handleEmail}
                    ></input>
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    color="primary"
                    type="button"
                    onClick={this.sendEmailHandle}
                  >
                    Send Email
                  </Button>
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={this.emailModalClose}
                  >
                    Close
                  </Button>
                </div>
              </Modal>
            </div>
          </div>
          {/* <FooterComponent/> */}
        </div>
        <FooterComponent />
      </section>
    );
  }
}

export default AboutUs;
