// //TODO: move these to constants
// const BASE_URL = "http://api.staging.aghazinvest.com";
let BASE_URL_PLAID = "";
let BASE_URL = '';
if (
  window.location.protocol === "http:" ||
  window.location.protocol === "http:"
) {
  if (window.location.href.indexOf("staging") > -1) {
     BASE_URL = "http://api.staging.aghazinvest.com/";
     BASE_URL_PLAID = "https://cors-anywhere.herokuapp.com/https://sandbox.plaid.com/";
  } else {
     BASE_URL = "https://api.aghazinvest.com/";
     BASE_URL_PLAID = "https://cors-anywhere.herokuapp.com/https://production.plaid.com/";
  }
  
} else {
  if (window.location.href.indexOf("staging") > -1) {
     BASE_URL = "https://api.staging.aghazinvest.com/";
     BASE_URL_PLAID = "https://cors-anywhere.herokuapp.com/https://sandbox.plaid.com/";
  } else {
     BASE_URL = "https://api.aghazinvest.com/";
     BASE_URL_PLAID = "https://cors-anywhere.herokuapp.com/https://production.plaid.com/";
  }
}
// BASE_URL_PLAID = "https://cors-anywhere.herokuapp.com/https://sandbox.plaid.com/";
//  BASE_URL = "http://api.staging.aghazinvest.com/";
export default {
  //It will authenticate Aghaz and get the token

  async userLogin(data) {
    try {
      let response = await fetch(BASE_URL + "/Node/api/client/login", {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }),
      });
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async forgotPassword(data) {
    try {
      let response = await fetch(
        BASE_URL + "/Node/api/client/forgotPassword/request",
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getGoals() {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(BASE_URL + "/Node/api/user/goal/list", {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "x-auth-token": token,
          Accept: "application/json",
        }),
      });
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async resetPassword(data) {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(BASE_URL + "/Node/api/client/password/reset", {
        method: "PUT",
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": "application/json",
          "x-auth-token": token,
        }),
      });
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async goalCreate(data) {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(BASE_URL + "/Node/api/user/goal/add", {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": "application/json",
          "x-auth-token": token,
        }),
      });
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getProfile() {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(BASE_URL + "/Node/api/client/getUser", {
        method: "GET",
        headers: new Headers({
          "x-auth-token": token,
        }),
      });
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getContactInfo() {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(BASE_URL + "/Node/api/client/address/get", {
        method: "GET",
        headers: new Headers({
          "x-auth-token": token,
        }),
      });
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async updateProfilePersonal(data) {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(BASE_URL + "/node/api/client/updateProfile", {
        method: "PUT",
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": "application/json",
          "x-auth-token": token,
        }),
      });
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async updateProfileContact(data) {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(BASE_URL + "/node/api/client/address/update", {
        method: "PUT",
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": "application/json",
          "x-auth-token": token,
        }),
      });
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getBanks() {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(BASE_URL + "/Node/api/client/bank/list", {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "x-auth-token": token,
          Accept: "application/json",
        }),
      });
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getTransactions() {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(
        BASE_URL + "/Node/api/client/goal/transactions/all",
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "x-auth-token": token,
            Accept: "application/json",
          }),
        }
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async goalCreateTransaction(data) {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(BASE_URL + "/Node/api/client/deposit/create", {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": "application/json",
          "x-auth-token": token,
          Accept: "application/json",
        }),
      });
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async validatePasswordRequest(data) {
    try {
      let response = await fetch(
        BASE_URL + "/Node/api/client/forgotPassword/validate",
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: new Headers({
            "Content-Type": "application/json",

            Accept: "application/json",
          }),
        }
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async forgotPasswordReset(data) {
    try {
      let response = await fetch(
        BASE_URL + "/Node/api/client/forgotPassword/reset",
        {
          method: "PUT",
          body: JSON.stringify(data),
          headers: new Headers({
            "Content-Type": "application/json",

            Accept: "application/json",
          }),
        }
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getTransactions() {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(
        BASE_URL + "/Node/api/client/goal/transactions/all",
        {
          method: "GET",
          // body: JSON.stringify(data),
          headers: new Headers({
            "Content-Type": "application/json",
            "x-auth-token": token,
            Accept: "application/json",
          }),
        }
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getTransactionsByGoalId(id) {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(
        BASE_URL + "/Node/api/client/goal/transactions/specfic?id=" + id,
        {
          method: "GET",
          // body: JSON.stringify(data),
          headers: new Headers({
            "Content-Type": "application/json",
            "x-auth-token": token,
            Accept: "application/json",
          }),
        }
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getTransactionsByBankId(id) {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(
        BASE_URL + "/Node/api/client/goal/transactions/specficBank?id=" + id,
        {
          method: "GET",
          // body: JSON.stringify(data),
          headers: new Headers({
            "Content-Type": "application/json",
            "x-auth-token": token,
            Accept: "application/json",
          }),
        }
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getTransactionsFilters(start, end) {
    const token = localStorage.getItem("loginToken");
    const clientId = localStorage.getItem("clientId");
    try {
      let response = await fetch(
        BASE_URL +
          "/Node/api/client/goal/transactions/all?sDate=" +
          start +
          "&eDate=" +
          end,
        {
          method: "GET",
          // body: JSON.stringify(data),
          headers: new Headers({
            "Content-Type": "application/json",
            "x-auth-token": token,
            Accept: "application/json",
          }),
        }
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async userVerify(data) {
    try {
      let response = await fetch(BASE_URL + "/node/api/Email/verify", {
        method: "PUT",
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getPerformanceByGoalId(id) {
    const token = localStorage.getItem("loginToken");
    try {
      let response = await fetch(
        BASE_URL + "/Node/api/goal/portfolio/getPerformance?id=" + id,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-auth-token": token,
          }),
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async deleteGoalById(data) {
    const token = localStorage.getItem("loginToken");
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL + "/node/api/user/goal/delete", {
        method: "delete",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-auth-token": token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async goalUpdate(data) {
    const token = localStorage.getItem("loginToken");
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL + "/node/api/user/goal/update", {
        method: "PUT",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-auth-token": token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async goalCreateRecurring(data) {
    const token = localStorage.getItem("loginToken");
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(
        BASE_URL + "/node/api/client/deposit/createRecurring",
        {
          method: "POST",
          body: dataTo,
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-auth-token": token,
          }),
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getDWTransactions(id) {
    const token = localStorage.getItem("loginToken");
    // var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(
        BASE_URL +
          "/node/api/client/account/statement/dwTransactionDetails?id=" +
          id +
          "&limit=20",
        {
          method: "GET",
          // body: dataTo,
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-auth-token": token,
          }),
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getSummary(id) {
    const token = localStorage.getItem("loginToken");
    // var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(
        BASE_URL + "/node/api/client/account/statement/list?id=" + id,
        {
          method: "GET",
          // body: dataTo,
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-auth-token": token,
          }),
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async downloadFile(id, key) {
    const token = localStorage.getItem("loginToken");
    // var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(
        BASE_URL +
          "/node/api/client/account/statement/downloadStatement?id=" +
          id +
          "&fileKey=" +
          key,
        {
          method: "GET",
          // body: dataTo,
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-auth-token": token,
          }),
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async deleteBank(id) {
    const token = localStorage.getItem("loginToken");
    // var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(
        BASE_URL +
          "/node/api/client/account/statement/downloadStatement?id=" +
          id,
        {
          method: "GET",
          // body: dataTo,
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-auth-token": token,
          }),
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getPlaidSettings(id) {
    // const token = localStorage.getItem('loginToken');
    // var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL + "/node/api/plaid/get", {
        method: "GET",
        // body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getAccessToken(data) {
    // const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL_PLAID+"item/public_token/exchange", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getPlaidBalance(data) {
    // const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL_PLAID+"accounts/get", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getProcessorToken(data) {
    // const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL_PLAID+"processor/token/create", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getRoutingNumber(data) {
    // const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL_PLAID+"auth/get", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getInstitution(data) {
    // const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL_PLAID+"institutions/get_by_id", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getInstitutionLogo(data) {
    // const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL_PLAID+"institutions/search", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async addBank(data) {
    const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"Node/api/client/bank/add", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async payOut(data) {
    const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"/node/api/goal/payout/create", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async liquidateGoal(data) {
    const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"/node/api/client/goal/rebalance", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async addFund(data) {
    const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"/Node/api/client/deposit/create", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async inviteFriend(data) {
    const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"/node/api/user/invite/send", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getInviteList() {
    const token = localStorage.getItem('loginToken');
    // var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"/node/api/user/invite/list", {
        method: "get",
        // body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async deleteInvite(data) {
    const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"/node/api/user/invite/widraw", {
        method: "DELETE",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getInvestment(data) {
    // const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL_PLAID+"investments/holdings/get", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getScore(data) {
    // const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"Node/api/client/Portfilio/review", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async sendEmail(data) {
    // const token = localStorage.getItem('loginToken');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"node/api/client/Portfolio/Email/send", {
        method: "post",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async gtPortfolioData() {
    // const token = localStorage.getItem('loginToken');
    // var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"node/api/client/Portfolio/search/list", {
        method: "get",
        // body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          // 'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getBeneferies() {
    const token = localStorage.getItem('loginToken');
    const defaultGoal = localStorage.getItem('defaultGoal');
    // var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"/node/api/client/account/beneficiary/list?id="+defaultGoal, {
        method: "get",
        // body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async saveBeneferyUpdate(data) {
    const token = localStorage.getItem('loginToken');
    // const defaultGoal = localStorage.getItem('defaultGoal');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"node/api/client/account/beneficiary/edit", {
        method: "PUT",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async goalCreateRecurring(data) {
    const token = localStorage.getItem('loginToken');
    // const defaultGoal = localStorage.getItem('defaultGoal');
    var dataTo = JSON.stringify(data);
    try {
      let response = await fetch(BASE_URL+"node/api/client/deposit/createRecurring", {
        method: "POST",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async updateRiskProfile(data) {
    const token = localStorage.getItem('loginToken');
    // const defaultGoal = localStorage.getItem('defaultGoal');
    var dataTo = JSON.stringify(data);
    console.log(dataTo,'dataTo')
    try {
      let response = await fetch(BASE_URL+"node/api/client/updateRisk", {
        method: "PUT",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async verifyCode(data) {
    const token = localStorage.getItem('loginToken');
    // const defaultGoal = localStorage.getItem('defaultGoal');
    var dataTo = JSON.stringify(data);
    console.log(dataTo,'dataTo')
    try {
      let response = await fetch(BASE_URL+"node/api/client/verifyOtp", {
        method: "POST",
        body: dataTo,
        headers: new Headers({
          "Content-Type": "application/json",
          // Accept: "application/json",
          'x-auth-token': token,
        }),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
