import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import HeaderComponent from '../Components/header.js';
import SideMenue from "../Components/sideMenue.js";
import InvestmentDisclaimerComponent from "../Components/disclaimer.js";
import FooterComponent from "../Components/footer.js";
import { Line } from "react-chartjs-2";
import { HorizontalBar } from "react-chartjs-2";

import Header from "../Components/header.js";
import "../style.css";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      optionsBar: [],
      calculate: false,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  calculate = (e) => {
    this.setState({ options: "" });

    let a = document.getElementById("amount").value;
    let i = document.getElementById("year").value;
    this.setState({ calculate: false });
    if (a == "" || i == "") {
      this.setState({ calculate: false });
      this.setState({ calculate: "Enter Amount and Years to continue" });
      return;
    } else {
      this.setState({ calculate: true });
      document.getElementById("tabs_data_content").style.display = "block";
    }
    let r = "";
    let n = "";
    let t = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    let es = new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 2,
    });

    // (a <= 0 || a >= 1e7) && (a = 7500, r = n);
    // (i <= 0 || i > 30) && (i = 7, r = n);
    let int = 2.05 / 100;
    let inf = 2.1 / 100;
    let gld = (6 - inf) / 100;
    let aghaz = (8 - inf) / 100;

    let l = ["Year", "Cash", "Gold", "Interest", "Aghaz"],
      u = a / (1 + -1 * inf * i),
      s = a / Math.pow(inf - int + 1, i),
      m = a / Math.pow(1 + gld, i),
      d = a / Math.pow(1 + aghaz, i);

    document.getElementById("cash_description").innerHTML =
      "If you want to save cash for " +
      i +
      " years without Interest, you need to start with <b>" +
      t.format(u) +
      "</b>. Effectively, you will lose <b>" +
      t.format(u - a) +
      "</b> due to inflation. With Interest, you need to start with <b>" +
      t.format(s) +
      "</b> to reach your goal.<p></p>";
    document.getElementById("goal_description").innerHTML =
      r +
      "You want to save <b>" +
      t.format(a) +
      "</b> in " +
      i +
      " years.<p></p>";
    document.getElementById("gold_description").innerHTML =
      "If you invest in Gold for " +
      i +
      " years, you need <b>" +
      t.format(m) +
      "</b> today to achieve your goal.<p></p>";
    document.getElementById("aghaz_description").innerHTML =
      "With Aghaz Investments, you need to start with <b>" +
      t.format(d) +
      "</b>. That is a saving of <b>" +
      t.format(u - d) +
      "</b> or <b>" +
      es.format((u - d) / a) +
      "</b>.<p>";

    this.setState({
      options: {
        title: {
          text:
            "Here's what you need to start with today to reach your goal.\n",
        },

        xAxis: {
          categories: ["Start", "Years " + i, ""],
          lineWidth: 4,
        },

        plotOptions: {
          series: {
            lineWidth: 3,
            label: {
              connectorAllowed: false,
            },
            animation: {
              duration: 2000,
            },
            marker: {
              enabled: false,
              symbol: "circle",
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        tooltip: {
          valueDecimals: 2,
          valuePrefix: "$",
          valueSuffix: " USD",
        },
        series: [
          {
            name: "Cash",
            data: [parseInt(u), parseInt(a)],
            color: "#7fcc6b",
          },
          {
            name: "Interest",
            data: [parseInt(s), parseInt(a)],
            color: "#ffb1d6",
          },
          {
            name: "Gold",
            data: [parseInt(m), parseInt(a)],
            color: "#d2dc78",
          },
          {
            name: "Aghaz",
            data: [parseInt(d), parseInt(a)],
            color: "#bbdefd",
          },
        ],
      },
    });

    this.setState({
      optionsBar: {
        title: {
          text: "How much do you save",
        },
        tooltip: {
          valueDecimals: 2,
          valuePrefix: "$",
          valueSuffix: " USD",
        },
        chart: {
          type: "bar",
        },

        xAxis: {
          categories: [""],
          title: {
            text: "Savings",
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            animation: {
              duration: 2000,
            },
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Cash",
            data: [parseInt(a - u)],
            color: "#7fcc6b",
          },
          {
            name: "Interest",
            data: [parseInt(u - m)],
            color: "#ffb1d6",
          },
          {
            name: "Gold",
            data: [parseInt(a - s)],
            color: "#dadc66",
          },
          {
            name: "Aghaz",
            data: [parseInt(u - d)],
            color: "#bbdefd",
          },
        ],
      },
    });

    // g.addRows([["Savings", a - u, t.format(a - u), u - m, t.format(u - m), a - s, t.format(a - s), u - d, t.format(u - d)]]);
  };

  render() {
    return (
      <section className="dashboard-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Header />
              <section className="section section-compare-top">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-7">
                      <div className="compare-top-txt">
                        <h1 style={{ fontWeight: "900", fontSize: "34px" }}>
                          COMPARE YOUR SAVINGS
                        </h1>
                        <h6>
                          You have various options when it comes saving for your
                          financial goals
                        </h6>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="compare-top-img">
                        <img
                          style={{ maxWidth: "70%" }}
                          src="/images/compare-top-img.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="section section-compare-saving"
                style={{ display: "none" }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card-saving">
                        <div className="card-s-img">
                          <img src="/images/save-img.png" alt="" />
                        </div>
                        <div className="card-s-txt">
                          <h3>Save cash in your bank</h3>
                          <p>
                            Some of you don’t take interest due to your beliefs,
                            so effectively, you lose money due to inflation.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card-saving">
                        <div className="card-s-img">
                          <img src="/images/gold-img.png" alt="" />
                        </div>
                        <div className="card-s-txt">
                          <h3>Buy Gold</h3>
                          <p>
                            You could purchase gold, either as an index fund or
                            actual jewellery, like many do. Your finances,
                            however, are at risk due to lack of diversification.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card-saving">
                        <div className="card-s-img">
                          <img src="/images/aghaz-logo-compare.png" alt="" />
                        </div>
                        <div className="card-s-txt">
                          <h3>Invest with Aghaz</h3>
                          <p>
                            Your portfolios are diversified among stocks, sukus
                            and commodities, and optimised for risk/return
                            through our software algorithms.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section section-compare-goal">
                <div className="container">
                  <h2 className="cmp-heading">WHAT IS YOUR FINANCIAL GOAL?</h2>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="compare-field-group">
                        <label>
                          Amount you want to save (up to $10 million)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id={"amount"}
                          defaultValue={"7500"}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="compare-field-group">
                        <label>Your goal due date (up to 30 years)</label>
                        <input
                          type="text"
                          className="form-control"
                          id={"year"}
                          defaultValue={"7"}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="calculate-btn text-center">
                        <button
                          className="btn btn-primary"
                          onClick={this.calculate}
                        >
                          Calculate
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="goal-tabs-wrapper"
                    id={"tabs_data_content"}
                    style={{ display: "none" }}
                  >
                    <div className="goal-tabs-inner">
                      <ul className="nav nav-tabs clearfix">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabYrGoal"
                          >
                            Your Goal
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabCash"
                          >
                            Cash
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabGold"
                          >
                            Gold
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabAghaz"
                          >
                            Aghaz
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content">
                        <div
                          className="tab-pane container active"
                          id="tabYrGoal"
                        >
                          <p id={"goal_description"}>
                            You want to save $7,500.00 in 7 years.
                          </p>
                        </div>
                        <div className="tab-pane container fade" id="tabCash">
                          <p id={"cash_description"}>
                            If you want to save cash for 6 years without
                            Interest, you need to start with $286.04.
                            Effectively, you will lose $36.04 due to inflation.
                            With Interest, you need to start with $250.75 to
                            reach your goal.
                          </p>
                        </div>
                        <div className="tab-pane container fade" id="tabGold">
                          <p id={"gold_description"}>
                            If you invest in Gold for 6 years, you need $187.62
                            today to achieve your goal.
                          </p>
                        </div>
                        <div className="tab-pane container fade" id="tabAghaz">
                          <p id={"aghaz_description"}>
                            With Aghaz Investments, you need to start with
                            $158.42. That is a saving of $127.62 or 51.05%.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<HorizontalBar data={data}/>*/}
                  {this.state.calculate == true ? (
                    <div className="compare-charts">
                      <div className="row">
                        <div className="col-md-6">
                          <div id={"saving_goals_chart"}>
                            {/*<Line data={this.state.options}/>*/}
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.options}
                              oneToOne={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div id={"saving_goals_chart"}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.optionsBar}
                              oneToOne={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="compare-charts" style={{ display: "none" }}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="compare-chart-box">
                          <p>
                            Here’s what you need to start with today to reach
                            your goal.
                          </p>
                          <h6>Saving for your goals</h6>
                          <div className="chart-box">
                            <img src="/images/line-chart.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 offset-md-2">
                        <div className="compare-chart-box">
                          <p>and here’s how much you will likely save.</p>
                          <h6>How much do you save?</h6>
                          <div className="chart-box">
                            <img src="/images/bar-chart.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <InvestmentDisclaimerComponent />
            </div>
          </div>
        </div>
        <FooterComponent/>
      </section>
    );
  }
}

export default AboutUs;
